import styled, { css } from 'styled-components';

export const ButtonActive = styled.button`
background:${(props) => props.theme.textColor};
`;

export const ButtonInactive = styled.button`
background:${(props) => props.theme.btnActive};
color:${(props) => props.theme.textColor}
`; 

export const Background = styled.div`
background-color: ${(props) => props.theme.backgroundColor};
`

export const BackgroundBox = styled.div`
background-color: ${(props) => props.theme.statsBoxBackground};
`

export const SelectDivActive = styled.select`
background:  ${(props) => props.theme.textColor};
`;

export const SelectDivInActive = styled.select`
background:  ${(props) => props.theme.btnActive}; 
color: ${(props) => props.theme.textColor};
`;
 
export const Heading = styled.h1`
color:  ${(props) => props.theme.buttonTextColorSecondary};
`

export const TextColor = styled.div`
color:  ${(props) => props.theme.buttonTextColorSecondary};
`

export const SelectDiv = styled.div`
background-color:  ${(props) => props.theme.btnActive};
color: ${(props) => props.theme.textColor};

&:hover {
  color: ${(props) => props.theme.hoverBackground};
}

&.selected {
  color: ${(props) => props.theme.hoverBackground};
}
`;

