import React from 'react'
import animation from "../../assets/images/animation_3.gif";
import './Loader.scss'
import timerImage from '../../assets/images/timer-page.png'

const Loader = () => {
    return (
        <>
            <div style={{ position: 'relative' }} className='loader-hide'>

                {/* <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: 'none',
                    height: '100vh',
                    width: '100vw',

                }}>
                    <img src={timerImage} alt="Loading.." style={{height: '100%', width: '100%'}} />
                </div> */}

                {/* <TimerPage /> */}
                {/* <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100vw',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                }}>
                    <img src={animation} alt="Loading.." style={{
                        width:'30vw'
                    }} />
                </div> */}
                
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                width: '100vw',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
            }} className='loader-mobile'>
                <img src={animation}  alt="Loading.." />
            </div>
        </>
    )
}

export default Loader