import React from "react";
import Left from "../../components/SignUp/Left/Left.jsx";
import Right from "../../components/SignUp/Right/Right.jsx";
import "./SignUp.scss";

const Signup = () => {
  return (
    <div className="signup-main">
      {/* <Left /> */}
      <Right />
    </div>
  );
};

export default Signup;
