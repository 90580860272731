import React, { useContext } from "react";
import NavComponents from "../../components/NavComponents/NavComponents";
import { ThemeContext } from "../../context";
//assests export

const Nav = () => {
  const { collapse, setCollapse, themeName, allStyles } =
    useContext(ThemeContext);
  return (
    <>
      <NavComponents
        collapse={collapse}
        setCollapse={setCollapse}
        themeName={themeName}
        allStyles={allStyles}
      />
    </>
  );
};

export default Nav;
