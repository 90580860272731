import React, { useState, useEffect, useContext, useRef } from 'react'
import './Learning.scss'
import { ReserveContext } from '../../App'
import NoSolves from "../../assets/images/timer/nosolves.png"
import { FocusBox, Background, Heading, TextColor, Button, SelectDiv, ButtonInactive } from './StyledComponent'
import { ThemeContext } from '../../context'
import * as XLSX from 'xlsx';
import sampleExcel from './Learning tab.xlsx'

const LearningComponent = ({ allStyles,
    handleCubeType,
    setLoad,
    cubeType,
    disableCubeType, }) => {
    const dropdownCubetypeRef = useRef(null)
    const dropdownFilterRef = useRef(null)
    const [disable, setDisable] = useState(false);
    const [learningData, setLearningData] = useState(null)
    const { themeName } = useContext(ThemeContext)
    const [isOpen, setisOpen] = useState(false)
    const [cubes, setCubes] = useState(true)
    const [algorithms, setAlgorithms] = useState(false)
    var solvesData = useContext(ReserveContext).reserve.solves;
    let currentSession = localStorage.getItem('current_session');
    currentSession = JSON.parse(currentSession)
    let currentSessionId = currentSession.sessionId;
    let currentSolvesData = solvesData ? solvesData.filter((item) => item.cubeType === cubeType && item.sessionId === currentSessionId) : null
    const [solves, setSolves] = useState(currentSolvesData);
    const { matrixDropdown, setmatrixDropdown } = useContext(ReserveContext)
    const [selectedFilter, setSelectedFilter] = useState('');
    const [showFilterDrop, setShowFilterDrop] = useState(false)
    const [filterDropdown, setfilterDropdown] = useState([])

    // return matrix cube 2x2 for 222
    const returnTextCubeType = (input) => {
        switch (input) {
            case "222":
                return "2x2";
            case "333":
                return "3x3";
            case "333OH":
                return "3x3OH";
            case "333BF":
                return "3x3BF";
            case "444":
                return "4x4";
            case "444BF":
                return "4x4BF";
            case "555":
                return "5x5";
            case "555BF":
                return "5x5BF";
            case "666":
                return "6x6";
            case "777":
                return "7x7";
            case "mminx":
                return "minx";
            default:
                return input;
        }
    };

    // solves data set
    useEffect(() => {
        setSolves(currentSolvesData)
    }, [solvesData, currentSessionId, cubeType])

    // cube type set
    useEffect(() => {
        setDisable(disableCubeType);
    }, [disableCubeType]);

    const dropdownRef = useRef()

    // Dropdown click outside
    useEffect(() => {
        // Handle clicks outside the sidebar
        const handleOutsideClick = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setisOpen(!isOpen)
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleOutsideClick);

        // Clean up the event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);

    // Algorithms fetching from excel
    useEffect(() => {
        const fetchData = async () => {

            const response = await fetch(sampleExcel);
            const arrayBuffer = await response.arrayBuffer();
            const data = new Uint8Array(arrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });

            /* DO SOMETHING WITH workbook HERE */
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // Now you can extract data from the sheet
            const learningData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            function convertToObjects(data) {
                const keys = data[0];
                const objects = [];

                for (let i = 1; i < data.length; i++) {
                    const currentArray = data[i];
                    const obj = {};

                    for (let j = 0; j < keys.length; j++) {
                        obj[keys[j]] = currentArray[j];
                    }

                    objects.push(obj);
                }

                return objects;
            }

            const objects = convertToObjects(learningData);
            // console.log(objects);
            let filteredObjects
            if (selectedFilter === 'Select Filter') {

                filteredObjects = objects.filter((item) => item.CubeType_Filter_1 === returnTextCubeType(cubeType));
            }
            else {
                filteredObjects = objects.filter((item) => item.CubeType_Filter_1 === returnTextCubeType(cubeType));
                filteredObjects = filteredObjects.filter((item) => item.CubeType_Filter_2 === selectedFilter);
            }
            setLearningData(filteredObjects);
        };

        fetchData();
    }, [cubeType, selectedFilter]);

    // return matrix cube 2x2 for 222
    const returnMatrixCube = (input) => {
        switch (input) {
            case "222":
                return "2x2";
            case "333":
                return "3x3";
            case "333OH":
                return "3x3 OH";
            case "333BF":
                return "3x3 BLD";
            case "444":
                return "4x4";
            case "444BF":
                return "4x4 BLD";
            case "555":
                return "5x5";
            case "555BF":
                return "5x5 BLD";
            case "666":
                return "6x6";
            case "777":
                return "7x7";
            case "skewb":
                return "Skewb";
            case "pyra":
                return "Pyraminx";
            case "minx":
                return "Megaminx";
            case "clock":
                return "Clock";
            case "sq1":
                return "Square-1";
            default:
                return;
        }
    };

    const cubeTypeOptions = [
        { value: '222', label: '2x2' },
        { value: '333', label: '3x3' },
        { value: '444', label: '4x4' },
        { value: '555', label: '5x5' },
        // { value: '555 Intermediate', label: '5x5 Intermediate'}
    ]

    const handleCube = () => {
        setmatrixDropdown(!matrixDropdown)
        setCubes(true);
        setAlgorithms(false);
        setShowFilterDrop(false);
    }

    const handleFilter = () => {
        setCubes(false);
        setAlgorithms(true);
        setShowFilterDrop(!showFilterDrop);
        setmatrixDropdown(false)

    }

    useEffect(() => {
        if (cubeType === '222') {
            setSelectedFilter('CLL')
            setfilterDropdown(['CLL', 'EG 1'])
        } else if (cubeType === '333') {
            setSelectedFilter('F2L')
            setfilterDropdown(['F2L', 'OLL', 'PLL'])
        } else if (cubeType === '444') {
            setSelectedFilter('OLL Parity')
            setfilterDropdown(['PLL', 'OLL Parity', 'PLL Parity', 'Last 2 Edges Cases'])
        } else if (cubeType === '555') {
            setSelectedFilter('Last 2 Edges Cases')
            setfilterDropdown(['Last 2 Edges Cases'])
        }
    }, [cubeType])

    const handleCloseSidebar = () => {
        setmatrixDropdown(false)
    }


    useEffect(() => {
        // Handle clicks outside the sidebar
        const handleOutsideClick = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target) && e.target.id !== 'select-cubetype') {
                handleCloseSidebar()
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleOutsideClick);

        // Clean up the event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [matrixDropdown]);

    console.log(learningData)

    return (

        <div className='learning-container'>

            <Heading theme={allStyles} className='learning-heading'>
                Learning
            </Heading>

            <div className='solves-btn' style={{ position: 'relative' }}>

                {/* Cubetype Dropdown */}

                <div className='select-buttons'>

                    {/* Matrix Select */}

                    <ButtonInactive theme={allStyles} className="cube-type-select" id="select-cubetype" onClick={handleCube} style={{ borderBottom: `${cubes ? themeName === 'light' ? '5px solid black' : '3px solid white' : ''}`, height: '2rem' }}>

                        {returnMatrixCube(cubeType)}

                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6918 3.81886C14.4945 3.63604 14.2268 3.53333 13.9477 3.53333C13.6686 3.53333 13.401 3.63604 13.2036 3.81886L7.99365 8.64608L2.78371 3.81886C2.5852 3.64122 2.31933 3.54292 2.04337 3.54515C1.7674 3.54737 1.50342 3.64993 1.30827 3.83074C1.11313 4.01155 1.00244 4.25614 1.00004 4.51183C0.997642 4.76752 1.10373 5.01386 1.29545 5.19779L7.24952 10.7145C7.44689 10.8973 7.71456 11 7.99365 11C8.27273 11 8.5404 10.8973 8.73777 10.7145L14.6918 5.19778C14.8892 5.01491 15 4.76691 15 4.50832C15 4.24973 14.8892 4.00173 14.6918 3.81886Z"
                                fill={themeName === "light" ? "black" : "white"} />
                        </svg>


                        {matrixDropdown && <ButtonInactive theme={allStyles} className="matrix-cubetype matrix-cubetype2" ref={dropdownRef}
                            id={`${themeName === 'light' ? 'style-1' : ""}`} >

                            {cubeTypeOptions.map((cube) => {
                                return (
                                    <SelectDiv theme={allStyles} className={`dropdown-matrix-cubetype ${cubeType === cube.value ? 'selected' : ''}`} key={cube.value}
                                        onClick={() => {
                                            handleCubeType(cube.value);
                                            setmatrixDropdown(!matrixDropdown)
                                            setLoad(false);
                                        }}
                                    >
                                        {cube.label}

                                        {cubeType === cube.value && <svg xmlns="http://www.w3.org/2000/svg" viewBox="-49 141 512 512" width="16" height="16" aria-hidden="true" className="dropdown-tick" fill={themeName === 'light' ? "#00000" : "#FFFFFF"}><path d="M127 552.25a24.92 24.92 0 0 1-17.678-7.322l-101-101c-9.763-9.763-9.763-25.593 0-35.355 9.764-9.764 25.592-9.764 35.355 0l83.339 83.34 243.323-242.857c9.771-9.754 25.602-9.74 35.355.033 9.754 9.772 9.739 25.602-.033 35.355l-261 260.5A24.92 24.92 0 0 1 127 552.25"></path></svg>}
                                    </SelectDiv>
                                )
                            })}

                        </ButtonInactive>}

                    </ButtonInactive>

                    {/* Filters dropdown */}
                    <ButtonInactive theme={allStyles} className="cube-type-select filter-dropdown" id="select-filter" onClick={handleFilter} style={{ borderBottom: `${algorithms ? themeName === 'light' ? '5px solid black' : '3px solid white' : ''}`, height: '2rem' }}>

                        {selectedFilter}

                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.6918 3.81886C14.4945 3.63604 14.2268 3.53333 13.9477 3.53333C13.6686 3.53333 13.401 3.63604 13.2036 3.81886L7.99365 8.64608L2.78371 3.81886C2.5852 3.64122 2.31933 3.54292 2.04337 3.54515C1.7674 3.54737 1.50342 3.64993 1.30827 3.83074C1.11313 4.01155 1.00244 4.25614 1.00004 4.51183C0.997642 4.76752 1.10373 5.01386 1.29545 5.19779L7.24952 10.7145C7.44689 10.8973 7.71456 11 7.99365 11C8.27273 11 8.5404 10.8973 8.73777 10.7145L14.6918 5.19778C14.8892 5.01491 15 4.76691 15 4.50832C15 4.24973 14.8892 4.00173 14.6918 3.81886Z"
                                fill={themeName === "light" ? "black" : "white"} />
                        </svg>


                        {showFilterDrop &&
                            <ButtonInactive theme={allStyles} className="matrix-cubetype matrix-cubetype2" ref={dropdownFilterRef}
                                id={`${themeName === 'light' ? 'style-1' : ""}`}  >


                                {filterDropdown?.map((filter) => (
                                    <SelectDiv
                                        theme={allStyles}
                                        className={`dropdown-matrix-cubetype ${selectedFilter === filter ? 'selected' : ''}`}
                                        key={filter}
                                        onClick={
                                            () => {
                                                setSelectedFilter(filter)
                                                setLoad(false);
                                            }
                                        }
                                    >
                                        {filter}
                                        {selectedFilter === filter && (
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-49 141 512 512" width="16" height="16" aria-hidden="true" className="dropdown-tick" fill={themeName === 'light' ? "#00000" : "#FFFFFF"}>
                                                <path d="M127 552.25a24.92 24.92 0 0 1-17.678-7.322l-101-101c-9.763-9.763-9.763-25.593 0-35.355 9.764-9.764 25.592-9.764 35.355 0l83.339 83.34 243.323-242.857c9.771-9.754 25.602-9.74 35.355.033 9.754 9.772 9.739 25.602-.033 35.355l-261 260.5A24.92 24.92 0 0 1 127 552.25"></path>
                                            </svg>
                                        )}
                                    </SelectDiv>
                                ))}


                            </ButtonInactive>
                        }

                    </ButtonInactive>
                </div>

                {/* buttons */}

                <FocusBox theme={allStyles} className='learning-btn' href="https://www.cubelelo.com/pages/xskills" target='_blank' rel="noreferrer" onClick={() => { setisOpen(!isOpen) }}>
                    Recorded Tutorials
                </FocusBox>

            </div>

            {/* ALgorithms Content */}

            <div className='learning-content'>
                {/* {solves?.length === 0 || solves === null ? learningData?.length !== 0 ? "" : <Background theme={allStyles} style={{ borderRadius: '10px' }} className='learning-no-solves-div'> <img src={NoSolves} alt="NoSolves" /></Background> : null} */}

                {cubes && (
                        
                        <>
                            {/* {solves?.map((solve, index) => {
                                return (

                                    <Background theme={allStyles} key={index} className='learning-content-box'>

                                        <div className='learning-content-box-left'>

                                            <Button theme={allStyles} className='learning-content-box-label'>{index + 1}</Button>
                                            <TextColor theme={allStyles} className='learning-content-box-solve-id'>{solve.scramble}</TextColor>
                                            <div className='learning-content-box-buttons'>
                                                <Button theme={allStyles} className='learning-content-box-buttons-dot'>Dot</Button>
                                            </div>

                                        </div>
                                        <div className='learning-content-box-right'>
                                            <img src="/cube-img.png" alt="" />
                                        </div>

                                    </Background>

                                )
                            })} */}
                            
                                {learningData?.length === 0 ?
                                    <Background theme={allStyles} style={{ borderRadius: '10px' }} className='learning-no-solves-div'> <img src={NoSolves} alt="NoSolves" /></Background>
                                    :
                                    <>
                                        {
                                            learningData?.map((solve, index) => {

                                                return (

                                                    <Background theme={allStyles} key={index} className='learning-content-box'>

                                                        <div className='learning-content-box-left'>

                                                            <TextColor theme={allStyles} className='learning-content-box-solve-id'>{solve.Algorithm}</TextColor>
                                                            <div className='learning-content-box-buttons'>
                                                                {solve.Algo_Tag_1 && <Button theme={allStyles} className={`learning-content-box-buttons-dot }`} style={{ color: themeName === 'light' ? "white" : "" }}>{solve.Algo_Tag_1}</Button>}

                                                                {solve.Algo_Tag_2 && <Button theme={allStyles} className={`learning-content-box-buttons-dot `} style={{ color: themeName === 'light' ? "white" : "" }}>{solve.Algo_Tag_2}</Button>}
                                                            </div>

                                                        </div>
                                                        <div className='learning-content-box-right'>
                                                            {console.log(solve.Image_ID)}
                                                            <img src={`https://algorithmimages.s3.ap-south-1.amazonaws.com/${solve.Image_ID}.svg`} alt="" width={100} height={100} />
                                                        </div>

                                                    </Background>

                                                )
                                            })
                                        }
                                    </>
    }

                        </>
                )

                }

                {algorithms && (
                    learningData?.length === 0 ?
                        <Background theme={allStyles} style={{ borderRadius: '10px' }} className='learning-no-solves-div'> <img src={NoSolves} alt="NoSolves" /></Background>
                        :
                        <>
                            {
                                learningData?.map((solve, index) => {

                                    return (

                                        <Background theme={allStyles} key={index} className='learning-content-box'>

                                            <div className='learning-content-box-left'>

                                                <TextColor theme={allStyles} className='learning-content-box-solve-id'>{solve.Algorithm}</TextColor>
                                                <div className='learning-content-box-buttons'>
                                                    {solve.Algo_Tag_1 && <Button theme={allStyles} className={`learning-content-box-buttons-dot }`} style={{ color: themeName === 'light' ? "white" : "" }}>{solve.Algo_Tag_1}</Button>}

                                                    {solve.Algo_Tag_2 && <Button theme={allStyles} className={`learning-content-box-buttons-dot `} style={{ color: themeName === 'light' ? "white" : "" }}>{solve.Algo_Tag_2}</Button>}
                                                </div>

                                            </div>
                                            <div className='learning-content-box-right'>
                                                {console.log(solve.Image_ID)}
                                                <img src={`https://algorithmimages.s3.ap-south-1.amazonaws.com/${solve.Image_ID}.svg`} alt="" width={100} height={100} />
                                            </div>

                                        </Background>

                                    )
                                })
                            }
                        </>
                )
                }

            </div>


        </div>
    )
}

export default LearningComponent