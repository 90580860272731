import React, { useContext, useEffect, useRef } from "react";
import video from "../../assets/images/video.png";
import "./Help.scss";
import { useState } from "react";
import {
  Heading,
  Form,
  InputTextColor,
  FocusBox,
  ScrollContainer,
  TextColor,
} from "./styledComponent";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { useIsMobile } from "../../utils/functions/CustomHooks";
import { ReserveContext } from "../../App";
import HelpFormModal from "../Modals/HelpFormModal/HelpFormModal";
import YouTube from 'react-youtube';

const HelpComponent = ({ allStyles }) => {
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setemail] = useState("");
  const [number, setnumber] = useState("");
  const [message, setmessage] = useState("");
  const form = useRef();
  const isMobile = useIsMobile();
  const { helpmobileform, sethelpmobileform } = useContext(ReserveContext)

  useEffect(() => {
    setTimeout(() => {
      localStorage.setItem("help", false);
    }, 2000)
  }, [])

  const handleChange = (e) => {
    if (e.target.name === "fname") {
      setfname(e.target.value);
    } else if (e.target.name === "lname") {
      setlname(e.target.value);
    } else if (e.target.name === "email") {
      setemail(e.target.value);
    } else if (e.target.name === "number") {
      setnumber(e.target.value);
    } else if (e.target.name === "message") {
      setmessage(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const sendEmail = () => {
        emailjs
          .sendForm(
            "upcubeservice_7asjay8",
            "upcubetemplate_4gsx62t",
            form.current,
            "9a9Iwe6leb8n7a5MY"
          )
          .then(
            (result) => {
              console.log(result.text);
            },
            (error) => {
              console.log(error);
            }
          );
        toast.success("Response Submitted Successfully!", { autoClose: 3000 });
      };
      sendEmail();
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong! try again later.", {
        autoClose: 4000,
      });
    }

    setfname("");
    setlname("");
    setemail("");
    setnumber("");
    setmessage("");
  };

  const onPlayerReady = (e) => {
    e.target.pauseVideo()
  }

  const opts = {
    height: '390',
    // width: '640',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
    <ScrollContainer
      style={isMobile ? {
        // position: 'fixed',
        // overflow: 'hidden',
        // height: "100vh",
        // width: "100vw",
        marginBottom: '7vh'
      } : null}
      className="help-container"
    >

      {/* Mobile Modal */}
      {helpmobileform && <HelpFormModal />}

      <Heading theme={allStyles} className="help-heading">
        Help
      </Heading>

      <div className="help-box">
        <div className="help-video">
          {/* Heading */}
          <div>
            <Heading theme={allStyles}>How to use this timer</Heading>
          </div>

          {/* Video */}
          <div className="video">
            {/* <YouTube videoId="s7WBIYqXgww" opts={opts} onReady={onPlayerReady} />  */}
            <iframe allowFullScreen title="Upcube Web" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" height={360} style={{ width: '100%' }} 
              src="https://www.youtube.com/embed/s7WBIYqXgww">

            </iframe>
          </div>
        </div>

        {/* Help Form */}
        <div className="help-form">
          <Heading theme={allStyles} className="help-contact-heading">Contact Us</Heading>

          {/* Desktop Form */}
          <Form
            theme={allStyles}
            onSubmit={handleSubmit}
            className="help-form-container"
            style={{ marginBottom: "3rem", borderRadius: "12px" }}
          >
            <div className="form">
              <form ref={form} style={{ padding: "1rem" }}>
                <div className="form-content">
                  <div className="form-div">
                    <InputTextColor theme={allStyles} htmlFor="fname">
                      First Name
                    </InputTextColor>

                    <input
                      type="text"
                      value={fname}
                      onChange={handleChange}
                      name="fname"
                      id="fname"
                      required
                    />
                  </div>

                  <div className="form-div">
                    <InputTextColor theme={allStyles} htmlFor="lname">
                      Last Name
                    </InputTextColor>

                    <input
                      type="text"
                      value={lname}
                      onChange={handleChange}
                      name="lname"
                      id="lname"
                      required
                    />
                  </div>

                  <div className="form-div">
                    <InputTextColor theme={allStyles} htmlFor="email">
                      Email
                    </InputTextColor>

                    <input
                      type="email"
                      value={email}
                      onChange={handleChange}
                      name="email"
                      id="email"
                      required
                    />
                  </div>

                  <div className="form-div">
                    <InputTextColor theme={allStyles} htmlFor="number">
                      Phone Number
                    </InputTextColor>

                    <input
                      type="number"
                      value={number}
                      onChange={handleChange}
                      name="number"
                      id="number"
                      required
                    />
                  </div>
                </div>

                <div className="form-div" style={{ marginTop: "1rem" }}>
                  <InputTextColor theme={allStyles} htmlFor="msg">
                    Message
                  </InputTextColor>

                  <textarea
                    type="text"
                    value={message}
                    onChange={handleChange}
                    name="message"
                    id="message"
                    required
                  />
                </div>

                <div className="btn-container">
                  <FocusBox
                    theme={allStyles}
                    className="focus-button"
                    type="submit"
                    value="Send"
                  >
                    Submit
                  </FocusBox>
                </div>
              </form>
            </div>
          </Form>

          {/* Mobile Help Content */}
          {
            isMobile && <Form
              theme={allStyles}
              style={{ marginBottom: "3rem", borderRadius: "12px", padding: '1rem' }}
            >

              <Heading theme={allStyles} className="mobile-help-heading">
                Having trouble with something?

              </Heading>

              <TextColor theme={allStyles} className="mobile-help-sub-heading">
                Send us a message, we will get back to you as soon as possible
              </TextColor>

              <div className="btn-container">
                <FocusBox
                  theme={allStyles}
                  className="focus-button"
                  onClick={() => { sethelpmobileform(!helpmobileform) }}
                >
                  Send Message
                </FocusBox>
              </div>

            </Form>
          }

        </div>
      </div>
    </ScrollContainer>
  );
};

export default HelpComponent;
