/**
 * This JavaScript function extracts the number of hours from a given number of milliseconds and
 * returns it in a formatted string.
 * @param milisecond - The `milisecond` parameter in the `extractHours` function represents a duration
 * of time in milliseconds that you want to convert into hours. The function calculates the number of
 * hours in the given milliseconds and returns a formatted string representing the hours.
 * @returns The function `extractHours` returns a string that represents the number of hours extracted
 * from the given milliseconds. The format of the returned string is either "00:" if the number of
 * hours is 0, or the actual number of hours followed by a colon ":".
 */

export const extractHours = (milisecond) => {
  let value = Math.floor(milisecond / 3600000);
  return `${value === 0 ? "00:" : value + ":"}`;
};

/**
 * The function `extractFormatHours` converts milliseconds to hours in a specific format.
 * @param milisecond - The `extractFormatHours` function takes a parameter `milisecond`, which
 * represents a duration in milliseconds. The function calculates the number of hours in this duration
 * and returns it in a specific format.
 * @returns An empty string or a string with the value of hours followed by a colon.
 */
export const extractFormatHours = (milisecond) => {
  let value = Math.floor(milisecond / 3600000);
  return `${value === 0 ? "" : value + ":"}`;
};

export const extractMinutes = (milisecond) => {
  let value = Math.floor(milisecond / 60000);
  return `${value === 0 ? "" : value % 60 === 0 ? "00:" : (value % 60) + ":"}`;
};

export const extractStatsMinutes = (milisecond) => {
  let value = Math.floor(milisecond / 60000);
  return `${value % 60 === 0 ? "00:" : (value % 60) + ":"}`;
};

export const extractSeconds = (milisecond) => {
  const seconds = `${Math.floor(milisecond / 1000) % 60}`
  const minutes = extractMinutes(milisecond)
  if(minutes.length > 0) {
  return `${seconds.length === 1 ? "0" + seconds : seconds}.`;
  } else {
    return `${Math.floor(milisecond / 1000) % 60}.`
  }
};

export const extractSecondsFormat = (milisecond) => {
    return `${Math.floor(milisecond / 1000) % 60}.`
  
};

export const extractStatsSeconds = (milisecond) => {
  let value = Math.floor(milisecond / 1000);
  return `${value % 60 === 0 ? "00" : value % 60 < 10 ? "0" + value : value}`.slice(0, 2);
};

export const extractCentiseconds = (milisecond) => {
  let value = Math.floor((milisecond % 1000) / 10);
  return `${value < 10 ? "0" : ""}${value}`;
};

export const formatter = (milisecond) => {
  return `${extractMinutes(milisecond)}${extractSeconds(
    milisecond)}${extractCentiseconds(milisecond)}`;
};

export const statsFormatter = (milisecond) => {
  return `${extractHours(milisecond)}${extractStatsMinutes(milisecond)}${extractStatsSeconds(
    milisecond)}`;
};
