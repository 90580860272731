import styled, { css } from 'styled-components';

export const ProfileModal = styled.div`
background-color: ${(props) => props.theme.backgroundColor};
  
`;

export const FocusBox = styled.div`
background-color: ${(props)=> props.theme.FocusBox};
color: ${(props) => props.theme.buttonTextColorSecondary}; 

&:hover {
    background-color: ${(props) => props.theme.hoverBackground};
  }
`

export const InputTextColor = styled.label`
color:  ${(props) => props.theme.buttonTextColorSecondary};
`

export const TextColor = styled.div`
color:  ${(props) => props.theme.buttonTextColorSecondary};
`