import axios from "axios";
import { urls } from "./urls";

export const getAllSessions = async () => {
  return (await axios.get(urls.sessions)).data;
};
export const createSession = async (body) => {
  return (await axios.post(urls.session,body)).data;
};

export const deleteSession = async (sessionId) => {
  return (await axios.delete(urls.session + "/" + sessionId));
};
