import styled, { css } from 'styled-components';

export const Button1 = styled.button`
background:${(props) => props.theme.textColor};
`;

export const Button2 = styled.button`
background:${(props) => props.theme.btnActive};
color:${(props) => props.theme.textColor}
`;

export const Heading = styled.h1`
color:  ${(props) => props.theme.buttonTextColorSecondary};
`

export const TextColor = styled.p`
color:  ${(props) => props.theme.buttonTextColorSecondary};
`

export const Background = styled.div`
background-color: ${(props) => props.theme.backgroundColor};
`