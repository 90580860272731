const domain = "https://upcube.el.r.appspot.com/";
//const domain = "http://localhost:8080/";
const base = domain + "api/v1/protected/";
const authBase = domain + "api/v1/auth/";
 
export const urls = {
  solves: base + "solves",
  sessions: base + "sessions",
  session: base + "session",
  checkUser: authBase + "user",
  signup: authBase + "signup",
  solve: base + "solve",
  bulkSolves:base + "bulkSolves",
};
