import React, { useContext, useState } from 'react'
import LearningComponent from '../../components/Learning/LearningComponent'
import { ThemeContext } from "../../context";

const Learnings = () => {

  const [cubeType, setCubeType] = useState("222");
  const [disableCubeType, setDisableCubeType] = useState(false);
  const [load, setLoad] = useState(false);

  const handleCubeType = (type) => {
    setCubeType(type);
  };
  const { allStyles } =
    useContext(ThemeContext);
  return (
    <LearningComponent
      allStyles={allStyles}
      cubeType={cubeType}
      handleCubeType={handleCubeType}
      setLoad={setLoad}
      disableCubeType={disableCubeType}
    />
  )
}

export default Learnings