import React, { useContext } from "react";
import { useImmer } from "use-immer"; // Import useImmer from the immer library
import "./Right.scss";
import { ThemeContext } from "../../../context";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import logo from "../../../assets/images/logo.png";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import EventIcon from "@mui/icons-material/Event";
import FlagIcon from "@mui/icons-material/Flag";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { SignUpRightContainer } from "./styledComponents";

const Right = () => {
  const { themeName, allStyles } = useContext(ThemeContext);

  // Initialize the state using useImmer
  const [inputValues, setInputValues] = useImmer({
    username: "",
    email: "",
    name: "",
    dateOfBirth: "",
    country: "",
    state: "",
    city: "",
  });

  // Handle input changes
  const handleInputChange = (e, field) => {
    const { value } = e.target;
    setInputValues((draft) => {
      draft[field] = value;
    });
  };

  const handleSubmit = () => {
    console.log(inputValues);
  };

  return (
    <div className="signup-right">
        <SignUpRightContainer
          theme={allStyles}
          className="signup-right-container"
        >
          <div className="signup-logo-container">
            <img src={logo} alt="" />
          </div>
          <div className="signup-right-bottom">
            <div className="signup-fields-container">
              <h1>Enter Your Details</h1>
              <div className="input-container">
                <PersonIcon fontSize="small" color="action" className="icon" />
                <input
                  type="text"
                  placeholder="Username"
                  className="input"
                  value={inputValues.username}
                  onChange={(e) => handleInputChange(e, "username")}
                />
              </div>
              <div className="input-container">
                <EmailIcon fontSize="small" color="action" className="icon" />
                <input
                  type="text"
                  placeholder="Email"
                  className="input"
                  value={inputValues.email}
                  onChange={(e) => handleInputChange(e, "email")}
                />
              </div>
              <div className="input-container">
                <AccountBoxIcon
                  fontSize="small"
                  color="action"
                  className="icon"
                />
                <input
                  type="text"
                  placeholder="Name"
                  className="input"
                  value={inputValues.name}
                  onChange={(e) => handleInputChange(e, "name")}
                />
              </div>
              {/* <div className="input-container">
                <EventIcon fontSize="small" color="action" className="icon" />
                <input
                  type="date"
                  placeholder="Date of Birth"
                  className="input"
                  value={inputValues.dateOfBirth}
                  onChange={(e) => handleInputChange(e, "dateOfBirth")}
                />
              </div> */}
              {/* <div className="input-two-fit">
                <div className="input-container fit">
                  <LocationCityIcon
                    fontSize="small"
                    color="action"
                    className="icon"
                  />
                  <input
                    type="text"
                    placeholder="City"
                    className="input fit-input"
                    value={inputValues.city}
                    onChange={(e) => handleInputChange(e, "city")}
                  />
                </div>
                <div className="input-container fit">
                  <LocationOnIcon
                    fontSize="small"
                    color="action"
                    className="icon "
                  />
                  <input
                    type="text"
                    placeholder="State"
                    className="input fit-input"
                    value={inputValues.state}
                    onChange={(e) => handleInputChange(e, "state")}
                  />
                </div>
              </div> */}
              {/* <div className="input-container">
                <FlagIcon fontSize="small" color="action" className="icon" />
                <input
                  type="text"
                  placeholder="Country"
                  className="input"
                  value={inputValues.country}
                  onChange={(e) => handleInputChange(e, "country")}
                />
              </div> */}
            </div>
            <div className="auth-email-button-container">
              <div className="auth-float">
                <button className="auth-right-button" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </SignUpRightContainer>
    
    </div>
  );
};

export default Right;
