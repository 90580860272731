import React, { useState, useContext, useEffect, useRef } from 'react'
import './Settings.scss'
import { ButtonActive, ButtonInActive, SettingBox, ButtonInactive, SelectDiv, Heading, TextColor, Background, CircleDots, FocusBox } from './StyledComponent'
import { ThemeContext } from "../../context";
import { IoIosArrowForward, IoIosSettings } from "react-icons/io";
import { PiMonitorFill } from "react-icons/pi"
import { ReserveContext } from '../../App';
import Papa from 'papaparse';
import { toast } from 'react-toastify';
import { useIsMobile } from '../../utils/functions/CustomHooks';
import { LuTimerOff } from 'react-icons/lu'
import { alpha, styled } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
// import { formattedData } from '../../utils/functions/csTimer';
import ImportModal from '../Modals/ImportModal/ImportModal';
import ImportModalUpcube from '../Modals/ImportModal/ImportModalUpcube';

const SettingsComponent = ({ allStyles }) => {
    const isMobile = useIsMobile()
    const importDropdownRef = useRef(null)
    const [Timer, setTimer] = useState(`${isMobile ? false : true}`)
    const [Display, setDisplay] = useState(false)
    const [Other, setOther] = useState(false)
    const [ScrambleSize, setScrambleSize] = useState(localStorage.getItem('scrambleFontSize'))
    const [TimerSize, setTimerSize] = useState(localStorage.getItem('timerFontSize'))
    const { themeName, setThemeName } = useContext(ThemeContext);
    const [importDropdown, setimportDropdown] = useState(false)
    const {
        setScrambleFontSize,
        setTimerFontSize,
        ManualTime,
        setManualTime,
        TimerDisplay,
        setTimerDisplay,
        DeleteSolve,
        setDeleteSolve,
        VoiceAlert,
        setVoiceAlert,
        Inspection,
        setInspection,
        importModal,
        setimportModal,
        importModalUpcube,
        setimportModalUpcube,
    } = useContext(ReserveContext)
    var solvesData = useContext(ReserveContext).reserve.solves;

    const handleExportData = () => {
        let count = 1;
            let data = solvesData.map((obj, index) => {
                let prevSession;
                if (index === 0) {
                    prevSession = solvesData[0].sessionId;
                } else {
                    prevSession = solvesData[index - 1].sessionId;
                }
                let curSession = solvesData[index].sessionId;
                if (curSession !== prevSession) {
                    count++;
                }
                // Creating a new object with the added property
                const newObj = {
                    "Date and Time": obj.dateTime,
                    "Solve Time (Seconds)": obj.time / 1000,
                    "Cube Type": obj.cubeType,
                    "Scramble": obj.scramble,
                    sessionName: `session-${count}`, 
                    "DNF": obj.dnfAuto || obj.dnfManual,
                    "plus 2": obj.plus2Auto || obj.plus2Manual
                };
                return newObj;
        });

        const handleImportData = () => {
            toast('This feature is coming soon')
        }


        const csvData = Papa.unparse(data);
        const blob = new Blob([csvData], { type: 'text/csv' });
        // console.log(data);
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'allSolves.csv';
        a.click();
        URL.revokeObjectURL(url);
    }

    useEffect(() => {
        setScrambleSize(localStorage.getItem("scrambleFontSize"))
        setTimerSize(localStorage.getItem("timerFontSize"))
        setimportModal(false)
        setimportModalUpcube(false)
    }, [])

    useEffect(() => {
        if (isMobile) {
            setTimer(false)
            setDisplay(false)
            setOther(false)
        } else {
            setTimer(true)
            setDisplay(false)
            setOther(false)
        }
    }, [isMobile])

    const handleManualTime = () => {
        setManualTime(!ManualTime)
        if (!ManualTime) {
            setTimerDisplay(false)
            setInspection(false)
            localStorage.setItem("inspection", false)
            localStorage.setItem("timerDisplay", false)
            localStorage.setItem("manualTime", true)
        } else {
            localStorage.setItem("manualTime", false)
        }
    }

    const handleTimerDisplay = () => {
        setTimerDisplay(!TimerDisplay)
        if (!TimerDisplay) {
            setManualTime(false)
            localStorage.setItem("manualTime", false)
            localStorage.setItem("timerDisplay", true)
        } else {
            localStorage.setItem("timerDisplay", false)
        }
    }

    const handleDeleteSolve = () => {
        setDeleteSolve(!DeleteSolve)
        if (!DeleteSolve) {
            localStorage.setItem("deleteSolve", true)
        } else {
            localStorage.setItem("deleteSolve", false)
        }
    }

    const handleVoiceAlert = () => {
        setVoiceAlert(!VoiceAlert)
        if (!VoiceAlert) {
            setInspection(true)
            localStorage.setItem("voiceAlert", true)
            localStorage.setItem("inspection", true)
        } else {
            localStorage.setItem("voiceAlert", false)
        }
    }

    const handleInspection = () => {
        setInspection(!Inspection)
        if (!Inspection) {
            setManualTime(false)
            localStorage.setItem("inspection", true)
            localStorage.setItem("manualTime", false)
        } else {
            localStorage.setItem("inspection", false)
        }
    }


    const handleTimer = () => {
        if (Timer) {
            setTimer(false)
        } else {
            setTimer(true)
        }
        setDisplay(false)
        setOther(false)
    }

    const handleDisplay = () => {
        setTimer(false)
        if (Display) {
            setDisplay(false)
        } else {
            setDisplay(true)
        }
        setOther(false)
    }

    const handleOther = () => {
        setTimer(false)
        setDisplay(false)
        if (Other) {
            setOther(false)
        } else {
            setOther(true)
        }
    }

    const handleLight = () => {
        localStorage.setItem('theme', 'light')
        setThemeName(themeName === "dark" || themeName === "default" ? "light" : "default")
    }

    const handleDark = () => {
        localStorage.setItem('theme', 'dark')
        setThemeName(themeName === "light" || themeName === "default" ? "dark" : "default")
    }

    const handleStandard = () => {
        localStorage.setItem('theme', 'default')
        setThemeName(themeName === "light" || themeName === "dark" ? "default" : "default")
    }

    // handle save changes
    const handleSaveChange = () => {
        if (localStorage.getItem('scrambleFontSize') === ScrambleSize && localStorage.getItem('TimerFontSize') === TimerSize) {
            toast.error('No Changes Found', { autoClose: 1000, className: 'copy-toast' })
        } else {
            setScrambleFontSize(ScrambleSize)
            setTimerFontSize(TimerSize)
            setThemeName(themeName)
            toast.success('Changes Saved Successfully', { autoClose: 1000, className: 'copy-toast' })
        }
    }

    // handle reset settings
    const handleResetSettings = () => {
        localStorage.setItem('scrambleFontSize', 18)
        localStorage.setItem('timerFontSize', 60)
        setScrambleSize(localStorage.getItem("scrambleFontSize"))
        setTimerSize(localStorage.getItem("timerFontSize"))
        localStorage.setItem('theme', 'default')
        localStorage.setItem('manualTime', false)
        localStorage.setItem('inspection', true)
        localStorage.setItem('voiceAlert', false)
        localStorage.setItem('timerDisplay', false)
        localStorage.setItem('deleteSolve', false)
        setManualTime(false)
        setInspection(true)
        setVoiceAlert(false)
        setTimerDisplay(false)
        setDeleteSolve(false)
        toast.success('Settings Saved Successfully .', { autoClose: 1000, className: 'copy-toast' })
    }

    const PinkSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: purple[700],
            '&:hover': {
                backgroundColor: alpha(purple[700], theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: purple[700],
        },
    }));

    const importDropdownOptions = [
        { name: 'Import from CsTimer' },
        { name: 'Import from UpCube' },
    ]

    const handleImportDropdown = () => { setimportDropdown(!importDropdown) }

    const handleCloseSidebar = () => { setimportDropdown(false) }

    useEffect(() => {
        // Handle clicks outside the sidebar
        const handleOutsideClick = (e) => {
            if (importDropdownRef.current && !importDropdownRef.current.contains(e.target) && e.target.id !== 'cstimer' && e.target.id !== 'upcube') {
                handleCloseSidebar()
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleOutsideClick);

        // Clean up the event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [importDropdownRef]);

    return (

        <>
            {importModal && <ImportModal />}

            {importModalUpcube && <ImportModalUpcube />}

            <div className='setting-container'>

                <div className='setting-header'>

                    {/* stats heading */}

                    <Heading theme={allStyles} className='setting-heading'>
                        Settings
                    </Heading>

                </div>

                <div className='settings-main-content'>

                    {/* Dropdown buttons to control content */}

                    <div className="settings-buttons-container">
                        <Background theme={allStyles} className="settings-btn-inner-container">

                            {/* Timer Settings */}
                            <div theme={allStyles} className={`settings-btn-field ${Timer && themeName === 'default' ? "active-default-timer" : ""} ${Timer && themeName === 'dark' ? "active-dark" : ""} ${Timer && themeName === 'light' ? "active-light" : ""}`} onClick={handleTimer}>

                                <div className='settings-btn-left'>
                                    <span className="settings-btn-icon">
                                        <LuTimerOff className={`settings-btn-icon ${themeName === 'light' && !Timer ? 'dropdown-color' : ''}`} />
                                    </span>
                                    <span className={`settings-btn-text ${themeName === 'light' && !Timer ? 'dropdown-color' : ''}`}>Timer Settings</span>
                                </div>
                                <span className="settings-btn-arrow">
                                    <IoIosArrowForward className={`${themeName === 'light' && !Timer ? 'dropdown-color' : ''}`} />
                                </span>

                            </div>

                            {/* display  */}
                            <div className={`settings-btn-field ${Display && themeName === 'default' ? "active-default-display" : ""} ${Display && themeName === 'dark' ? "active-dark" : ""} ${Display && themeName === 'light' ? "active-light" : ""}`} onClick={handleDisplay}>

                                <div className='settings-btn-left'>
                                    <span className="settings-btn-icon">
                                        <PiMonitorFill className={`settings-btn-icon ${themeName === 'light' && !Display ? 'dropdown-color' : ''}`} />
                                    </span>
                                    <span className={`settings-btn-text ${themeName === 'light' && !Display ? 'dropdown-color' : ''}`}>Display</span>

                                </div>
                                <span className="settings-btn-arrow">
                                    <IoIosArrowForward className={`${themeName === 'light' && !Display ? 'dropdown-color' : ''}`} />
                                </span>
                            </div>

                            {/* other settings */}
                            <div className={`settings-btn-field ${Other && themeName === 'default' ? "active-default-other" : ""} ${Other && themeName === 'dark' ? "active-dark" : ""} ${Other && themeName === 'light' ? "active-light" : ""}`} onClick={handleOther}>

                                <div className='settings-btn-left'>
                                    <span className="settings-btn-icon">
                                        <IoIosSettings className={`settings-btn-icon ${themeName === 'light' && !Other ? 'dropdown-color' : ''} `} />
                                    </span>
                                    <span className={`settings-btn-text ${themeName === 'light' && !Other ? 'dropdown-color' : ''}`}>Other Settings</span>

                                </div>
                                <span className="settings-btn-arrow">
                                    <IoIosArrowForward className={`${themeName === 'light' && !Other ? 'dropdown-color' : ''}`} />
                                </span>
                            </div>

                        </Background>
                    </div>

                    {Timer && <div className='setting-content setting-web'>

                        {/* Manual Time */}
                        <SettingBox theme={allStyles} className='setting-box'>

                            <TextColor theme={allStyles} className='setting-box-text'>
                                Manual Time Entry <br />
                                <span style={{ fontWeight: '300', fontSize: '15px' }}>
                                    mm:ss.xx format
                                </span>
                            </TextColor>

                            <PinkSwitch color="warning" checked={ManualTime} onChange={handleManualTime} inputProps={{ 'aria-label': 'controlled' }} />

                        </SettingBox>

                        {/* Inspection */}
                        <SettingBox theme={allStyles} className='setting-box'>

                            <TextColor theme={allStyles} className='setting-box-text'>
                                Inspection<br />
                                <span style={{ fontWeight: '300', fontSize: '15px' }}>
                                    15 Seconds
                                </span>
                            </TextColor>

                            <PinkSwitch color="warning" checked={Inspection} onChange={handleInspection} inputProps={{ 'aria-label': 'controlled' }} />

                        </SettingBox>

                        {/* Voice Alert on Inspection */}
                        <SettingBox theme={allStyles} className='setting-box'>

                            <TextColor theme={allStyles} className='setting-box-text'>
                                Voice Alert on Inspection<br />
                                <span style={{ fontWeight: '300', fontSize: '15px' }}>
                                    Alert on 8 and 12 seconds
                                </span>
                            </TextColor>

                            <PinkSwitch color="warning" checked={VoiceAlert} onChange={handleVoiceAlert} inputProps={{ 'aria-label': 'controlled' }} />


                        </SettingBox>

                        {/* Timer Display */}
                        <SettingBox theme={allStyles} className='setting-box'>

                            <TextColor theme={allStyles} className='setting-box-text'>
                                Hide Timer while Solving<br />
                                <span style={{ fontWeight: '300', fontSize: '15px' }}>
                                    Hide timer to avoid distraction during a solve
                                </span>
                            </TextColor>

                            <PinkSwitch color="warning" checked={TimerDisplay} onChange={handleTimerDisplay} inputProps={{ 'aria-label': 'controlled' }} />

                        </SettingBox>

                        {/* Delete Solve */}
                        <SettingBox theme={allStyles} className='setting-box'>

                            <TextColor theme={allStyles} className='setting-box-text'>
                                Confirm Before Deleting a Solve<br />
                                <span style={{ fontWeight: '300', fontSize: '15px' }}>
                                    Get a confirmation box before deleting any solve
                                </span>
                            </TextColor>

                            <PinkSwitch color="warning" checked={DeleteSolve} onChange={handleDeleteSolve} inputProps={{ 'aria-label': 'controlled' }} />


                        </SettingBox>

                    </div>}

                    {Display && <div className='setting-content setting-web'>

                        {/* scramble font size */}
                        <SettingBox theme={allStyles} className='setting-display-box'>

                            <div className='setting-display-box-top'>

                                {/* Content */}

                                <div className='setting-display-box-top-left'>

                                    <TextColor theme={allStyles} className='setting-display-box-top-heading'>Scramble Font Size</TextColor>

                                    {/* <TextColor theme={allStyles} className='setting-display-box-text'>
                                    Lorem ipsum dolor sit amet consectetur. Tellus quis lectus mauris elit bibendum in..
                                </TextColor> */}

                                </div>

                                {/* Font Size Slider */}

                                <div className='setting-display-font-select'>

                                    <div className="circle-dots">

                                        {[ 22, 24, 26,28,30].map((size) => {
                                            return (
                                                <CircleDots
                                                    theme={allStyles}
                                                    key={size}
                                                    onClick={() => setScrambleSize(size)}
                                                    className={ScrambleSize == size ? themeName === 'default' ? 'selected-default' : themeName === 'dark' ? 'selected-dark' : themeName === 'light' ? 'selected-dark' : '' : ''}
                                                ></CircleDots>
                                            )
                                        })}

                                    </div>

                                </div>

                            </div>

                            <TextColor theme={allStyles} className='setting-content-box-text' style={{ fontSize: `${ScrambleSize}px`, width: '100%', fontFamily: '"Ubuntu Mono", monospace' }}>
                                F' U2 R2 B' U2 F L2 F' D2 B2 L2 D2 L U F2 U L U B R'
                            </TextColor>

                        </SettingBox>

                        {/* timer font size */}
                        <SettingBox theme={allStyles} className='setting-display-box'>

                            <div className='setting-display-box-top'>

                                <div className='setting-display-box-top-left'>

                                    <TextColor theme={allStyles} className='setting-display-box-top-heading'>Timer Font Size</TextColor>

                                    {/* <TextColor theme={allStyles} className='setting-display-box-text'>
                                    Lorem ipsum dolor sit amet consectetur. Tellus quis lectus mauris elit bibendum in.
                                </TextColor> */}

                                </div>

                                <div className='setting-display-font-select'>

                                    <div className="circle-dots">

                                        {[ 70, 75, 80,85,90].map((size) => {
                                            return (
                                                <CircleDots
                                                    theme={allStyles}
                                                    key={size}
                                                    onClick={() => setTimerSize(size)}
                                                    className={TimerSize == size ? themeName === 'default' ? 'selected-default' : themeName === 'dark' ? 'selected-dark' : themeName === 'light' ? 'selected-dark' : '' : ''}
                                                ></CircleDots>
                                            )
                                        })}

                                    </div>

                                </div>

                            </div>

                            <TextColor theme={allStyles} className='setting-display-box-timer-text' style={{ fontSize: `${TimerSize}px` }}>
                                0.00
                            </TextColor>

                        </SettingBox>

                        {/* themes */}
                        <SettingBox theme={allStyles} className='setting-box'>

                            <TextColor theme={allStyles} className='setting-box-text'>
                                Theme
                            </TextColor>

                            <div className='setting-display-btn-box'>

                                {themeName === 'light' ? <ButtonActive theme={allStyles} className='focus-button' onClick={handleLight} style={{ color: `${themeName === 'light' ? 'white' : 'white'}` }}>
                                    Light
                                </ButtonActive> :
                                    <ButtonInActive theme={allStyles} className='focus-button' onClick={handleLight}>
                                        Light
                                    </ButtonInActive>
                                }

                                {themeName === 'default' ? <ButtonActive theme={allStyles} className='focus-button' onClick={handleStandard} style={{ color: `${themeName === 'default' ? 'black' : 'white'}` }}>
                                    Standard
                                </ButtonActive> :
                                    <ButtonInActive theme={allStyles} className='focus-button' onClick={handleStandard}>
                                        Standard
                                    </ButtonInActive>
                                }

                                {themeName === 'dark' ? <ButtonActive theme={allStyles} className='focus-button' onClick={handleDark} style={{ color: `${themeName === 'dark' ? 'black' : 'white'}` }}>
                                    Dark
                                </ButtonActive> :
                                    <ButtonInActive theme={allStyles} className='focus-button' onClick={handleDark}>
                                        Dark
                                    </ButtonInActive>
                                }

                            </div>

                        </SettingBox>

                        {/* save change btn  */}
                        <div className='setting-last-box'>

                            <FocusBox theme={allStyles} className={`focus-button`} onClick={handleSaveChange}>Save Changes</FocusBox>

                        </div>

                    </div>}

                    {Other && <div className='setting-content setting-web'>

                        {/* Export Data */}
                        <SettingBox theme={allStyles} className='setting-content-box'>

                            <div className='setting-content-box-top'>

                                <TextColor theme={allStyles} className='setting-content-box-top-heading'>Export Data</TextColor>

                                <FocusBox theme={allStyles} className={`focus-button  solve-modal-scramble-button`} onClick={handleExportData}>
                                    Export
                                </FocusBox>
                            </div>

                            <TextColor theme={allStyles} className='setting-content-box-text'>
                                Backup for your solve and sessions data, which can be imported later if needed
                            </TextColor>

                        </SettingBox>

                        {/* Import Data */}
                        <SettingBox theme={allStyles} className='setting-content-box'>

                            <div className='setting-content-box-top'>

                                <TextColor theme={allStyles} className='setting-content-box-top-heading'>Import Data</TextColor>

                                <FocusBox theme={allStyles} className={`focus-button  solve-modal-scramble-button`} style={{ position: 'relative', display: 'flex', alignItems: 'center', gap: '0.25rem' }} onClick={handleImportDropdown}>
                                    Import
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.6918 3.81886C14.4945 3.63604 14.2268 3.53333 13.9477 3.53333C13.6686 3.53333 13.401 3.63604 13.2036 3.81886L7.99365 8.64608L2.78371 3.81886C2.5852 3.64122 2.31933 3.54292 2.04337 3.54515C1.7674 3.54737 1.50342 3.64993 1.30827 3.83074C1.11313 4.01155 1.00244 4.25614 1.00004 4.51183C0.997642 4.76752 1.10373 5.01386 1.29545 5.19779L7.24952 10.7145C7.44689 10.8973 7.71456 11 7.99365 11C8.27273 11 8.5404 10.8973 8.73777 10.7145L14.6918 5.19778C14.8892 5.01491 15 4.76691 15 4.50832C15 4.24973 14.8892 4.00173 14.6918 3.81886Z" 
                                        fill={themeName==='light'?"black":"white"} />
                                    </svg>

                                    {importDropdown && <ButtonInactive theme={allStyles} className="import-dropdown" onClick={handleImportDropdown} ref={importDropdownRef} >

                                        <SelectDiv theme={allStyles} id='cstimer' onClick={() => { setimportModal(!importModal) }} >
                                            Import from CsTimer
                                        </SelectDiv>

                                        <SelectDiv theme={allStyles} id='upcube' onClick={()=>{setimportModalUpcube(!importModalUpcube)}} >
                                            Import from UpCube
                                        </SelectDiv>

                                    </ButtonInactive>}

                                </FocusBox>



                            </div>

                            <TextColor theme={allStyles} className='setting-content-box-text'>
                                Import data from csTimer or UpCube.
                            </TextColor>

                        </SettingBox>

                        {/* Reset Setting */}
                        <SettingBox theme={allStyles} className='setting-content-box'>

                            <div className='setting-content-box-top'>

                                <TextColor theme={allStyles} className='setting-content-box-top-heading'>Reset Setting</TextColor>

                                <FocusBox theme={allStyles} className={`focus-button  solve-modal-scramble-button`} onClick={handleResetSettings}>
                                    Reset
                                </FocusBox>
                            </div>

                            <TextColor theme={allStyles} className='setting-content-box-text'>
                                Reset everything in the settings to default values.
                            </TextColor>

                        </SettingBox>

                    </div>}

                </div>

                {/* Timer Mobile */}
                <div className='setting-mobile'>

                    {/* Dropdown buttons to control content */}
                    <div className="settings-buttons-container-mobile">

                        <div className="settings-btn-inner-container">

                            {/* Timer Settings */}
                            <div className={`settings-btn-field ${Timer && themeName === 'default' ? "active-default-timer" : ""} ${Timer && themeName === 'dark' ? "active-dark" : ""} ${Timer && themeName === 'light' ? "active-light" : ""} ${!Timer && themeName === 'default' ? 'color-default' : ''} ${!Timer && themeName === 'dark' ? 'color-dark' : ''} ${!Timer && themeName === 'light' ? 'color-light' : ''}`} onClick={handleTimer}>

                                <div className='settings-btn-left'>

                                    <span className="settings-btn-icon">
                                        <LuTimerOff className={`settings-btn-icon ${themeName === 'light' && !Timer ? 'dropdown-color' : ''}`} />
                                    </span>

                                    <span className={`settings-btn-text ${themeName === 'light' && !Timer ? 'dropdown-color' : ''}`}>Timer Settings</span>
                                </div>

                                <span className="settings-btn-arrow">
                                    <IoIosArrowForward className={`${themeName === 'light' && !Timer ? 'dropdown-color' : ''}`} style={{ transform: `${Timer ? 'rotate(90deg)' : ''}` }} />
                                </span>

                            </div>

                            {Timer && <div className='setting-content setting-mobile'>

                                {/* Manual Time */}
                                <SettingBox theme={allStyles} className='setting-box'>

                                    <TextColor theme={allStyles} className='setting-box-text'>
                                        Manual Time Entry<br />
                                        <span style={{ fontWeight: '300', fontSize: '15px' }}>
                                            (mm:ss.xx format)
                                        </span>
                                    </TextColor>

                                    <PinkSwitch color="warning" checked={ManualTime} onChange={handleManualTime} inputProps={{ 'aria-label': 'controlled' }} />

                                </SettingBox>

                                {/* Inspection */}
                                <SettingBox theme={allStyles} className='setting-box'>

                                    <TextColor theme={allStyles} className='setting-box-text'>
                                        Inspection<br />
                                        <span style={{ fontWeight: '300', fontSize: '15px' }}>
                                            15 Seconds
                                        </span>
                                    </TextColor>

                                    <PinkSwitch color="warning" checked={Inspection} onChange={handleInspection} inputProps={{ 'aria-label': 'controlled' }} />

                                </SettingBox>

                                {/* Voice Alert on Inspection */}
                                <SettingBox theme={allStyles} className='setting-box'>

                                    <TextColor theme={allStyles} className='setting-box-text'>
                                        Voice Alert on Inspection<br />
                                        <span style={{ fontWeight: '300', fontSize: '15px' }}>
                                            Alert on 8 and 12 seconds
                                        </span>
                                    </TextColor>

                                    <PinkSwitch color="warning" checked={VoiceAlert} onChange={handleVoiceAlert} inputProps={{ 'aria-label': 'controlled' }} />

                                </SettingBox>

                                {/* Hide Timer */}
                                <SettingBox theme={allStyles} className='setting-box'>

                                    <TextColor theme={allStyles} className='setting-box-text'>
                                        Hide Timer while Solving<br />
                                        <span style={{ fontWeight: '300', fontSize: '15px' }}>
                                            Hide timer to avoid distraction during a solve
                                        </span>
                                    </TextColor>

                                    <PinkSwitch color="warning" checked={TimerDisplay} onChange={handleTimerDisplay} inputProps={{ 'aria-label': 'controlled' }} />

                                </SettingBox>

                                {/* Delete Solve */}
                                <SettingBox theme={allStyles} className='setting-box'>

                                    <TextColor theme={allStyles} className='setting-box-text'>
                                        Confirm Before Deleting a Solve<br />
                                        <span style={{ fontWeight: '300', fontSize: '15px' }}>
                                            Get a confirmation box before deleting any solve
                                        </span>
                                    </TextColor>

                                    <PinkSwitch color="warning" checked={DeleteSolve} onChange={handleDeleteSolve} inputProps={{ 'aria-label': 'controlled' }} />

                                </SettingBox>

                            </div>}

                            {/* display  */}
                            <div className={`settings-btn-field ${Display && themeName === 'default' ? "active-default-display" : ""} ${Display && themeName === 'dark' ? "active-dark" : ""} ${Display && themeName === 'light' ? "active-light" : ""} ${!Display && themeName === 'default' ? 'color-default' : ''} ${!Display && themeName === 'dark' ? 'color-dark' : ''} ${!Display && themeName === 'light' ? 'color-light' : ''}
                        `} onClick={handleDisplay} style={{ borderRadius: `${isMobile ? '0px 0px 0 0' : ''}` }}>

                                <div className='settings-btn-left'>
                                    <span className="settings-btn-icon">
                                        <PiMonitorFill className={`settings-btn-icon ${themeName === 'light' && !Display ? 'dropdown-color' : ''}`} />
                                    </span>
                                    <span className={`settings-btn-text ${themeName === 'light' && !Display ? 'dropdown-color' : ''}`}>Display</span>

                                </div>
                                <span className="settings-btn-arrow">
                                    <IoIosArrowForward className={`${themeName === 'light' && !Display ? 'dropdown-color' : ''}`} style={{ transform: `${Display ? 'rotate(90deg)' : ''}` }} />
                                </span>
                            </div>

                            {Display && <div className='setting-content setting-mobile'>

                                {/* Scramble Font Size */}
                                <SettingBox theme={allStyles} className='setting-display-box'>

                                    <div className='setting-display-box-top'>

                                        {/* Content */}

                                        <div className='setting-display-box-top-left'>

                                            <TextColor theme={allStyles} className='setting-display-box-top-heading'>Scramble font size</TextColor>

                                            {/* <TextColor theme={allStyles} className='setting-display-box-text'>
                                            Lorem ipsum dolor sit amet consectetur. Tellus quis lectus mauris elit bibendum in..
                                        </TextColor> */}

                                        </div>

                                        {/* Font Size Slider */}

                                        <div className='setting-display-font-select'>

                                            <div className="circle-dots">

                                                {[18, 20, 22, 24, 26].map((size) => {
                                                    return (
                                                        <CircleDots
                                                            theme={allStyles}
                                                            key={size}
                                                            onClick={() => {setScrambleSize(size);localStorage.setItem('scrambleFontSize', size)}}
                                                            className={ScrambleSize == size ? themeName === 'default' ? 'selected-default' : themeName === 'dark' ? 'selected-dark' : themeName === 'light' ? 'selected-dark' : '' : ''}
                                                        ></CircleDots>
                                                    )
                                                })}

                                            </div>

                                        </div>

                                    </div>

                                    <TextColor theme={allStyles} className='setting-content-box-text' style={{ fontSize: `${ScrambleSize}px`, width: '100%', fontFamily: '"Space Mono", monospace' }}>
                                        F' U2 R2 B' U2 F L2 F' D2 B2 L2 D2 L U F2 U L U B R'
                                    </TextColor>

                                </SettingBox>

                                {/* Timer Font Size */}
                                <SettingBox theme={allStyles} className='setting-display-box'>

                                    <div className='setting-display-box-top'>

                                        <div className='setting-display-box-top-left'>

                                            <TextColor theme={allStyles} className='setting-display-box-top-heading'>Timer font size</TextColor>

                                            {/* <TextColor theme={allStyles} className='setting-display-box-text'>
                                            Lorem ipsum dolor sit amet consectetur. Tellus quis lectus mauris elit bibendum in.
                                        </TextColor> */}

                                        </div>

                                        <div className='setting-display-font-select'>

                                            <div className="circle-dots">

                                                {[40, 45, 50, 55, 60].map((size) => {
                                                    return (
                                                        <CircleDots
                                                            theme={allStyles}
                                                            key={size}
                                                            onClick={() => {setTimerSize(size);localStorage.setItem("timerFontSize",size)}}
                                                            className={TimerSize == size ? themeName === 'default' ? 'selected-default' : themeName === 'dark' ? 'selected-dark' : themeName === 'light' ? 'selected-dark' : '' : ''}
                                                        ></CircleDots>
                                                    )
                                                })}

                                            </div>

                                        </div>

                                    </div>

                                    <TextColor theme={allStyles} className='setting-display-box-timer-text' style={{ fontSize: `${TimerSize}px` }}>
                                        0.00
                                    </TextColor>

                                </SettingBox>

                                {/* Theme */}
                                <SettingBox theme={allStyles} className='setting-box'>

                                    <TextColor theme={allStyles} className='setting-box-text'>
                                        Theme
                                    </TextColor>

                                    <div className='setting-display-btn-box'>

                                        {themeName === 'light' ? <ButtonActive theme={allStyles} className='focus-button' onClick={handleLight} style={{ color: `${themeName === 'light' ? 'white' : 'white'}` }}>
                                            Light
                                        </ButtonActive> :
                                            <ButtonInActive theme={allStyles} className='focus-button' onClick={handleLight}>
                                                Light
                                            </ButtonInActive>
                                        }

                                        {themeName === 'default' ? <ButtonActive theme={allStyles} className='focus-button' onClick={handleStandard} style={{ color: `${themeName === 'default' ? 'black' : 'white'}` }}>
                                            Standard
                                        </ButtonActive> :
                                            <ButtonInActive theme={allStyles} className='focus-button' onClick={handleStandard}>
                                                Standard
                                            </ButtonInActive>
                                        }

                                        {themeName === 'dark' ? <ButtonActive theme={allStyles} className='focus-button' onClick={handleDark} style={{ color: `${themeName === 'dark' ? 'black' : 'white'}` }}>
                                            Dark
                                        </ButtonActive> :
                                            <ButtonInActive theme={allStyles} className='focus-button' onClick={handleDark}>
                                                Dark
                                            </ButtonInActive>
                                        }

                                    </div>

                                </SettingBox>

                                {/* save changes btn
                                <div className='setting-last-box'>

                                    <FocusBox theme={allStyles} className={`focus-button`} onClick={handleSaveChange}>Save Changes</FocusBox>

                                </div> */}

                            </div>}

                            {/* other settings */}
                            <div className={`settings-btn-field ${Other && themeName === 'default' ? "active-default-other" : ""} ${Other && themeName === 'dark' ? "active-dark" : ""} ${Other && themeName === 'light' ? "active-light" : ""} ${!Other && themeName === 'default' ? 'color-default' : ''} ${!Other && themeName === 'dark' ? 'color-dark' : ''} ${!Other && themeName === 'light' ? 'color-light' : ''}`} onClick={handleOther} style={{ borderRadius: `${isMobile ? '0px 0px 0 0' : ''}` }}>

                                <div className='settings-btn-left'>
                                    <span className="settings-btn-icon">
                                        <IoIosSettings className={`settings-btn-icon ${themeName === 'light' && !Other ? 'dropdown-color' : ''} `} />
                                    </span>
                                    <span className={`settings-btn-text ${themeName === 'light' && !Other ? 'dropdown-color' : ''}`}>Other Settings</span>

                                </div>
                                <span className="settings-btn-arrow">
                                    <IoIosArrowForward className={`${themeName === 'light' && !Other ? 'dropdown-color' : ''}`} style={{ transform: `${Other ? 'rotate(90deg)' : ''}` }} />
                                </span>
                            </div>

                            {Other && <div className='setting-content setting-mobile'>

                                {/* Export Data */}
                                <SettingBox theme={allStyles} className='setting-content-box'>

                                    <div className='setting-content-box-top'>

                                        <TextColor theme={allStyles} className='setting-content-box-top-heading'>Export data</TextColor>

                                        <FocusBox theme={allStyles} className={`setting-button focus-button  solve-modal-scramble-button`} onClick={handleExportData}>
                                            Export
                                        </FocusBox>
                                    </div>

                                    <TextColor theme={allStyles} className='setting-content-box-text'>
                                        Backup for your solve and sessions data, which can be imported later if needed
                                    </TextColor>

                                </SettingBox>

                                {/* Import Data */}
                                <SettingBox theme={allStyles} className='setting-content-box'>

                                    <div className='setting-content-box-top'>

                                        <TextColor theme={allStyles} className='setting-content-box-top-heading'>Import data</TextColor>

                                        <FocusBox theme={allStyles} className={`setting-button focus-button  solve-modal-scramble-button`} style={{ position: 'relative', display: 'flex', alignItems: 'center', gap: '0.25rem' }} onClick={handleImportDropdown}>
                                            Import
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14.6918 3.81886C14.4945 3.63604 14.2268 3.53333 13.9477 3.53333C13.6686 3.53333 13.401 3.63604 13.2036 3.81886L7.99365 8.64608L2.78371 3.81886C2.5852 3.64122 2.31933 3.54292 2.04337 3.54515C1.7674 3.54737 1.50342 3.64993 1.30827 3.83074C1.11313 4.01155 1.00244 4.25614 1.00004 4.51183C0.997642 4.76752 1.10373 5.01386 1.29545 5.19779L7.24952 10.7145C7.44689 10.8973 7.71456 11 7.99365 11C8.27273 11 8.5404 10.8973 8.73777 10.7145L14.6918 5.19778C14.8892 5.01491 15 4.76691 15 4.50832C15 4.24973 14.8892 4.00173 14.6918 3.81886Z" 
                                                fill={themeName==='light'?"black" :"white"} />
                                            </svg>

                                            {importDropdown && <ButtonInactive theme={allStyles} className="import-dropdown" onClick={handleImportDropdown} ref={importDropdownRef} >

                                                <SelectDiv theme={allStyles} className='' onClick={() => { setimportModal(!importModal) }} >
                                                    Import from CsTimer
                                                </SelectDiv>
                                                {console.log(importModal)}
                                                <SelectDiv theme={allStyles} className='' onClick={() => { setimportModalUpcube(!importModalUpcube) }} >
                                                    Import from UpCube
                                                </SelectDiv>

                                            </ButtonInactive>}
                                        </FocusBox>

                                    </div>

                                    <TextColor theme={allStyles} className='setting-content-box-text'>
                                        Import data from csTimer or UpCube.
                                    </TextColor>

                                </SettingBox>

                                {/* Reset Setting */}
                                <SettingBox theme={allStyles} className='setting-content-box'>

                                    <div className='setting-content-box-top'>

                                        <TextColor theme={allStyles} className='setting-content-box-top-heading'>Reset Setting</TextColor>

                                        <FocusBox theme={allStyles} className={`setting-button focus-button  solve-modal-scramble-button`} onClick={handleResetSettings}>
                                            Reset
                                        </FocusBox>

                                    </div>

                                    <TextColor theme={allStyles} className='setting-content-box-text'>
                                        Reset everything in the settings to default values.
                                    </TextColor>

                                </SettingBox>

                            </div>}

                        </div>

                    </div>

                </div>

            </div>

        </>
    )
}

export default SettingsComponent