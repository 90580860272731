import React, { useContext, useState } from 'react'
import { ThemeContext } from "../../context";
import { ReserveContext } from '../../App';
import { PrepareDataForStats } from '../../utils/functions/operations.ts';
import SessionComponent from '../../components/Session/SessionComponent'
import MainSolveModal from '../../components/Modals/SolveModal/MainSolveModal.jsx';
import MainDeleteSolveModal from '../../components/Modals/DeleteSolveModal/MainDeleteSolveModal.jsx';

const Sessions = () => {

  var solves = useContext(ReserveContext).reserve.solves;
  let currentSession = localStorage.getItem('current_session');
  currentSession = JSON.parse(currentSession)
  let currentSessionId = currentSession.sessionId;
  solves = solves ? solves.filter((item) => item?.sessionId === currentSessionId) : null
  var stats = PrepareDataForStats(solves);

  const [cubeType, setCubeType] = useState(localStorage.getItem("cubeType") || "222");
  const [disableCubeType, setDisableCubeType] = useState(false);
  const [load, setLoad] = useState(false);
  const handleCubeType = (type) => {
    setCubeType(type);
  };

  const { allStyles } =
    useContext(ThemeContext);

  return (
    <>
    <MainDeleteSolveModal />
    <MainSolveModal />
    <SessionComponent allStyles={allStyles}
      cubeType={cubeType}
      handleCubeType={handleCubeType}
      setLoad={setLoad}
      disableCubeType={disableCubeType}
    />
    </>
  )
}

export default Sessions