import React, { useEffect } from "react";

const Svg = ({ cubeType, scramble }) => {
  let svg;

  svg = window.methodCaller.getSvg(cubeType, scramble);
  return (
    <div className="svg-main">
      <div
        dangerouslySetInnerHTML={{
          __html: svg,
        }}
      ></div>
    </div>
  );
};

export default Svg;
