import React, { useContext } from "react";
import './Help.scss'
import { ThemeContext } from "../../context";
import HelpComponent from '../../components/HelpComponent/HelpComponent'
const Help = () => {

  const { allStyles } =
    useContext(ThemeContext);
  return (
    <>

    <HelpComponent allStyles={allStyles} />

    </>
  )
}

export default Help