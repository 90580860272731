const Data =
  [
    {
      SpeedCubing: [
        {
          "id": 1,
          "heading": "2-gen",
          "paragraph": "A solving method that only uses two types of moves (e.g., U and R)."
        },
        {
          "id": 2,
          "heading": "3-style",
          "paragraph": "3-style is a method for 3x3 Blindfolded which makes the use of commutators and/or setups to commutators to solve all possible cases of moving three pieces around on the cube."
        },
        {
          "id": 3,
          "heading": "Adjustment of the U Face (AUF)",
          "paragraph": "Making small adjustments to the last layer to align pieces correctly before the final solve."
        },
        {
          "id": 4,
          "heading": "Algorithms",
          "paragraph": "A sequence of moves used to achieve a specific goal or solve a particular step in the solving process."
        },
        {
          "id": 5,
          "heading": "CFCE (Cross, F2L, Corners, Edges)",
          "paragraph": "A method that combines the CFOP method and the ZBLL method."
        },
        {
          "id": 6,
          "heading": "CFOP",
          "paragraph": "A popular speedcubing method that involves solving the Cross, F2L, OLL, and PLL in separate steps."
        },
        {
          "id": 7,
          "heading": "CLL (Corners of the Last Layer)",
          "paragraph": "Solving only the permutation of corner pieces in the last layer."
        },
        {
          "id": 8,
          "heading": "Commutators",
          "paragraph": "Algorithms used to swap or reposition multiple pieces simultaneously."
        },
        {
          "id": 9,
          "heading": "CR (Continental Record)",
          "paragraph": "The fastest recorded solve time achieved in an official competition within a specific continent."
        },
        {
          "id": 10,
          "heading": "Cross",
          "paragraph": "Creating a cross shape on one face of the cube using edge pieces."
        },
        {
          "id": 11,
          "heading": "Cubing notation",
          "paragraph": "A system of symbols used to represent cube moves, algorithms, and patterns."
        },
        {
          "id": 12,
          "heading": "DNF (Did Not Finish)",
          "paragraph": "A solve that was not completed successfully or was not within the given time limits."
        },
        {
          "id": 13,
          "heading": "Double Layer Turn",
          "paragraph": "Rotating two layers of the cube simultaneously."
        },
        {
          "id": 14,
          "heading": "ECE (Execution, Correction, Execution)",
          "paragraph": "A solving technique that involves executing moves, correcting mistakes, and executing again."
        },
        {
          "id": 15,
          "heading": "EPLL (Edge Permutation of the Last Layer)",
          "paragraph": "Solving the permutation of only the edge pieces in the last layer."
        },
        {
          "id": 16,
          "heading": "Feet",
          "paragraph": "Solving the cube using only the feet."
        },
        {
          "id": 17,
          "heading": "F2L (First Two Layers)",
          "paragraph": "A method where the first two layers are solved simultaneously."
        },
        {
          "id": 18,
          "heading": "Fingertricks",
          "paragraph": "Techniques used to execute cube moves quickly and efficiently with the fingers."
        },
        {
          "id": 19,
          "heading": "FMC (Fewest Moves Challenge)",
          "paragraph": "A competition format where participants solve the cube using the fewest number of moves."
        },
        {
          "id": 20,
          "heading": "Fridrich Method",
          "paragraph": "Another name for the CFOP method, named after its creator, Jessica Fridrich."
        },
        {
          "id": 21,
          "heading": "Inspection",
          "paragraph": "The time given to a solver to examine the scrambled cube before starting the solve."
        },
        {
          "id": 22,
          "heading": "Layer",
          "paragraph": "A set of cubies that move together when a layer turn is performed."
        },
        {
          "id": 23,
          "heading": "M-technique",
          "paragraph": "A technique where the cube is held in a specific way to optimize fingertricks and move efficiency."
        },
        {
          "id": 24,
          "heading": "Notation",
          "paragraph": "A standardized system of symbols used to represent different cube moves."
        },
        {
          "id": 25,
          "heading": "NR (National Record)",
          "paragraph": "The fastest recorded solve time achieved in an official competition within a specific country."
        },
        {
          "id": 26,
          "heading": "OH (One-Handed)",
          "paragraph": "Solving the cube using only one hand."
        },
        {
          "id": 27,
          "heading": "OHLL (One-Handed Last Layer)",
          "paragraph": "Algorithms specifically designed for solving the last layer using one hand."
        },
        {
          "id": 28,
          "heading": "OLL (Orientation of the Last Layer)",
          "paragraph": "The step in the solving process where the orientation of the last layer is solved."
        },
        {
          "id": 29,
          "heading": "Ortega Method",
          "paragraph": "A 2x2x2 method that focuses on solving the corners first, followed by the edges."
        },
        {
          "id": 30,
          "heading": "PB (Personal Best)",
          "paragraph": "The fastest recorded solve time achieved by an individual cuber."
        },
        {
          "id": 31,
          "heading": "Petrus Method",
          "paragraph": "A method that involves building a 2x2x2 block, followed by layer-by-layer solving."
        },
        {
          "id": 32,
          "heading": "PLL (Permutation of the Last Layer)",
          "paragraph": "The step in the solving process where the permutation of the last layer is solved."
        },
        {
          "id": 33,
          "heading": "Reverse",
          "paragraph": "Performing a move in the opposite direction than usual (e.g., R' instead of R)."
        },
        {
          "id": 34,
          "heading": "Roux Blocks",
          "paragraph": "Specific patterns used in the Roux method for block building and solving."
        },
        {
          "id": 35,
          "heading": "Roux Method",
          "paragraph": "A method that focuses on solving the first two layers and the last layer simultaneously."
        },
        {
          "id": 36,
          "heading": "Scramble",
          "paragraph": "Randomizing the positions of the cube's pieces before solving."
        },
        {
          "id": 37,
          "heading": "Solve",
          "paragraph": "Successfully completing the process of returning the cube to its original state."
        },
        {
          "id": 38,
          "heading": "Speedcubing",
          "paragraph": "The practice of solving a Rubik's Cube or similar puzzles as quickly as possible."
        },
        {
          "id": 39,
          "heading": "Speedsolve",
          "paragraph": "A solve performed with the goal of achieving a fast time, typically in a competition setting."
        },
        {
          "id": 40,
          "heading": "Sub-x",
          "paragraph": "Achieving a solve time below a specific time threshold (e.g., sub-10 refers to solving under 10 seconds)."
        },
        {
          "id": 41,
          "heading": "Twist",
          "paragraph": "Rotating a single face of the cube by 90 degrees in either direction."
        },
        {
          "id": 42,
          "heading": "Turn",
          "paragraph": "Rotating one or more layers of the cube, either 90 or 180 degrees."
        },
        {
          "id": 43,
          "heading": "WR (World Record)",
          "paragraph": "The fastest recorded solve time achieved in an official speedcubing competition."
        },
        {
          "id": 44,
          "heading": "X-Cross",
          "paragraph": "Creating a cross shape on the cube using edge pieces in both the U and D faces."
        },
        {
          "id": 45,
          "heading": "Yau Method",
          "paragraph": "A method that solves the last two layers of the cube simultaneously, named after its creator, Guus Razoux Schultz."
        },
        {
          "id": 46,
          "heading": "ZB Method",
          "paragraph": "A method that solves the cube using ZBLL and ZBLS (Zborowski-Bruchem Last Slot) techniques."
        },
        {
          "id": 47,
          "heading": "ZBLL (Zborowski-Bruchem Last Layer)",
          "paragraph": "A method that solves the last layer by orienting and permuting all corner and edge pieces in one step."
        },
        {
          "id": 48,
          "heading": "ZBLS (Zborowski-Bruchem Last Slot)",
          "paragraph": "A technique used in the ZB Method to solve the last slot of the last layer."
        },
        {
          "id": 49,
          "heading": "ZZ Method",
          "paragraph": "A method that involves solving the last layer first, followed by the first two layers."
        },
        {
          "id": 50,
          "heading": "ZZ-CT",
          "paragraph": "The method of solving the corners first in the ZZ method, followed by solving the cross and the remaining edges."
        }
      ]
      ,
    },

    {
      Cubing: [
        {
          "id": 1,
          "heading": "AUF (Adjustment of the U Face)",
          "paragraph": "Making small adjustments to the last layer to align pieces correctly before the final solve."
        },
        {
          "id": 2,
          "heading": "Algorithms",
          "paragraph": "A sequence of moves used to achieve a specific goal or solve a particular step in the solving process."
        },
        {
          "id": 3,
          "heading": "Beginner's Method",
          "paragraph": "A simplified method for solving the cube that focuses on layer-by-layer solving."
        },
        {
          "id": 4,
          "heading": "BLD (Blindfolded)",
          "paragraph": "Solving the cube without looking at it, relying on memorized algorithms."
        },
        {
          "id": 5,
          "heading": "CLL (Corners of the Last Layer)",
          "paragraph": "Solving only the permutation of corner pieces in the last layer."
        },
        {
          "id": 6,
          "heading": "Commutators",
          "paragraph": "Algorithms used to swap or reposition multiple pieces simultaneously."
        },
        {
          "id": 7,
          "heading": "Corner",
          "paragraph": "A cubie with three faces, located at the corner of the cube."
        },
        {
          "id": 8,
          "heading": "Cross",
          "paragraph": "Creating a cross shape on one face of the cube using edge pieces."
        },
        {
          "id": 9,
          "heading": "Cube",
          "paragraph": "Refers to the Rubik's Cube or any similar puzzle with rotating parts."
        },
        {
          "id": 10,
          "heading": "DNF (Did Not Finish)",
          "paragraph": "A solve that was not completed successfully or was not within the given time limits."
        },
        {
          "id": 11,
          "heading": "Edge",
          "paragraph": "A cubie with two faces, located along the edges of the cube."
        },
        {
          "id": 12,
          "heading": "EPLL (Edge Permutation of the Last Layer)",
          "paragraph": "Solving the permutation of only the edge pieces in the last layer."
        },
        {
          "id": 13,
          "heading": "Feet",
          "paragraph": "Solving the cube using only the feet."
        },
        {
          "id": 14,
          "heading": "Finger Tricks",
          "paragraph": "Techniques used to execute cube moves quickly and efficiently with the fingers."
        },
        {
          "id": 15,
          "heading": "F2L (First Two Layers)",
          "paragraph": "A method where the first two layers are solved simultaneously."
        },
        {
          "id": 16,
          "heading": "Fridrich Method",
          "paragraph": "Another name for the CFOP method, named after its creator, Jessica Fridrich."
        },
        {
          "id": 17,
          "heading": "Inspection",
          "paragraph": "The time given to a solver to examine the scrambled cube before starting the solve."
        },
        {
          "id": 18,
          "heading": "Layer",
          "paragraph": "A set of cubies that move together when a layer turn is performed."
        },
        {
          "id": 19,
          "heading": "Layer-By-Layer",
          "paragraph": "A solving approach where each layer is solved one at a time, starting from the bottom layer."
        },
        {
          "id": 20,
          "heading": "NR (National Record)",
          "paragraph": "The fastest recorded solve time achieved in an official competition within a specific country."
        },
        {
          "id": 21,
          "heading": "OH (One-Handed)",
          "paragraph": "Solving the cube using only one hand."
        },
        {
          "id": 22,
          "heading": "OLL (Orientation of the Last Layer)",
          "paragraph": "The step in the solving process where the orientation of the last layer is solved."
        },
        {
          "id": 23,
          "heading": "PB (Personal Best)",
          "paragraph": "The fastest recorded solve time achieved by an individual cuber."
        },
        {
          "id": 24,
          "heading": "Petrus Method",
          "paragraph": "A method that involves building a 2x2x2 block, followed by layer-by-layer solving."
        },
        {
          "id": 25,
          "heading": "PLL (Permutation of the Last Layer)",
          "paragraph": "The step in the solving process where the permutation of the last layer is solved."
        },
        {
          "id": 26,
          "heading": "Reverse",
          "paragraph": "Performing a move in the opposite direction than usual (e.g., R' instead of R)."
        },
        {
          "id": 27,
          "heading": "Roux Method",
          "paragraph": "A method that focuses on solving the first two layers and the last layer simultaneously."
        },
        {
          "id": 28,
          "heading": "Scramble",
          "paragraph": "Randomizing the positions of the cube's pieces before solving."
        },
        {
          "id": 29,
          "heading": "Second Layer",
          "paragraph": "Solving the middle layer of the cube after completing the first layer."
        },
        {
          "id": 30,
          "heading": "Solve",
          "paragraph": "Successfully completing the process of returning the cube to its original state."
        },
        {
          "id": 31,
          "heading": "Sub-x",
          "paragraph": "Achieving a solve time below a specific time threshold (e.g., sub-10 refers to solving under 10 seconds)."
        },
        {
          "id": 32,
          "heading": "TPS (Turns Per Second)",
          "paragraph": "The speed at which a cuber executes moves, measured in the number of turns performed per second."
        },
        {
          "id": 33,
          "heading": "Twist",
          "paragraph": "Rotating a single face of the cube by 90 degrees in either direction."
        },
        {
          "id": 34,
          "heading": "Turn",
          "paragraph": "Rotating one or more layers of the cube, either 90 or 180 degrees."
        },
        {
          "id": 35,
          "heading": "U (Up), D (Down), F (Front), B (Back), L (Left), R (Right)",
          "paragraph": "Basic move notations representing clockwise rotations of the corresponding faces."
        },
        {
          "id": 36,
          "heading": "White Corners",
          "paragraph": "Placing the four white corners in their correct positions after solving the white cross."
        },
        {
          "id": 37,
          "heading": "White Cross",
          "paragraph": "Creating a white cross on the bottom layer as the first step in layer-by-layer solving."
        },
        {
          "id": 38,
          "heading": "WR (World Record)",
          "paragraph": "The fastest recorded solve time achieved in an official speedcubing competition."
        },
        {
          "id": 39,
          "heading": "Yellow Corners",
          "paragraph": "Orienting and placing the four yellow corners in their correct positions."
        },
        {
          "id": 40,
          "heading": "Yellow Cross",
          "paragraph": "Creating a yellow cross on the top layer as the final step in layer-by-layer solving."
        },
        {
          "id": 41,
          "heading": "ZBLL (Zborowski-Bruchem Last Layer)",
          "paragraph": "A method that solves the last layer by orienting and permuting all corner and edge pieces in one step."
        },
        {
          "id": 42,
          "heading": "ZZ Method",
          "paragraph": "A method that involves solving the last layer first, followed by the first two layers."
        }
      ]

    }
  ]

export default Data