import styled, { css } from 'styled-components';

export const ButtonActive = styled.button`
background:${(props) => props.theme.textColor};
`;

export const ButtonInActive = styled.button`
background:${(props) => props.theme.btnActive};
color:${(props) => props.theme.textColor}
`;

export const NavTextDiv = styled.div`
  color: ${(props) => props.theme.textColorInactive};
`;

export const Heading = styled.h1`
color:  ${(props) => props.theme.buttonTextColorSecondary};
`

export const InputTextColor = styled.label`
color:  ${(props) => props.theme.buttonTextColorSecondary};
`

export const TextColor = styled.div`
color:  ${(props) => props.theme.buttonTextColorSecondary};
`

export const ParaTextColor = styled.p`
color:  ${(props) => props.theme.buttonTextColorSecondary};
`

export const ProfileBox = styled.div`
background:${(props) => props.theme.btnActive};
`;

export const ProfileModal = styled.div`
background-color: ${(props) => props.theme.backgroundColor};
  
`;

export const FocusBox = styled.button`
background-color: ${(props)=> props.theme.FocusBox};
color: ${(props) => props.theme.buttonTextColorSecondary}; 

&:hover {
  background-color: ${(props) => props.theme.hoverBackground};
}
`

export const MainDiv = styled.div`

background:${(props) => props.theme.theme == "default"? 'radial-gradient(52.3% 83.03% at 47.7% 50%, #692BCB 0%, #260062 100%)': props.theme.secondaryBackgroundColor}
  
`;