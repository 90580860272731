import axios from "axios";
import { urls } from "./urls";

export const getAllSolves = async () => {
  return (await axios.get(urls.solves)).data;
};

export const submitSolve = async (body) => {
  return (await axios.post(urls.solve, body)).data;
};

export const updateSolve = async (body) => {
  return (await axios.put(urls.solve, body)).data;
};

export const deleteSolve = async (solveId) => {
  return await axios.delete(urls.solve + "/" + solveId);
};
