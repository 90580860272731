import React, { useContext } from 'react'
import { ThemeContext } from "../../context";
import SettingsComponent from '../../components/Setting/SettingsComponent';

const Settings = () => {

  const { allStyles } =
    useContext(ThemeContext);
  return (
    <>
    <SettingsComponent allStyles={allStyles} />
    </>
  )
}

export default Settings