import styled, { css } from 'styled-components';

export const NavTextDiv = styled.div`
  color: ${(props) => props.theme.textColorInactive};
  
  ${(props) =>
    props.active &&
    css`
      color: ${props.theme.buttonTextColorSecondary};;
    `}
`;
 

export const MainNavDiv = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
`;
