import React, { useState, useEffect, useContext } from 'react'
import './Session.scss'
import { ReserveContext } from '../../App'
import NoSolves from "../../assets/images/timer/nosolves.png";
import { deleteSolve, updateSolve } from '../../apis/solves'
import { extractFormatHours, formatter } from '../Timer/Timer Section/TimerLogic';
import { extractCentiseconds, extractMinutes, extractSeconds } from '../Timer/Timer Section/TimerLogic'
import { CalculateAverage } from '../../utils/functions/calculations';
import { Background, BackgroundBox, Heading, TextColor, BoxBg, SelectDiv, ButtonInactive } from './StyledComponent'
import CancelIcon from "@mui/icons-material/Cancel";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { deleteSession } from '../../apis/sessions';
import { toast } from 'react-toastify';
import DeleteModal from './DeleteModal';
// import SolveModal from './SolveModal';
import SessionDeleteModal from './SessionDeleteModal';
import { ThemeContext } from '../../context';
import { PrepareDataForStats } from '../../utils/functions/operations.ts';
import { useRef } from 'react';
import SolveModal from '../Modals/SolveModal/SolveModal.jsx';
const SessionComponent = ({ allStyles,
    handleCubeType,
    setLoad,
    cubeType,
    disableCubeType, }) => {


    const dropdownRef = useRef(null)

    const [disable, setDisable] = useState(false);

    ChartJS.register(...registerables);

    const {
        setDeleteSolveModal,
        DeleteSolve,
        matrixDropdown, setmatrixDropdown,
        setSolveModal,
        solveModalVlue, setsolveModalVlue,
        setCurrentSolve,
        setSolveId
    } = useContext(ReserveContext)

    const [SessionModal, setSessionModal] = useState(false)

    const { themeName } = useContext(ThemeContext)

    let sessions = useContext(ReserveContext).reserve.sessions
    var solvesData = useContext(ReserveContext).reserve.solves;
    // console.log(solvesData)
    let currentSession = localStorage.getItem('current_session');

    currentSession = JSON.parse(currentSession)

    let currentSessionIdRead = currentSession?.sessionId;

    const [currentSessionId, setCurrentSessionId] = useState(currentSessionIdRead ? currentSessionIdRead : sessions[0]);

    const sortedSessions = [...sessions].sort((a, b) => {
        if (a.sessionId === currentSessionId) return -1; // Place the current session first
        if (b.sessionId === currentSessionId) return 1; // Place the current session first
        return 0; // Keep the order unchanged for other sessions
    });

    let currentSolvesData = solvesData ? solvesData?.filter((item) => item?.sessionId === currentSessionId && item.cubeType === cubeType) : null
    var stats = PrepareDataForStats(currentSolvesData);

    const [solves, setSolves] = useState(currentSolvesData?.reverse());

    const [sessionid, setsessionid] = useState('')

    const updateReserve = useContext(ReserveContext).updateReserve;
    const [solveDeleted, setSolveDeleted] = useState(false)
    useEffect(() => {
        setSolves(currentSolvesData)
    }, [solvesData, currentSessionId, cubeType])


    const handleDeleteSolve = async (solveId) => {
        try {
            await deleteSolve(solveId);
            setSolves((prevSolves) => prevSolves.filter((solve) => solve.solveId !== solveId));
            updateReserve((prevReserve) => ({
                ...prevReserve,
                solves: prevReserve.solves.filter((solve) => solve.solveId !== solveId),
            }));
            if(!solveDeleted)
                {
                    setSolveDeleted(true)
                    toast.success("Solve deleted successfully.", { autoClose: 1000, className: 'copy-toast' })
                    setTimeout(() => {
                        setSolveDeleted(false);
                    }, 2000);

                }
        } catch (error) {
            toast.error("Error deleting solve.", { autoClose: 1000, className: 'copy-toast' })
            console.error("Error deleting solve:", error);
        }
    };

    const handleUpdateSolveForPlus2 = async (solveToUpdate) => {
        try {
            const updatedSolve = { ...solveToUpdate, plus2Manual: !solveToUpdate.plus2Manual };
            setsolveModalVlue({ ...solveModalVlue, plus2Manual: !solveModalVlue.plus2Manual })
            await updateSolve(updatedSolve);
            setSolves((prevSolves) =>
                prevSolves.map((solve) =>
                    solve.solveId === updatedSolve.solveId ? updatedSolve : solve
                )
            );
            updateReserve((prevReserve) => ({
                ...prevReserve,
                solves: prevReserve.solves.map((solve) =>
                    solve.solveId === updatedSolve.solveId ? updatedSolve : solve
                ),
            }));
        } catch (error) {
            console.error("Error updating solve:", error);
        }
    };

    const handleUpdateSolveDNF = async (solveToUpdate) => {
        try {
            const updatedSolve = { ...solveToUpdate, dnfManual: !solveToUpdate.dnfManual };
            setsolveModalVlue({ ...solveModalVlue, dnfManual: !solveModalVlue.dnfManual })

            await updateSolve(updatedSolve);
            setSolves((prevSolves) =>
                prevSolves.map((solve) =>
                    solve.solveId === updatedSolve.solveId ? updatedSolve : solve
                )
            );
            updateReserve((prevReserve) => ({
                ...prevReserve,
                solves: prevReserve.solves.map((solve) =>
                    solve.solveId === updatedSolve.solveId ? updatedSolve : solve
                ),
            }));

        }
        catch (error) {
            console.error("Error updating solve:", error);
        }
    }

    const returnTextCubeType = (input) => {
        switch (input) {
            case "222":
                return "two";
            case "333":
                return "three";
            case "333OH":
                return "threeOH";
            case "333BF":
                return "threeBF";
            case "444":
                return "four";
            case "444BF":
                return "fourBF";
            case "555":
                return "five";
            case "555BF":
                return "fiveBF";
            case "666":
                return "six";
            case "777":
                return "seven";
            case "mminx":
                return "megaminx";
            default:
                return input;
        }
    };

    useEffect(() => {
        setDisable(disableCubeType);
    }, [disableCubeType]);

    const handleDeleteSession = async (sessionId) => {

        if (sessionId === currentSessionId) {
            localStorage.removeItem('current_session')
            if (sessionId === sessions[0]?.sessionId) {
                setCurrentSessionId(sessions[1]?.sessionId)
                localStorage.setItem('current_session', JSON.stringify(sessions[1]))
            } else {
                setCurrentSessionId(sessions[0]?.sessionId)
                localStorage.setItem('current_session', JSON.stringify(sessions[0]))
            }
        }

        try {
            await deleteSession(sessionId);
            updateReserve((prevReserve) => ({
                ...prevReserve,
                sessions: prevReserve?.sessions?.filter((session) => session?.sessionId !== sessionId),
                solves: prevReserve?.solves?.filter((solve) => solve?.sessionId !== sessionId),
            }));
            toast.success("Session Deleted Successfully.", { autoClose: 1000, className: 'copy-toast' })
            // window.location.reload();
        } catch (error) {
            console.error("Error deleting session:", error);
        }

    }

    function calculateDateDifference(createTimeString) {

        const currentDateObject = new Date();

        // Split the string into parts
        const parts = createTimeString.split(" ");
        const datePart = parts[0];

        // Extract year, month, and date
        const [year, month, date] = datePart.split("-").map(Number);

        const currentYear = currentDateObject.getFullYear();
        const currentMonth = currentDateObject.getMonth() + 1; // Months are zero-based, so add 1
        const currentDate = currentDateObject.getDate();

        const daysDifference = currentDate - date;
        const monthDifference = currentMonth - month;
        const yearDifference = currentYear - year;

        if (monthDifference === 0 && yearDifference === 0) {

            return daysDifference;

        } else {
            // Calculate the days for different months and years
            const date1 = new Date(year, month - 1, date); // Month is zero-based
            const date2 = new Date(currentYear, currentMonth - 1, currentDate);

            const differenceInMilliseconds = date2 - date1;
            const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

            return differenceInDays;
        }

    }

    const solvesDataGraph = stats[returnTextCubeType(cubeType)]?.solves.filter(item => item.dnfManual === false).map((solve, index) => (solve.time / 1000)); // Convert time to seconds
    function getStep(dataSize, intervals) {
        let step = 1; 
        for (const interval of intervals) {
            if (dataSize <= interval.size) {
                step = interval.step;
                break; 
            }
        }
        return step;
    }
    const generateLabelsAndData = (dataSize, intervals) => {
        const labels = [];
        const data = [];
        const step = getStep(dataSize, intervals);
        let currentIndex = 0;
          for ( currentIndex; currentIndex < dataSize; currentIndex += step) {
            labels.push((currentIndex + 1).toString());
            data.push(solvesDataGraph[currentIndex]);
          }
          for(currentIndex; currentIndex < dataSize; currentIndex++)
            {
                labels.push((currentIndex + 1).toString());
            data.push(solvesDataGraph[currentIndex]);
            }
        
       
      
        return { labels, data };
      };
      
      const intervals = [
        { size: 20, step: 1 }, // No omission for the first 20 data points
        { size: 50, step: 3 }, // No omission for the first 50 data points
        { size: 100, step: 5 }, // Omit labels and data with a step of 5 for the next 100 data points
        { size: Infinity, step: 10 }, // Omit labels and data with a step of 10 for the remaining data points
      ];
      const { labels, data } = generateLabelsAndData(solvesDataGraph?.length, intervals);

    const lineData = {
        labels: labels,
        datasets: [
            {
                label: 'Time Spent (seconds)',
                data: data,
                fill: true,
                borderColor: 'rgba(75, 192, 192, 0.6)', // You can adjust the color as needed
                tension: 0.1,
            },
        ],
    };

    const lineOptions = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };

    let dnfManualSolves = solves?.filter(solve => solve.dnfManual);

    const returnMatrixCube = (input) => {
        switch (input) {
            case "222":
                return "2x2";
            case "333":
                return "3x3";
            case "333OH":
                return "3x3 OH";
            case "333BF":
                return "3x3 BLD";
            case "444":
                return "4x4";
            case "444BF":
                return "4x4 BLD";
            case "555":
                return "5x5";
            case "555BF":
                return "5x5 BLD";
            case "666":
                return "6x6";
            case "777":
                return "7x7";
            case "skewb":
                return "Skewb";
            case "pyra":
                return "Pyraminx";
            case "minx":
                return "Megaminx";
            case "clock":
                return "Clock";
            case "sq1":
                return "Square-1";
            default:
                return;
        }
    };

    const cubeTypeOptions = [
        { value: '222', label: '2x2' },
        { value: '333', label: '3x3' },
        { value: '444', label: '4x4' },
        { value: '555', label: '5x5' },
        { value: '666', label: '6x6' },
        { value: '777', label: '7x7' },
        { value: '333OH', label: '3x3 OH' },
        { value: '333BF', label: '3x3 BLD' },
        { value: 'skewb', label: 'Skewb' },
        { value: 'pyra', label: 'Pyraminx' },
        { value: 'minx', label: 'Megaminx' },
        { value: 'clock', label: 'Clock' },
        { value: 'sq1', label: 'Square-1' },
        { value: '444BF', label: '4x4 BLD' },
        { value: '555BF', label: '5x5 BLD' },
      ]

    const handleCloseSidebar = () => {
        setmatrixDropdown(false)
    }

    useEffect(() => {
        // Handle clicks outside the sidebar
        const handleOutsideClick = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target) && e.target.id !== 'select-cubetype') {
                handleCloseSidebar()
            }
        };

        // Attach the event listener
        document.addEventListener('mousedown', handleOutsideClick);

        // Clean up the event listener on unmount
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [matrixDropdown]);

    return (
        <div className='session-container'>

            {SessionModal && <SessionDeleteModal
                allStyles={allStyles}
                handleDeleteSession={handleDeleteSession}
                setSessionModal={setSessionModal}
                sessionid={sessionid}
            />}

            {/* Session Heading */}

            <Heading theme={allStyles} className='session-heading'>
                Sessions
            </Heading>

            {/* Matrix Dropdown */}



            {/* Main Session Content */}

            <div className='session-content'>

                {/* Lists of all Sessions - Desktop */}

                <Background theme={allStyles} className='session-content-left'  id={`${themeName ==='light' ?'style-1' :""}`}>

                    {sessions?.length === 0 ? (
                        <div className='session-no-solves-div'>
                            <Heading theme={allStyles}>No Sessions...</Heading>
                        </div>
                    ) :
                        (

                            <>

                                {sessions?.map((session) => {
                                    const createTimeString = session.createTime;
                                    return (
                                        <div
                                            onClick={() => {
                                                setCurrentSessionId(session.sessionId)
                                                localStorage.setItem('current_session', JSON.stringify(session))
                                            }}
                                            key={session.sessionId} style={{ cursor: `${session.sessionId !== currentSessionId ? 'pointer' : ''}`, paddingLeft: '0.7rem', }}>
                                            <div className='session-blocks' style={{
                                                position: 'relative',
                                                borderBottom: `${themeName === 'default' ? '2.36px solid rgb(105, 43, 203)' : themeName === 'dark' || themeName === 'light' ? '2.36px solid rgb(17, 17, 17)' : ''}`,
                                                // padding: `${session.sessionId !== currentSessionId ? '' : '0rem 0rem 0.5rem 0.75rem '}`,
                                            }}>
                                                <div className="session-active-bar">

                                                    <div className='session-active' style={{
                                                        
                                                        width: '8px',
                                                        height: '3.5rem',
                                                        backgroundColor: `${session.sessionId !== currentSessionId ? '' : themeName === 'default' ? 'rgb(105, 43, 203)' : themeName === 'dark' ? 'rgb(191 191 191)' : themeName === 'light' ? 'rgb(17, 17, 17)' : ''}`,
                                                    }}>
                                                    </div>



                                                </div>
                                                <div className="session-other">
                                                    <div className='session-block-name'>
                                                        <TextColor theme={allStyles} className='session-block-name-heading'>{session.name}</TextColor>
                                                        {sessions?.length !== 1 ? <TextColor theme={allStyles} className='session-block-name-btn'>
                                                            <CancelIcon
                                                                className="solve-col"
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    setsessionid(session.sessionId);
                                                                    setSessionModal(true)
                                                                }}
                                                            />
                                                        </TextColor> :
                                                            ""
                                                        }
                                                    </div>
                                                    <TextColor theme={allStyles} className='session-block-timeline'>{`Created ${calculateDateDifference(createTimeString)}`} days ago</TextColor>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}

                            </>
                        )
                    }

                </Background>

                {/* Lists of all Sessions - Mobile */}

                <div className='session-content-left-mob' >
                    {sessions?.length === 0 ? (
                        <div className='session-no-solves-div'>
                            <Heading theme={allStyles}>No Sessions...</Heading>
                        </div>
                    ) : (

                        <>

                            {sortedSessions?.map((session) => {
                                const createTimeString = session.createTime;
                                return (
                                    <div key={session.sessionId} style={{ cursor: `${session.sessionId !== currentSessionId ? 'pointer' : ''}` }} 
                                    onClick={() => {
                                        setCurrentSessionId(session.sessionId)
                                        localStorage.setItem('current_session', JSON.stringify(session))
                                    }}
                                    >
                                        <Background theme={allStyles} className='session-blocks' style={{
                                            border: `${session.sessionId !== currentSessionId ? '' : themeName === 'default' ? '3px solid rgb(105, 43, 203)' : themeName === 'dark' ? '3px solid rgb(251 246 246)' : themeName === 'light' ? '3px solid rgb(17, 17, 17)' : ''}`,
                                            padding: `${session.sessionId !== currentSessionId ? '' : '0.75rem'}`,
                                            borderRadius: `${session.sessionId !== currentSessionId ? '' : '11.79px'}`,
                                        }}>
                                            <div className='session-block-name'>
                                                <TextColor theme={allStyles} className='session-block-name-heading'>{session.name}</TextColor>
                                                {sessions?.length !== 1 ? <TextColor theme={allStyles} className='session-block-name-btn'>
                                                    <CancelIcon
                                                        className="solve-col"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setsessionid(session.sessionId);
                                                            setSessionModal(true)
                                                        }}
                                                    />
                                                </TextColor> :
                                                    <BoxBg theme={allStyles} className='session-block-name-btn-current' >
                                                        Current
                                                    </BoxBg>
                                                }
                                            </div>
                                            <TextColor theme={allStyles} className='session-block-timeline'>{`Created ${calculateDateDifference(createTimeString)} days ago`}</TextColor>
                                        </Background>
                                    </div>
                                )
                            })}

                        </>
                    )}
                </div>

                {/* Session Right Content */}

                <div className='session-content-right' >

                    {/* filter */}
                    <div className='session-matrix-select' style={{ height: '2rem' }}>

                        <ButtonInactive theme={allStyles} className="cube-type-select" id="select-cubetype" onClick={() => { setmatrixDropdown(!matrixDropdown) }}>

                            {returnMatrixCube(cubeType)}

                            {/* <img src={dropdown} alt="" /> */}
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.6918 3.81886C14.4945 3.63604 14.2268 3.53333 13.9477 3.53333C13.6686 3.53333 13.401 3.63604 13.2036 3.81886L7.99365 8.64608L2.78371 3.81886C2.5852 3.64122 2.31933 3.54292 2.04337 3.54515C1.7674 3.54737 1.50342 3.64993 1.30827 3.83074C1.11313 4.01155 1.00244 4.25614 1.00004 4.51183C0.997642 4.76752 1.10373 5.01386 1.29545 5.19779L7.24952 10.7145C7.44689 10.8973 7.71456 11 7.99365 11C8.27273 11 8.5404 10.8973 8.73777 10.7145L14.6918 5.19778C14.8892 5.01491 15 4.76691 15 4.50832C15 4.24973 14.8892 4.00173 14.6918 3.81886Z" 
                                 fill={themeName==="light"?"black":"white"}  />
                            </svg>


                            {matrixDropdown && <ButtonInactive theme={allStyles} className="matrix-cubetype" ref={dropdownRef} 
                             id={`${themeName ==='light' ?'style-1' :""}`}>

                                {cubeTypeOptions.map((cube) => {
                                    return (
                                        <SelectDiv theme={allStyles} className={`dropdown-matrix-cubetype ${cubeType === cube.value ? 'selected' : ''}`} key={cube.value}
                                            onClick={() => {
                                                handleCubeType(cube.value);
                                                setmatrixDropdown(!matrixDropdown)
                                                setLoad(false);
                                            }}
                                             
                                        >
                                            {cube.label}

                                            {cubeType === cube.value && <svg xmlns="http://www.w3.org/2000/svg" viewBox="-49 141 512 512" width="16" height="16" aria-hidden="true" className="dropdown-tick" fill={themeName==='light'?"#00000":"#FFFFFF"}><path d="M127 552.25a24.92 24.92 0 0 1-17.678-7.322l-101-101c-9.763-9.763-9.763-25.593 0-35.355 9.764-9.764 25.592-9.764 35.355 0l83.339 83.34 243.323-242.857c9.771-9.754 25.602-9.74 35.355.033 9.754 9.772 9.739 25.602-.033 35.355l-261 260.5A24.92 24.92 0 0 1 127 552.25"></path></svg>}
                                        </SelectDiv>
                                    )
                                })}

                            </ButtonInactive>}

                        </ButtonInactive>

                    </div>

                    {/* Session Solves and Stats */}

                    <div className='session-right-content-top'>

                        {/* Session Solves */}

                        <div className='session-solves'>

                            <Heading theme={allStyles} className='session-solve-heading'>Session Solves</Heading>

                            <Background theme={allStyles} className='session-solve-box'  id={`${themeName ==='light' ?'style-1' :""}`} >

                                {solves?.length === 0 || solves === null ? (
                                    <div className='session-no-solves-div'>
                                        <img src={NoSolves} alt="NoSolves" />
                                    </div>
                                ) : (
                                    <>
                                        {solves?.map((solve, index) => {
                                            return (
                                                <div className='session-solve-box-content' key={index + 1}>

                                                    <div className='session-solve-box-left'>

                                                        <TextColor theme={allStyles} className='session-solve-sno'>{solves.length - index}.</TextColor>

                                                        <div className='session-solve-value' onClick={() => {
                                                            setSolveModal(true)
                                                            setCurrentSolve(solve)
                                                            setsolveModalVlue({
                                                                scramble: solve.scramble,
                                                                date: solve.dateTime,
                                                                cubetype: solve.cubeType,
                                                                plus2Auto: solve.plus2Auto,
                                                                plus2Manual: solve.plus2Manual,
                                                                dnfAuto: solve.dnfAuto,
                                                                dnfManual: solve.dnfManual,
                                                                solveTime: solve.time
                                                            })
                                                        }}>
                                                            {solve.dnfAuto || solve.dnfManual ? null : extractFormatHours(solve.time)}
                                                            {solve.dnfAuto || solve.dnfManual ? null : extractMinutes(solve.time).split(":")[0] > 0 ? extractMinutes(solve.time).split(":")[0] % 60 + ':' : null}
                                                            {solve.dnfAuto || solve.dnfManual ? "DNF" : (solve.plus2Auto || solve.plus2Manual ? `${extractSeconds(solve.time + 2000)}` : extractSeconds(solve.time))}
                                                            {solve.dnfAuto || solve.dnfManual ? null : extractCentiseconds(solve.time)}
                                                        </div>

                                                    </div>

                                                    <div className='session-solve-box-right'>

                                                        <TextColor theme={allStyles} className={`session-solve-increment ${solve.plus2Auto || solve.plus2Manual
                                                            ? "plus2-enable"
                                                            : null
                                                            }`}
                                                            onClick={() => {

                                                                handleUpdateSolveForPlus2(solve);
                                                            }}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            +2
                                                        </TextColor>

                                                        <TextColor theme={allStyles} className={`session-solve-content ${solve.dnfManual || solve.dnfAuto ? "plus2DNF-enable" : null}`}
                                                            onClick={() => {
                                                                handleUpdateSolveDNF(solve);
                                                            }}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            DNF
                                                        </TextColor>

                                                        <CancelIcon
                                                            onClick={() => {
                                                                if (DeleteSolve) {
                                                                    setDeleteSolveModal(true)
                                                                    setSolveId(solve.solveId)
                                                                } else {
                                                                    handleDeleteSolve(solve.solveId)
                                                                }
                                                            }}
                                                            className="solve-col"
                                                            // onClick={() => {
                                                            //     handleDeleteSolve(solve.solveId);
                                                            // }}
                                                            sx={{ color: "red" }}
                                                            style={{ fontSize: '1.25rem' }}
                                                        />

                                                    </div>

                                                </div>
                                            )
                                        })}

                                    </>
                                )}

                            </Background>

                        </div>

                        {/* Session Stats */}

                        <div className='session-stats'>

                            <Heading theme={allStyles} className='session-stats-heading'>Session Stats</Heading>

                            {!solves ?
                                <Background theme={allStyles} className='session-solve-box session-no-solves-div'>
                                    <Heading theme={allStyles}>No Stats Yet</Heading>
                                </Background>
                                :
                                <Background theme={allStyles} className='session-stats-content'>

                                    <div className='session-stats-content-column'>

                                        <BackgroundBox theme={allStyles} className='session-stats-box session-box-1'>

                                            <div className='session-stats-box-content'>

                                                <TextColor theme={allStyles} className='session-stats-box-content-heading'>
                                                    PB
                                                </TextColor>

                                                <div className='session-stats-pb-content'>
                                                    {stats[returnTextCubeType(cubeType)]?.pb.time ==
                                                        Number.POSITIVE_INFINITY
                                                        ? "-"
                                                        : formatter(
                                                            stats[returnTextCubeType(cubeType)]?.pb.time
                                                        )}
                                                </div>

                                            </div>

                                        </BackgroundBox>

                                        <BackgroundBox theme={allStyles} className='session-stats-box-container session-box-2'>

                                            <div className='session-stats-box-content'>

                                                <TextColor theme={allStyles} className='session-stats-box-content-heading'>
                                                    worst
                                                </TextColor>

                                                <div className='session-stats-worst-content'>
                                                    {
                                                        stats[returnTextCubeType(cubeType)]?.worst ?
                                                            formatter(
                                                                stats[returnTextCubeType(cubeType)]?.worst
                                                            ) : "-"
                                                    }
                                                </div>

                                            </div>

                                        </BackgroundBox>

                                        <BackgroundBox theme={allStyles} className='session-stats-box-container session-box-3'>

                                            <div className='session-stats-box-content'>

                                                <TextColor theme={allStyles} className='session-stats-box-content-heading'>
                                                    avg
                                                </TextColor>

                                                <TextColor theme={allStyles} className='session-stats-dynamic-box-content'>
                                                    {dnfManualSolves?.length === 0 ?
                                                        <>
                                                            {formatter(
                                                                CalculateAverage(
                                                                    solves
                                                                )
                                                            )}
                                                        </>
                                                        : <>
                                                            {dnfManualSolves?.length > 1 ? 'DNF' : <>
                                                                {formatter(
                                                                    CalculateAverage(
                                                                        solves
                                                                    )
                                                                )}
                                                            </>}
                                                        </>}
                                                </TextColor>
                                            </div>

                                        </BackgroundBox>

                                    </div>

                                    <div className='session-stats-content-column'>

                                        <BackgroundBox theme={allStyles} className='session-stats-box-container session-box-4'>

                                            <div className='session-stats-box-content'>

                                                <TextColor theme={allStyles} className='session-stats-box-content-heading'>
                                                    ao5 PB
                                                </TextColor>

                                                <TextColor theme={allStyles} className='session-stats-dynamic-box-content'>
                                                    {stats[returnTextCubeType(cubeType)]?.ao5pb.value ==
                                                        Number.POSITIVE_INFINITY
                                                        ? "-"
                                                        : formatter(
                                                            stats[returnTextCubeType(cubeType)]?.ao5pb.value
                                                        )}
                                                </TextColor>
                                            </div>

                                        </BackgroundBox>

                                        <BackgroundBox theme={allStyles} className='session-stats-box-container session-box-5'>

                                            <div className='session-stats-box-content'>

                                                <TextColor theme={allStyles} className='session-stats-box-content-heading'>
                                                    ao5
                                                </TextColor>

                                                <TextColor theme={allStyles} className='session-stats-dynamic-box-content'>
                                                    {stats[returnTextCubeType(cubeType)]?.ao5
                                                        ? formatter(stats[returnTextCubeType(cubeType)]?.ao5)
                                                        : "-"}
                                                </TextColor>
                                            </div>

                                        </BackgroundBox>

                                        <BackgroundBox theme={allStyles} className='session-stats-box-container session-box-6'>

                                            <div className='session-stats-box-content'>

                                                <TextColor theme={allStyles} className='session-stats-box-content-heading'>
                                                    ao12 PB
                                                </TextColor>

                                                <TextColor theme={allStyles} className='session-stats-dynamic-box-content'>
                                                    {stats[returnTextCubeType(cubeType)]?.ao12pb.value ==
                                                        Number.POSITIVE_INFINITY
                                                        ? "-"
                                                        : formatter(
                                                            stats[returnTextCubeType(cubeType)]?.ao12pb.value
                                                        )}
                                                </TextColor>
                                            </div>

                                        </BackgroundBox>

                                        <BackgroundBox theme={allStyles} className='session-stats-box-container session-box-7'>

                                            <div className='session-stats-box-content'>

                                                <TextColor theme={allStyles} className='session-stats-box-content-heading'>
                                                    ao12
                                                </TextColor>

                                                <TextColor theme={allStyles} className='session-stats-dynamic-box-content'>
                                                    {stats[returnTextCubeType(cubeType)]?.ao12
                                                        ? formatter(stats[returnTextCubeType(cubeType)]?.ao12)
                                                        : "-"}
                                                </TextColor>
                                            </div>

                                        </BackgroundBox>

                                    </div>

                                </Background>
                            }

                        </div>

                    </div>


                </div>

            </div>

            {/* Session Graph */}

            <div className='session-graph'>

                <Heading theme={allStyles} className='session-graph-heading'>Session Graph</Heading>

                <Background theme={allStyles} className='session-graph-box'>
                    <div className='session-graph-content' style={{ width: '100%', height: 'inherit' }}>
                        <Line data={lineData} options={lineOptions} />

                    </div>
                </Background>

            </div>

        </div>
    )
}

export default SessionComponent