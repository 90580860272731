import {CalculateWorst } from "./calculations"
import { PuzzleIds } from "../../types/puzzleIds";
import { Solve } from "../../types/solve";
import {
  AoPbComposite,
  PuzzleComposite,
  StatsComposite,
} from "../../types/statsComposite";
import { CalculateAoXpb, CalculateAverage } from "./calculations";

export const PrepareDataForStats = (solves: Solve[]): StatsComposite => {
  if (!solves) {
    return {} as StatsComposite;
  }
  let stats = SortPuzzles(solves);
  stats = PbTimeAndTotal(stats);
  stats = AssignAoPBs(stats);
  stats = AssignAverages(stats);

  return stats;
};

const SortPuzzles = (solves: Solve[]): StatsComposite => {
  let stats = {} as StatsComposite;

  Object.keys(PuzzleIds).forEach((value) => {
    let puzzleType = value;
    stats[puzzleType] = {} as PuzzleComposite;
    stats[puzzleType].ao5pb = {} as AoPbComposite;
    stats[puzzleType].ao12pb = {} as AoPbComposite;

    stats[puzzleType].solves = solves.filter((solve: Solve) => {
      return solve.cubeType === PuzzleIds[puzzleType];
    });
  });

  return stats;
};

export const PbTimeAndTotal = (stats: StatsComposite): StatsComposite => {
  Object.keys(PuzzleIds).forEach((value) => {
    let puzzleType = value;
    stats[puzzleType].solvesCount = stats[puzzleType].solves.length;
    let pb = {
      time: Number.POSITIVE_INFINITY,
      plus2Auto: false,
      plus2Manual: false,
    };
    let totalTime = 0;
    stats[puzzleType].solves.forEach((solve: Solve) => {
      let solveExtras = 0;
      let pbExtras = 0;
      if (solve.plus2Auto) {
        solveExtras = solveExtras + 2000;
      }
      if (solve.plus2Manual) {
        solveExtras = solveExtras + 2000;
      }
      if (pb.plus2Auto) {
        pbExtras = pbExtras + 2000;
      }
      if (pb.plus2Manual) {
        pbExtras = pbExtras + 2000;
      }
      totalTime = totalTime + solve.time;
      if (solve.time + solveExtras < pb.time + pbExtras) {
        if (!solve.dnfAuto && !solve.dnfManual) {
          pb = structuredClone(solve);
        }
      }
    });
    stats[puzzleType].pb = structuredClone(pb);
    stats[puzzleType].worst = CalculateWorst(stats[puzzleType].solves)
    stats[puzzleType].timeSpent = totalTime;
  });
  return stats;
};

const AssignAoPBs = (stats: StatsComposite): StatsComposite => {
  Object.keys(PuzzleIds).forEach((puzzleType) => {
    stats[puzzleType].ao5pb = CalculateAoXpb(stats[puzzleType].solves, 5);
    stats[puzzleType].ao12pb = CalculateAoXpb(stats[puzzleType].solves, 12);
  });
  return stats;
};

const AssignAverages = (stats: StatsComposite): StatsComposite => {
  Object.keys(PuzzleIds).forEach((puzzleType) => {
    if (stats[puzzleType].solves.length >= 5) {
      stats[puzzleType].ao5 = CalculateAverage(
        stats[puzzleType].solves.slice(-5)
      );
    }
    if (stats[puzzleType].solves.length >= 12) {
      stats[puzzleType].ao12 = CalculateAverage(
        stats[puzzleType].solves.slice(-12)
      );
    }
    if (stats[puzzleType].solves.length >= 10) {
      stats[puzzleType].ao10 = CalculateAverage(
        stats[puzzleType].solves.slice(-10)
      );
    }
    if (stats[puzzleType].solves.length >= 25) {
      stats[puzzleType].ao25 = CalculateAverage(
        stats[puzzleType].solves.slice(-25)
      );
    }
    if (stats[puzzleType].solves.length >= 50) {
      stats[puzzleType].ao50 = CalculateAverage(
        stats[puzzleType].solves.slice(-50)
      );
    }
    if (stats[puzzleType].solves.length >= 100) {
      stats[puzzleType].ao100 = CalculateAverage(
        stats[puzzleType].solves.slice(-100)
      );
    }
    if (stats[puzzleType].solves.length >= 500) {
      stats[puzzleType].ao500 = CalculateAverage(
        stats[puzzleType].solves.slice(-500)
      );
    }
    if (stats[puzzleType].solves.length >= 1000) {
      stats[puzzleType].ao1000 = CalculateAverage(
        stats[puzzleType].solves.slice(-1000)
      );
    }
  });
  return stats;
};
