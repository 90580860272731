import styled, { css } from 'styled-components';

export const ButtonActive = styled.button`
background:${(props) => props.theme.textColor};
`;

export const Background = styled.div`
background-color: ${(props) => props.theme.backgroundColor};
` 

export const BackgroundBox = styled.div`
background-color: ${(props) => props.theme.statsBoxBackground};
`

export const SelectDivActive = styled.select`
background:  ${(props) => props.theme.textColor};
color: ${(props) => props.theme.btnActive};
`;

export const Heading = styled.h1`
color:  ${(props) => props.theme.buttonTextColorSecondary};
`

export const TextColor = styled.div`
color:  ${(props) => props.theme.buttonTextColorSecondary};
`

export const BoxBg = styled.div`
background-color: ${(props) => props.theme.solvesBtn};
color:  ${(props) => props.theme.textColor}
`

export const ProfileModal = styled.div`
background-color: ${(props) => props.theme.backgroundColor};
  
`;

export const FocusBox = styled.div`
background-color: ${(props)=> props.theme.FocusBox};
color: ${(props) => props.theme.buttonTextColorSecondary}; 

&:hover {
    background-color: ${(props) => props.theme.hoverBackground};
  }
`

export const ButtonInactive = styled.button`
background:${(props) => props.theme.btnActive};
color:${(props) => props.theme.textColor}
`; 

export const SelectDiv = styled.div`
background-color:  ${(props) => props.theme.btnActive};
color: ${(props) => props.theme.textColor};

&:hover {
  color: ${(props) => props.theme.hoverBackground};
}

&.selected {
  color: ${(props) => props.theme.hoverBackground};
}
`;