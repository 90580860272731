import axios from "axios";
import { urls } from "./urls";

export const getUser = async () => {
  return axios
    .get(urls.checkUser)
    .then((res) => {
      return res.data.user;
    })
    .catch((err) => {
      return undefined;
    });
};
