import React, { useState } from "react";
import "./Cube.css";

const Cube = ({ matrix }) => {
  const facesOrder = ["left", "front", "right", "back"];

  return (
    <div className="side">
      {facesOrder.map((face) => (
        <div key={face} className={`face ${face}`}>
          {face == "front" ? (
            <div className="top box">
              {matrix.up.map((row, rowIndex) => (
                <div key={rowIndex} className="row">
                  {row.map((color, colIndex) => (
                    <div key={colIndex} className={`cell ${color}`}>
                      {/* {color} */}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ) : (
            <div></div>
          )}

          <div className="box">
            {matrix[face].map((row, rowIndex) => (
              <div key={rowIndex} className="row">
                {row.map((color, colIndex) => (
                  <div key={colIndex} className={`cell ${color}`}>
                    {/* {color} */}
                  </div>
                ))}
              </div>
            ))}
          </div>

          {face == "front" ? (
            <div className="down box">
              {matrix.down.map((row, rowIndex) => (
                <div key={rowIndex} className="row">
                  {row.map((color, colIndex) => (
                    <div key={colIndex} className={`cell ${color}`}>
                      {/* {color} */}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default Cube;
