import styled, { css } from 'styled-components';

export const NavTextDiv = styled.div`
  color: ${(props) => props.theme.textColorInactive};
  
  ${(props) =>
    props.active &&
    css`
      color: ${props.theme.buttonTextColorSecondary};;
    `}
`;
 

export const MainNavDiv = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
`;

export const MainTimerDiv = styled.div`
background:${(props) => props.theme.theme == "default"? 'radial-gradient(52.3% 83.03% at 47.7% 50%, #692BCB 0%, #260062 100%)': props.theme.secondaryBackgroundColor}
`;


export const CreateSessionMain = styled.div`
background-color: ${(props) => props.theme.backgroundColor};
`;

export const Background = styled.div`
background-color: ${(props) => props.theme.backgroundColor};
`

export const SelectDiv = styled.div`
background-color:  ${(props) => props.theme.btnActive};
color: ${(props) => props.theme.textColor};

&:hover {
  color: ${(props) => props.theme.hoverBackground};
}

&.selected {
  color: ${(props) => props.theme.hoverBackground};
}
`;

export const SelectDivMob = styled.select`
background-color:  ${(props) => props.theme.btnActive};
color: ${(props) => props.theme.textColor};
`;

export const SelectInput = styled.input`
background-color:  ${(props) => props.theme.btnActive};
color: ${(props) => props.theme.textColor};
`;

export const ScrambleDiv = styled.div`
color: ${(props) => props.theme.textColor};
`;
export const TextDiv = styled.div`
color: ${(props) => props.theme.buttonTextColorSecondary}; 
`;
export const TextDivSecondary = styled.div`
color: ${(props) => props.theme.textColorInactive};
`;

export const Box = styled.div`
background-color: ${(props) => props.theme.backgroundColor};
`;

export const TimerInput = styled.input`
background-color: ${(props) => props.theme.backgroundColor};
`;
export const InnerBox = styled.div`
background-color: ${(props) => props.theme.statsBoxBackground}`;

export const OptionDiv = styled.option`
background-color: ${(props) => props.theme.backgroundColor}`;

export const ShowFooterDiv = styled.div`
background-color: ${(props)=> props.theme.backgroundColor};
`
export const RoundBorder = styled.img`
border: 10px solid ${(props)=> props.theme.backgroundColor};
`
export const SelectOptionDiv = styled.select`
background-color: ${(props)=> props.theme.secondaryOption};
`

export const FocusBox = styled.button`
background-color: ${(props)=> props.theme.FocusBox};
color: ${(props) => props.theme.buttonTextColorSecondary}; 

&:hover {
  background-color: ${(props) => props.theme.hoverBackground};
}
`

export const ButtonInactive = styled.button`
background:${(props) => props.theme.btnActive};
color:${(props) => props.theme.textColor}
`; 