import React, { useContext } from 'react'
import { ThemeContext } from "../../context";
import GlossaryComponent from '../../components/Glossary/GlossaryComponent'
const Glossary = () => {
  const { allStyles } =
    useContext(ThemeContext);
  return (
    <>
      <GlossaryComponent allStyles={allStyles} />
    </>
  )
}

export default Glossary