import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation, useNavigate } from 'react-router-dom';

import { Heading, ProfileModal, FocusBox, TextColor, ButtonInactive, SelectDiv } from './styledComponent.js'
import { IoMdClose } from 'react-icons/io'
import './ImportModal.scss'
import { ThemeContext } from '../../../context.js'
import { ReserveContext } from '../../../App.jsx'
import { formatData } from '../../../utils/functions/csTimer.js'
import { submitBulkSolve } from '../../../apis/bulkSolves.js'
import { toast } from 'react-toastify'
import { getAllSessions } from '../../../apis/sessions.js';
import { getAllSolves } from '../../../apis/solves.js';

const ImportModal = () => {
  const updateReserve = useContext(ReserveContext).updateReserve;
  const { allStyles, themeName } = useContext(ThemeContext);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const inputRef = useRef(null);
  const cubeTypeRef = useRef(null);
  const [cubeType, setcubeType] = useState('')
  const [currentSolves,setCurrentSolves] = useState(0);
  const [importData, setimportData] = useState([
    // { sessionName: 'Session 1', cubeType: '333' },
    // { sessionName: 'Session 2', cubeType: '222' },
    // { sessionName: 'Session 3', cubeType: '555' },
  ])
  const [load, setLoad] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);


  const {
    setmatrixDropdown, setimportModal
  } = useContext(ReserveContext)

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles(files);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      readFileContent(file);
    }
  };

  const readFileContent = (file) => {
    const fileReader = new FileReader();

    fileReader.onload = (event) => {
    setSelectedFiles(event.target.result);
    const data = JSON.parse(event.target.result);
    const formattedData = formatData(data);
    // console.log(formattedData);

    let totalSolvesSize = 0;
    const importData = formattedData.filter(session => session.solves.length > 0);
    importData.forEach(session => {
        totalSolvesSize += session.solves.length;
    });

    setimportData(importData);
    setCurrentSolves(totalSolvesSize);
};

    fileReader.onerror = (error) => {
      console.error('Error reading file:', error);
    };

    fileReader.readAsText(file);

    
    setSelectedFiles(file);

  };
  const handleFileUpload = () => {
    // Handle file upload logic here
    console.log("Uploading files:", selectedFiles);
  };

  const returnMatrixCube = (input) => {
    switch (input) {
      case "all":
        return "All";
      case "222":
        return "2x2";
      case "333":
        return "3x3";
      case "333OH":
        return "3x3 OH";
      case "333BF":
        return "3x3 BLD";
      case "444":
        return "4x4";
      case "444BF":
        return "4x4 BLD";
      case "555":
        return "5x5";
      case "555BF":
        return "5x5 BLD";
      case "666":
        return "6x6";
      case "777":
        return "7x7";
      case "skewb":
        return "Skewb";
      case "pyra":
        return "Pyraminx";
      case "minx":
        return "Megaminx";
      case "clock":
        return "Clock";
      case "sq1":
        return "Square-1";
      default:
        // Check if the input starts with digits
        if (/^\d{3}/.test(input)) {
          const n = parseInt(input.substring(0, 1)); 
          input=parseInt(input.substring(0, 3));// Extract the first 3 digits and convert to a number
          return `${n}x${n}`; // Return cube type as n*n
        }
        // If input doesn't match any case and doesn't start with 3 digits, return "3x3" as default
        return "3x3";
    }
  };
  

  const cubeTypeOptions = [
    { value: '222', label: '2x2' },
    { value: '333', label: '3x3' },
    { value: '333OH', label: '3x3 OH' },
    { value: '333BF', label: '3x3 BLD' },
    { value: '444', label: '4x4' },
    { value: '444BF', label: '4x4 BLD' },
    { value: '555', label: '5x5' },
    { value: '555BF', label: '5x5 BLD' },
    { value: '666', label: '6x6' },
    { value: '777', label: '7x7' },
    { value: 'skewb', label: 'Skewb' },
    { value: 'pyra', label: 'Pyraminx' },
    { value: 'minx', label: 'Megaminx' },
    { value: 'clock', label: 'Clock' },
    { value: 'sq1', label: 'Square-1' }
  ]

  const handleCloseSidebar = () => {
    setmatrixDropdown(false)
  }

  useEffect(() => {
    // Handle clicks outside the sidebar
    const handleOutsideClick = (e) => {
      if (openDropdownIndex !== null &&
        dropdownRefs.current[openDropdownIndex] &&
        !dropdownRefs.current[openDropdownIndex].current.contains(e.target)) {
        handleCloseSidebar()
        setOpenDropdownIndex(null);
      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleOutsideClick);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [openDropdownIndex]);

  const handleCubeType = (value, index) => {
    const updatedImportData = importData.map((item, i) => {
      console.log(item)
      if (i === index) {
        return { ...item, cubeType: value };
      }

      return item;
    });

    setimportData(updatedImportData)

    // update the whole data array
  };

  function convertSolvesToNewFormat(importData) {
    return importData.map((session) => {
        return {
            session: session.session,
            sessionName: session.sessionName,
            cubeType: session.cubeType,
            solves: session.solves.map((solve) => {
                return {
                    time: solve.time,
                    scramble: solve.scramble,
                    solveId: "xN-N5bQ043BfCTxYobfD_",
                    dnfAuto: false,
                    dnfManual:  solve.dnf,
                    plus2Auto: false,
                    plus2Manual: solve.plus2,
                    cubeType: session.cubeType,
                    notes: " ",
                    uid: "", 
                    sessionId: "", 
                    dateTime: solve?.dateTime, 
                };
            })
        };
    });
}

const navigate = useNavigate();

const handleImport = async (importData) => {
  try {
    setButtonClicked(true);

    const data = convertSolvesToNewFormat(importData);
    // console.log(data);
    const response = await submitBulkSolve(data);
    // console.log(response);
    let allSession = await getAllSessions();
    // console.log(allSession);
    let allSolves= await getAllSolves();
    // console.log(allSolves);
    updateReserve((prevReserve) => ({
      ...prevReserve,
      sessions:allSession?.sessions,
      solves:allSolves?.solves,
  }));
    navigate("/sessions");
  } catch (err) {
    toast.error("Something went wrong");
  }
}

  const dropdownRefs = useRef(importData.map(() => React.createRef()));

  const handleCubeTypeSelect = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };

  const handleSessionNameChange = (event, index) => {

    let updatedImportData = [...importData];
    updatedImportData[index].sessionName = event.target.value;
    setimportData(updatedImportData)

  }
  const handleRemoveImportData = (index) => {
    let updatedImportData = importData.filter((item, i) => i !== index);
    
    setimportData(updatedImportData)
    let totalSolvesSize = 0;

    // Iterate through each session
    for (let i = 0; i < updatedImportData.length; i++) {
            totalSolvesSize += updatedImportData[i]?.solves.length;
        }
    setCurrentSolves(totalSolvesSize)
  }

  return (
    <div className="profile-modal" style={{overflow: 'hidden'}}>

      <ProfileModal theme={allStyles} className='profile-modal-container' style={{
        maxHeight: '90vh',
        overflow: 'hidden',
        overflowY: 'scroll',
      }}>

        {/* close button */}
        <div className="import-modal-close-button" style={{ position: 'relative', zIndex: '1000' }} onClick={() => { setimportModal(false) }}>

          <Heading theme={allStyles} className='import-modal-heading'>
            Import data from csTimer
          </Heading>

          <div
            aria-label="Close" style={{ top: '18px' }}
          >
            <IoMdClose style={{color: themeName === 'light' ? 'black' : 'white'}} />

          </div>

        </div>

        {/* main heading */}
        <div style={{position: 'relative'}}>

          <Heading theme={allStyles} className='import-modal-sub-heading'>
            How to export data from csTimer
          </Heading>

          {/* Modal Steps */}
          <div className='import-modal-steps'>

            <div>
              <TextColor theme={allStyles} className='import-modal-step'>
                1. Go to <a href="https://cstimer.net" className={`import-modal-link ${themeName==='light'?"text-color-light":""}`}>cstimer.net</a>
              </TextColor>
            </div>

            <div>
              <TextColor theme={allStyles} className='import-modal-step'>
                2. Click the "Export" icon (next to the Settings button)
              </TextColor>
            </div>

            <div>
              <TextColor theme={allStyles} className='import-modal-step'>
                3. Click "Export to file"
              </TextColor>
            </div>

          </div>

          {/* horizontal Line */}

        <div className='import-modal-horizontal-line' />

        </div>

        

        {/* select file to import */}
        <div style={{position: 'relative', margin: '2rem 0'}}>

          <Heading theme={allStyles} className='import-modal-select-file'>
            Select the file to import
          </Heading>

          <div
            className="file-uploader"
            onDrop={handleDrop}
            onDragOver={(event) => event.preventDefault()}
            onClick={() => inputRef.current.click()}
          >
            <input
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileSelect}
              ref={inputRef}
              accept="text/plain"
            />
            <TextColor theme={allStyles}>Drag and drop files here or click to select</TextColor>

          </div>

          {/* horizontal Line */}

        <div className='import-modal-horizontal-line' style={{bottom: '-3.5rem'}} />
        </div>

        

        {/* showing data */}
        {importData.length !== 0 && <div style={{marginTop: '2rem'}}>

          <TextColor theme={allStyles} className='import-modal-sub-heading'>
            Review & import
          </TextColor>

          <TextColor theme={allStyles} className='import-modal-step' >
            Please make sure that the number below look correct. Then click Import data!
          </TextColor>

        </div>}

        {/* Sessions and solves */}

        {importData.length !== 0 && <div className='import-modal-solves-sessions-data'>

          <TextColor theme={allStyles} className='import-modal-solve-value'>
            Solves : <span style={{ color: '#41b058' }}>{currentSolves}</span>
          </TextColor>

          <TextColor theme={allStyles} className='import-modal-session-value'>
            Sessions : <span style={{ color: '#41b058' }}>{importData.length}</span>
          </TextColor>

          <div className='import-modal-horizontal-line' style={{bottom: '-3.5rem'}} />

        </div>
}
        {/* horizontal Line */}

        <hr className='import-modal-horizontal-line' />

        {/* imported Data */}

        {importData.length !== 0 && <div>

          <div className='import-modal-imported-data-headings'>

            <TextColor theme={allStyles} className='import-modal-session-value'>
              Session Name
            </TextColor>

            <TextColor theme={allStyles} className='import-modal-cubetype-value'>
              Cube Type
            </TextColor>

            <TextColor theme={allStyles} className='import-modal-session-value'>
              Remove
            </TextColor>

          </div>


          <div>

            {importData.length === 0 && (
              <Heading theme={allStyles} className='import-modal-no-data'>
                No data to import
              </Heading>
            )}

            {importData.map((data, index) => (
              <div className='import-modal-imported-data' key={index}>
{/* 
                {console.log(data)} */}
                <TextColor theme={allStyles} className='import-modal-session-value'>

                  <input type="text" value={data.sessionName} onChange={(e) => { handleSessionNameChange(e, index) }} name='sessionname' id={`sessionname-${index}`} required style={{ background: `${themeName === 'light' ? 'rgba(222, 222, 222, 1)' : ''}` }}>

                  </input>

                </TextColor>

                <ButtonInactive
                  theme={allStyles}
                  className="cube-type-select"
                  id={`select-cubetype-${index}`}
                  onClick={() => handleCubeTypeSelect(index)}
                  ref={cubeTypeRef}
                >
                  {returnMatrixCube(data?.cubeType)}
                  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 256 256">
                    <rect width="256" height="256" fill="none"></rect>
                    <path d="M224,177.3V78.7a8.1,8.1,0,0,0-4.1-7l-88-49.5a7.8,7.8,0,0,0-7.8,0l-88,49.5a8.1,8.1,0,0,0-4.1,7v98.6a8.1,8.1,0,0,0,4.1,7l88,49.5a7.8,7.8,0,0,0,7.8,0l88-49.5A8.1,8.1,0,0,0,224,177.3Z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></path>
                    <polyline points="222.9 74.6 128.9 128 33.1 74.6" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></polyline>
                    <line x1="128.9" y1="128" x2="128" y2="234.8" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="24"></line>
                  </svg>

                  {openDropdownIndex === index && (
                    <ButtonInactive theme={allStyles} className="matrix-cubetype" ref={dropdownRefs.current[index]}>
                      {cubeTypeOptions.map((cube) => (
                        <SelectDiv
                          theme={allStyles}
                          className={`dropdown-matrix-cubetype ${data.cubeType === cube.value ? 'selected' : ''}`}
                          key={cube.value}
                          onClick={() => {
                            handleCubeType(cube.value, index);
                            setOpenDropdownIndex(null);
                            setLoad(false);
                          }}
                        >
                          {cube.label}
                          {data.cubeType === cube.value && (
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-49 141 512 512" width="16" height="16" aria-hidden="true" className="dropdown-tick" fill={themeName==='light'?"#00000":"#FFFFFF"}>
                              <path d="M127 552.25a24.92 24.92 0 0 1-17.678-7.322l-101-101c-9.763-9.763-9.763-25.593 0-35.355 9.764-9.764 25.592-9.764 35.355 0l83.339 83.34 243.323-242.857c9.771-9.754 25.602-9.74 35.355.033 9.754 9.772 9.739 25.602-.033 35.355l-261 260.5A24.92 24.92 0 0 1 127 552.25"></path>
                            </svg>
                          )}
                        </SelectDiv>
                      ))}
                    </ButtonInactive>
                  )}
                </ButtonInactive>

                <TextColor theme={allStyles} className='import-modal-session-value' style={{ cursor: 'pointer' }} onClick={() => handleRemoveImportData(index)}>
                  <IoMdClose style={{ opacity: '0.7' }} />
                </TextColor>
              </div>
            ))}
          </div>

        </div>}

        {/* Import button */}
        {importData.length !== 0 && (
        <FocusBox
          theme={allStyles}
          className={`focus-button import-modal-btn ${buttonClicked ? 'button-disabled' : ''}`}
          style={{ padding: '12px 5px', pointerEvents: buttonClicked ? 'none' : 'auto' }}
          onClick={() => handleImport(importData)}
        >
          Import data
        </FocusBox>
      )}

      </ProfileModal>
    </div>
  )
}

export default ImportModal