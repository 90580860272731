import React from 'react'
import { Heading, ProfileModal, FocusBox } from './StyledComponent'
import { IoMdClose } from 'react-icons/io'
import { useLocation } from 'react-router-dom'
import { useIsMobile } from '../../../utils/functions/CustomHooks'
const DeleteModal = ({ allStyles, setDeleteSolveModal, handleDeleteSolve, id }) => {
    const location = useLocation()
    const isMobile = useIsMobile
    return (
        <div className={`profile-modal ${location.pathname === '/timer' ? 'delete-height' : ''}`}>

            <ProfileModal theme={allStyles} className='profile-modal-container delete-modal-width'>

                {!isMobile ? (<div style={{ position: 'relative' }} onClick={() => { setDeleteSolveModal(false) }}>
                    <button
                        className="close-button" aria-label="Close"
                        style={{ right: '-5px' }}
                    >
                        <IoMdClose style={{ width: '20px' }} />
                    </button>
                </div>) : (<div style={{ position: 'relative' }} onClick={() => { setDeleteSolveModal(false) }}>
                    <button
                        className="close-button" aria-label="Close"
                        style={{ right: '-5px', top: '-5px' }}
                    >
                        <IoMdClose style={{ width: '20px' }} />
                    </button>
                </div>)}

                {/* heading */}
                <div>
                    <Heading theme={allStyles} className='profile-edit-heading' style={{ textAlign: 'center' }}>Want to Delete a Solve ?</Heading>

                    <div className='btn-container'>

                        <FocusBox theme={allStyles} type='submit' className='focus-button' style={{ marginTop: '1rem' }} onClick={() => {
                            handleDeleteSolve(id)
                            setDeleteSolveModal(false)
                        }}
                        >
                            Confirm Delete
                        </FocusBox>

                    </div>
                </div>

            </ProfileModal>
        </div>
    )
}

export default DeleteModal