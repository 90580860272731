import styled, { css } from 'styled-components';

export const OptionDiv = styled.div`
background-color: ${(props) => props.theme.backgroundColor}`;

export const SelectOptionDiv = styled.div`
background-color: ${(props)=> props.theme.secondaryOption};
`
export const SelectOptionDiv2 = styled.div`
background-color: ${(props)=> props.theme.btnActive};
border-image-source: ${(props) =>
    props.theme.theme === 'default'
      ? 'linear-gradient(154.84deg, #E817BA 15.64%, rgba(232, 23, 186, 0) 51.7%, rgba(232, 23, 186, 0) 86%)'
      : null};
`

export const ButtonInactive = styled.div`
background:${(props) => props.theme.btnActive};
color:${(props) => props.theme.textColor}
`;

export const FocusBox = styled.button`
background-color: ${(props)=> props.theme.FocusBox};
color: ${(props) => props.theme.buttonTextColorSecondary}; 

&:hover {
  background-color: ${(props) => props.theme.hoverBackground};
}
`

export const SelectDiv = styled.li`
background-color:  ${(props) => props.theme.btnActive};
color: ${(props) => props.theme.textColor};

&:hover {
  color: ${(props) => props.theme.hoverBackground};
}

&.selected {
  color: ${(props) => props.theme.hoverBackground};
}
`;