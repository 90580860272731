/* This code snippet is creating a React context for managing themes in a React application. Here's a
breakdown of what the code is doing: */
import React, { createContext, useState } from 'react'
import { themeData } from './assets/styles/ThemeData';
// import { ThemeProvider, createGlobalStyle, css } from 'styled-components';
import "./assets/styles/_mixins.scss"

export const ThemeContext = createContext({ themeName: "default", undefined, undefined, theme: themeData, allStyles: themeData['default'], undefined, undefined });
export const ThemeProvide = ({ children }) => {
  const [themeName, setThemeName] = useState(localStorage.getItem('theme') || 'default');
  const [primaryColor, setprimaryColor] = useState("#ff0000");
  //nav bar collapse variable
  const [collapse, setCollapse] = useState(false);
  const theme = {
    ...themeData,
    dynamic: {
      theme: "dynamic",
      backgroundColor: primaryColor,
      // oppBackgroundColor: defaultPrimaryOppColor,
      // secondaryBackgroundColor: defaultSecondaryColor,
      // textColor: defaultTextPrimaryColor,
      // buttonColorPrimary: defaultPrimaryColor,
      // buttonTextColorPrimary: defaultPrimaryOppColor,
      // buttonColorSecondary: defaultPrimaryOppColor,
      // buttonTextColorSecondary: defaultTextPrimaryOppColor,
      // borderColor_0: defaultBorderColor0,
      // borderColor_1: defaultBorderColor1,
    }
  }
  let allStyles = theme[themeName]

  return React.createElement(ThemeContext.Provider, { value: { themeName, setThemeName, setprimaryColor, theme, allStyles, collapse, setCollapse } }, children);
};