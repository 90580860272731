import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
const DumyPage = () => {
    const router = useNavigate()
    useEffect(() => {
        router('/timer')
    }, [])
    
  return (
    <div>DumyPage</div>
  )
}

export default DumyPage