import React from "react";
import {
  get222imgmatrix,
  get333imgmatrix,
  get444imgmatrix,
  get555imgmatrix,
  get666imgmatrix,
  get777imgmatrix,
} from "wca-scramble-to-matrix";
import Cube from "../cube/Cube";
import "./ScrambledCube.css";
import { useLocation } from "react-router-dom";
import { useIsMobile } from "../../utils/functions/CustomHooks";
const ScrambledCube = ({ cubeType, scramble }) => {
  // console.log(cubeType, scramble);
  const isMobile = useIsMobile()
  const router = useLocation()
  let matrix;
  switch (cubeType) {
    case "222":
      matrix = get222imgmatrix(scramble);
      break;
    case "333":

      matrix = get333imgmatrix(scramble);
      break;
    case "333OH":

      matrix = get333imgmatrix(scramble);
      break;
    case "333BF":

      matrix = get333imgmatrix(scramble);
      break;
    case "444":

      matrix = get444imgmatrix(scramble);
      break;
    case "444BF":

      matrix = get444imgmatrix(scramble);
      break;
    case "555":

      matrix = get555imgmatrix(scramble);
      break;
    case "555BF":

      matrix = get555imgmatrix(scramble);
      break;
    case "666":

      matrix = get666imgmatrix(scramble);
      break;
    case "777":

      matrix = get777imgmatrix(scramble);
      break;

    default:
      break;
  }
  // handleScramble(scramble)
  return (
    <div className="cube_class"
      style={{
        position: `${router.pathname === '/sessions' || router.pathname === '/solves' ? 'relative' : ''}`,
        bottom: `${router.pathname === '/sessions' || router.pathname === '/solves' ? isMobile ? '0rem' : '3rem' : ''}`,
        maxWidth: `${router.pathname === '/sessions' || router.pathname === '/solves' ? '280px' : '400px'}`,
      }}
    >
      <Cube matrix={matrix} />
    </div>
  );
};

export default ScrambledCube;
