import React, { useContext, useEffect, useState } from "react";
import "./TopBar.scss";
import {
  Box,
  CreateSessionMain,
  SelectDiv,
  SelectOptionDiv,
  TextDiv,
  TextDivSecondary,
  FocusBox
} from "../styledComponents";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { IconButton } from "@mui/material";
import { createSession } from "../../../apis/sessions";
import { ReserveContext } from "../../../App";
import { toast } from "react-toastify";
import { useIsMobile } from "../../../utils/functions/CustomHooks";

const CreateSession = ({ allStyles, dispCNS, setDispCNS, handleOptionClick }) => {
  const updateReserve = useContext(ReserveContext).updateReserve;
  const reserve = useContext(ReserveContext).reserve;
  const maxCharacterLimit = 14; // Define the character limit here
  const isMobile = useIsMobile()
  const [name, setName] = useState("");
  const [characterCount, setCharacterCount] = useState(0);
  const isCharacterLimitExceeded = characterCount > maxCharacterLimit;
  const sessions = reserve.sessions;

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleCreateSession({ name: name });
    }
  }

  const handleCreateSession = async (body) => {
    if (name === "" || name.length == 0) {
      toast.error("Session name cannot be empty",{autoClose:1000,className:'copy-toast'});
      return;
    }
    const isNameUnique = !sessions?.some(session => session.name.trim() === name.trim());
    if (!isCharacterLimitExceeded && isNameUnique) {
      
      await createSession(body)
        .then(async (data) => {
          setDispCNS(false);
          let newSess = data.session;
          await updateReserve((reserve) => {
            if (!reserve.sessions) reserve.sessions = [];
            reserve.sessions.push(newSess);
          });
          await handleOptionClick(newSess);
        })
        .catch((err) => {
          console.log(err);
        });

      setName("");
    } else {
      toast.error("Session name already exists or character limit exceeded",{autoClose:2000,className:'copy-toast'});
    }
  };

  return (
    <div style={{
      display: `${dispCNS ? "flex" : "none"}`,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: `${isMobile ? '100vh' : '100%'}`,
      position: 'absolute',
      zIndex: '100',
      top: `${isMobile ? '-100px' : '0'}`,
      left: '0',
      // backgroundColor: 'rgba(0, 0, 0, 0.5)',
      backdropFilter: 'blur(10px)',
      WebkitBackdropFilter: 'blur(10px)',
    }}>
      <CreateSessionMain
        theme={allStyles}
        className={dispCNS ? "create-session-main" : "create-session-main display-none"}
      >
        <div className="CNS-cancel-div">
          <IconButton
            sx={{ color: `${allStyles.textColor}` }}
            aria-label="cancel"
            className="CNS-cancel"
            onClick={() => {
              setDispCNS(false);
            }}
          >
            <ClearOutlinedIcon
              className="create-session-cancel-button"
              sx={{ color: `${allStyles.textColor}` }}
            />
          </IconButton>
        </div>
        <TextDiv theme={allStyles} className="CNS-title">
          Create New Session
        </TextDiv>
        <TextDiv theme={allStyles} className="CNS-desc">
          In UpCube, sessions can have multiple cube types. You can split up sessions however you'd like: by cube type, by day, etc.
        </TextDiv>
        <div className="CNS-input-div">
          <TextDiv theme={allStyles} className="CNS-session-text">
            Session Name
          </TextDiv>
          <input
            type="text"
            placeholder="New Session"
            onKeyDown={handleKeyPress}
            className={`CNS-input ${isCharacterLimitExceeded ? "input-error" : ""}`}
            value={name}
            onChange={(e) => {
              e.stopPropagation();
              const inputValue = e.target.value;
              if (inputValue.includes(' ')) {
                toast.error("Session name cannot contain spaces",{autoClose:1000,className:'copy-toast'});
              } else if (inputValue.length <= maxCharacterLimit) {
                setName(inputValue);
                setCharacterCount(inputValue.length);
              }
            }}
          />
          {isCharacterLimitExceeded && (
            <div className="character-limit-error">Character limit is 14</div>
          )}
        </div>
        <div className="CNS-button-div">
          <FocusBox
            className="focus-button"
            theme={allStyles}
            onClick={() => {
              handleCreateSession({ name: name });
            }}
          >
            Create
          </FocusBox>
        </div>
      </CreateSessionMain>
    </div>
  );
};

export default CreateSession;
