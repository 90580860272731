import React, { useContext, useState } from 'react'
import { ThemeContext } from "../../context";
import SolvesComponent from '../../components/Solves/SolvesComponent'
import MainSolveModal from '../../components/Modals/SolveModal/MainSolveModal';
import MainDeleteSolveModal from '../../components/Modals/DeleteSolveModal/MainDeleteSolveModal';

const Solves = () => {

  const [cubeType, setCubeType] = useState(localStorage.getItem("cubeType") || "all");
  const [disableCubeType, setDisableCubeType] = useState(false);
  const [load, setLoad] = useState(false);
  const handleCubeType = (type) => {
    setCubeType(type);
  };
 
  const { allStyles } =
    useContext(ThemeContext);
  return (
    <>

    <MainDeleteSolveModal />

    <MainSolveModal />

      <SolvesComponent 
      allStyles={allStyles}
      cubeType={cubeType}
      handleCubeType={handleCubeType}
      setLoad={setLoad}
      disableCubeType={disableCubeType} 
      />
    
    </>
  )
}

export default Solves