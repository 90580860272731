import styled, { css } from 'styled-components';

export const ButtonActive = styled.button`
background:${(props) => props.theme.textColor};
`;

export const Background = styled.div`
background-color: ${(props) => props.theme.backgroundColor};
`

export const SelectDivActive = styled.select`
background:  ${(props) => props.theme.textColor};
color: ${(props) => props.theme.btnActive};
`;

export const Heading = styled.h1`
color:  ${(props) => props.theme.buttonTextColorSecondary};
`

export const TextColor = styled.div`
color:  ${(props) => props.theme.buttonTextColorSecondary};
`

export const ParaTextColor = styled.div`
color:  ${(props) => props.theme.buttonTextColorSecondary};
`

export const Button = styled.div`
background: ${(props) => props.theme.solvesBtn};
`

export const ControlButton = styled.button`
background: ${(props) => props.theme.oppBackgroundColor};
color:  ${(props) => props.theme.textColorInactive};
`

export const FocusBox = styled.div`
background-color: ${(props)=> props.theme.FocusBox};
color: ${(props) => props.theme.buttonTextColorSecondary}; 

&:hover {
    background-color: ${(props) => props.theme.hoverBackground};
  }
`

export const FocusBoxBtn = styled.button`
background-color: ${(props)=> props.theme.FocusBox};
color: ${(props) => props.theme.buttonTextColorSecondary}; 

&:hover {
    background-color: ${(props) => props.theme.hoverBackground};
  }
`

export const ButtonInactive = styled.button`
background:${(props) => props.theme.btnActive};
color:${(props) => props.theme.textColor}
`; 

export const SelectDiv = styled.div`
background-color:  ${(props) => props.theme.btnActive};
color: ${(props) => props.theme.textColor};

&:hover {
  color: ${(props) => props.theme.hoverBackground};
}

&.selected {
  color: ${(props) => props.theme.hoverBackground};
}
`;