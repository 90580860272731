//default variables
let defaultPrimaryColor = 'rgba(10, 0, 58, 1)';
let defaultPrimaryDarkColor = "#692BCB33";
let defaultPrimaryOppColor = '#FFFFFF';
let defaultSecondaryColor = 'rgba(105, 43, 203, 1)';
let defaultTextPrimaryColor = 'rgba(255, 255, 255, 1)';
let defaultTextPrimaryOppColor = '#FFFFFF';
let defaultTextSecondaryColor = '#0A003A';
let defaultBorderColor0 = 'rgba(232, 23, 186, 1)';
let defaultBorderColor1 = 'rgba(232, 23, 186, 0)';
let defaultButtonColor = '#692BCB';
let defaultSecondaryOption = 'rgba(193, 155, 253, 0.5)'
let defaultBoxbackground = 'rgba(105, 43, 203, 0.2)';
let defaultSettingBox = 'rgba(10, 0, 58, 0.5)';
let defaultBtnColorActive = 'rgba(10, 0, 58, 1)';
let defaultSolvesBtn = '#AA4FF6';
let defaultNavBackground = 'linear-gradient(270deg, #0A003A 0%, #692BCB 100%)'
let defaultLearningBox = '#AA4FF6';
let defaultFocusBox = '#692bcb';
let defaultHoverBackground = '#9c71e1'

//dark variables
let darkPrimaryColor = 'rgba(33, 33, 33, 1)';
let darkPrimaryOppColor = '#FFFFFF';
let darkSecondaryColor = '#111111';
let darkTextPrimaryColor = 'white';
let darkTextPrimaryOppColor = '#FFFFFF';
let darkTextSecondaryColor = '#0A003A';
let darkBorderColor0 = 'rgba(232, 23, 186, 1)';
let darkBorderColor1 = 'rgba(232, 23, 186, 0)';
let darkButtonColor = '#692BCB';
let darkSecondaryOption = 'rgba(193, 155, 253, 0.5)';
let darkBoxbackground = 'rgba(26, 26, 26, 1)';
let darkBtnColorActive = 'rgba(51, 51, 51, 1)';
let darkSolvesBtn = '#111111';
let darkNavBackground = 'rgba(17, 17, 17, 1)'
let darkLearningBox = '#AA4FF6';
let darkFocusBox = 'rgba(51, 51, 51, 1)';
let darkHoverBackground = 'rgb(113 109 109)' 
// rgb(69 67 67)

//light variables 
let lightPrimaryColor = 'rgba(222, 222, 222, 1)';
let lightPrimaryOppColor = '#0D0D0D8C';
let lightSecondaryColor = '#FFFFFF';
let lightTextPrimaryColor = 'black';
let lightTextPrimaryOppColor = 'rgba(15, 15, 15, 1)';
let lightTextSecondaryColor = 'rgba(13, 13, 13, 1)';
let lightBorderColor0 = 'rgba(232, 23, 186, 1)';
let lightBorderColor1 = 'rgba(232, 23, 186, 0)';
let lightButtonColor = '#9364DE';
let lightSecondaryOption = 'rgba(193, 155, 253, 0.5)'
let lightBoxbackground = 'rgba(255, 255, 255, 1)';
let lightSettingBox = 'rgba(229, 229, 229, 1)';
let lightBtnColorActive = 'rgba(222, 222, 222, 1)';
let lightSolvesBtn = '#0D0D0D8C';
let lightNavBackground = 'rgba(255, 255, 255, 1)'
let lightLearningBox = 'rgba(51, 51, 51, 1)';
let lightFocusBox = 'rgb(180 180 180)';
let lightHoverBackground = 'rgb(140, 140, 140)'

exports.themeData = {
    default: {
        theme: "default",
        backgroundColor: defaultPrimaryColor,
        navBackground: defaultNavBackground,
        darkBackgroundColor: defaultPrimaryDarkColor,
        oppBackgroundColor: defaultPrimaryOppColor,
        secondaryBackgroundColor: defaultSecondaryColor,
        textColor: defaultTextPrimaryColor,
        textColorInactive: defaultTextSecondaryColor,
        buttonColorPrimary: defaultButtonColor,
        buttonTextColorPrimary: defaultPrimaryOppColor,
        buttonColorSecondary: defaultPrimaryOppColor,
        buttonTextColorSecondary: defaultTextPrimaryOppColor,
        borderColor_0: defaultBorderColor0,
        borderColor_1: defaultBorderColor1,
        secondaryOption:defaultSecondaryOption,
        statsBoxBackground: defaultBoxbackground,
        settingBox: defaultSettingBox,
        btnActive: defaultBtnColorActive,
        solvesBtn: defaultSolvesBtn,
        learningBox: defaultLearningBox,
        FocusBox: defaultFocusBox,
        hoverBackground: defaultHoverBackground,
    },
    dark: {
        theme: "dark",
        backgroundColor: darkPrimaryColor,
        navBackground: darkNavBackground,
        oppBackgroundColor: darkPrimaryOppColor,
        secondaryBackgroundColor: darkSecondaryColor,
        textColor: darkTextPrimaryColor,
        textColorInactive: darkTextSecondaryColor,
        buttonColorPrimary: darkButtonColor,
        buttonTextColorPrimary: darkPrimaryOppColor,
        buttonColorSecondary: darkPrimaryOppColor,
        buttonTextColorSecondary: darkTextPrimaryOppColor,
        borderColor_0: darkBorderColor0,
        borderColor_1: darkBorderColor1,
        secondaryOption:darkSecondaryOption,
        statsBoxBackground: darkBoxbackground,
        settingBox: darkBoxbackground,
        btnActive: darkBtnColorActive,
        solvesBtn: darkSolvesBtn,
        learningBox: darkLearningBox,
        FocusBox: darkFocusBox,
        hoverBackground: darkHoverBackground,
    },
    light: {
        theme: "light",
        backgroundColor: lightPrimaryColor,
        navBackground: lightNavBackground,
        oppBackgroundColor: lightPrimaryOppColor,
        secondaryBackgroundColor: lightSecondaryColor,
        textColor: lightTextPrimaryColor,
        textColorInactive: lightTextSecondaryColor,
        buttonColorPrimary: lightButtonColor,
        buttonTextColorPrimary: lightPrimaryOppColor,
        buttonColorSecondary: lightPrimaryOppColor,
        buttonTextColorSecondary: lightTextPrimaryOppColor,
        borderColor_0: lightBorderColor0,
        borderColor_1: lightBorderColor1,
        secondaryOption:lightSecondaryOption,
        statsBoxBackground: lightBoxbackground,
        settingBox: lightSettingBox,
        btnActive: lightBtnColorActive,
        solvesBtn: lightSolvesBtn,
        learningBox: lightLearningBox,
        FocusBox: lightFocusBox,
        hoverBackground: lightHoverBackground,
    },
}