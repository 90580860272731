import axios from "axios";
import { urls } from "./urls";

export const getAllSolves = async () => {
  return (await axios.get(urls.bulkSolves)).data;
};

export const submitBulkSolve = async (body) => {
  return (await axios.post(urls.bulkSolves, body)).data;
};

export const updateBulkSolve = async (body) => {
  return (await axios.put(urls.bulkSolves, body)).data;
};

export const deleteBulkSolve = async (solveId) => {
  return await axios.delete(urls.bulkSolves + "/" + solveId);
};
