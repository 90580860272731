import React, { useContext, useState } from 'react'
import './Glossary.scss'
import { Button1, Button2, Heading, TextColor, Background } from './StyledComponent'
import Data from './GlossaryContent'
import { ThemeContext } from '../../context'
const GlossaryComponent = ({ allStyles }) => {

    const speedCubingData = Data[0].SpeedCubing;
    const cubingData = Data[1].Cubing;
    const {themeName} = useContext(ThemeContext)
    const [speedCubing, setspeedCubing] = useState(true)
    const [cubing, setCubing] = useState(false)

    const handleSpeedCubing = () => {
        setspeedCubing(true)
        setCubing(false)
    }

    const handleCubing = () => {
        setspeedCubing(false)
        setCubing(true)
    }

    return (
        <div className='glossary-container'>

            <Heading theme={allStyles} className='glossary-heading'>
                Glossary
            </Heading>

            <div className='glossary-btn'>

                <Button2 theme={allStyles} onClick={handleSpeedCubing} style={{borderBottom: `${speedCubing ? themeName === 'light' ? '5px solid black' : '3px solid white' : ''}`}} >
                    Speed Cubing
                </Button2>

                <Button2 theme={allStyles} onClick={handleCubing} style={{borderBottom: `${cubing ? themeName === 'light' ? '5px solid black' : '3px solid white' : ''}`}} >
                    Cubing
                </Button2>

            </div>

            <div className='glossary-box'>


                {speedCubing && speedCubingData.map((data) => {

                    return (

                        <Background theme={allStyles} className='glossary-content' key={data.id} style={{ borderRadius: '12px' }}>

                            <Heading theme={allStyles}>{data.id}. {data.heading}</Heading>

                            <TextColor theme={allStyles}>{data.paragraph}</TextColor>

                        </Background>

                    )

                })}

                {cubing &&
                    cubingData.map((data) => {

                        return (

                            <Background theme={allStyles} className='glossary-content' key={data.id} style={{ borderRadius: '12px' }} >

                                <Heading theme={allStyles}>{data.id}. {data.heading}</Heading>

                                <TextColor theme={allStyles}>{data.paragraph}</TextColor>

                            </Background>

                        )

                    })
                }
            </div>
        </div >
    )
}

export default GlossaryComponent