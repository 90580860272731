import React, { useContext } from 'react'
import { ReserveContext } from '../../../App'
import { deleteSolve } from '../../../apis/solves';
import { toast } from 'react-toastify';
import DeleteModal from './DeleteModal';

const MainDeleteSolveModal = ({allStyles, setSolves}) => {
    
    const updateReserve = useContext(ReserveContext).updateReserve;

    const {
        setDeleteSolveModal, DeleteSolveModal,
        solveId,
    } = useContext(ReserveContext)

    const handleDeleteSolve = async (solveId) => {
        try {
            await deleteSolve(solveId);
            setSolves((prevSolves) => prevSolves.filter((solve) => solve.solveId !== solveId));
            updateReserve((prevReserve) => ({
                ...prevReserve,
                solves: prevReserve.solves.filter((solve) => solve.solveId !== solveId),
            }));
            toast.success("Solve deleted successfully.",{autoClose:1000,className:'copy-toast'})
        } catch (error) {
            toast.error("Error deleting solve.",{autoClose:1000,className:'copy-toast'})
            console.error("Error deleting solve:", error);
        }
    };

    return (
        <>
            {
                DeleteSolveModal &&
                <DeleteModal
                    allStyles={allStyles}
                    setDeleteSolveModal={setDeleteSolveModal}
                    handleDeleteSolve={handleDeleteSolve}
                    id={solveId}
                />
            }

        </>
    )
}

export default MainDeleteSolveModal