import React, { useContext } from 'react'
import { InnerBox, TextDiv, Background } from '../styledComponents';
import { formatter } from '../Timer Section/TimerLogic';
import { CalculateAo5and12, CalculateAoXpb, CalculateAverage } from '../../../utils/functions/calculations';
import NoSolves from "../../../assets/images/timer/nosolves.png";
import { ReserveContext } from '../../../App';

const StatsBox = ({ allStyles, stats, cubeType, returnTextCubeType, solves }) => {
  const { isFocus } = useContext(ReserveContext)
  const cubeTypeFilter = solves?.filter((solve) => solve.cubeType === cubeType)
  let dnfManualSolves = cubeTypeFilter?.filter(solve => solve.dnfManual);
  
  return (
    <>
      {Object.keys(stats).length !== 0 ? <>
        <div className={`timer-column ${isFocus ? 'hidden' : ''}`}>

          {/* PB */}
          <InnerBox theme={allStyles} className="innerBox pb">
            <TextDiv theme={allStyles} className="timer-text">
              PB
            </TextDiv>
            <div theme={allStyles} className="timer-value pb-value">
              {stats[returnTextCubeType(cubeType)]?.pb.time ==
                Number.POSITIVE_INFINITY
                ? "-"
                : formatter(
                  stats[returnTextCubeType(cubeType)]?.pb.time
                )}
            </div>
          </InnerBox>

          {/* Ao5PB */}
          <InnerBox theme={allStyles} className="innerBox ao5pb">
            <TextDiv theme={allStyles} className="timer-text">
              ao5 PB
            </TextDiv>
            <TextDiv theme={allStyles} className="timer-value">
              {stats[returnTextCubeType(cubeType)]?.ao5pb.value ==
                Number.POSITIVE_INFINITY
                ? "-"
                : dnfManualSolves > 1 ? 'DNF' : formatter(
                  stats[returnTextCubeType(cubeType)]?.ao5pb.value
                )}
            </TextDiv>
          </InnerBox>

          {/* Ao12PB */}
          <InnerBox theme={allStyles} className="innerBox ao12pb">
            <TextDiv theme={allStyles} className="timer-text">
              ao12 PB
            </TextDiv>
            <TextDiv theme={allStyles} className="timer-value">
              {stats[returnTextCubeType(cubeType)]?.ao12pb.value ==
                Number.POSITIVE_INFINITY
                ? "-"
                : dnfManualSolves > 1 ? 'DNF' :
                  formatter(
                    stats[returnTextCubeType(cubeType)]?.ao12pb.value
                  )
                }
            </TextDiv>
          </InnerBox>
        </div>

        <div className={`timer-column ${isFocus ? 'hidden' : ''}`}>

          {/* worst */}
          <InnerBox theme={allStyles} className="innerBox worst">
            <TextDiv theme={allStyles} className="timer-text">
              worst
            </TextDiv>
            <div
              theme={allStyles}
              className="timer-value worst-value"
            >
              {
                stats[returnTextCubeType(cubeType)]?.worst ?
                  formatter(
                    stats[returnTextCubeType(cubeType)]?.worst
                  ) : "-"
              }
            </div>
          </InnerBox>

          {/* Average */}
          <InnerBox theme={allStyles} className="innerBox avg">
            <TextDiv theme={allStyles} className="timer-text">
              avg
            </TextDiv>
            <TextDiv theme={allStyles} className="timer-value">
              {dnfManualSolves?.length === 0 ?
                <>
                  {formatter(
                    CalculateAverage(
                      solves?.filter((solve) => {
                        return solve?.cubeType == cubeType;
                      })
                    )
                  )}
                </>
                : <>
                  {dnfManualSolves?.length > 1 ? 'DNF' : <>
                    {formatter(
                      CalculateAverage(
                        solves?.filter((solve) => {
                          return solve?.cubeType == cubeType;
                        })
                      )
                    )}
                  </>}
                </>}
            </TextDiv>
          </InnerBox>

          {/* Ao5 */}
          <InnerBox theme={allStyles} className="innerBox ao5">
            <TextDiv theme={allStyles} className="timer-text">
              ao5
            </TextDiv>

            <TextDiv theme={allStyles} className="timer-value">
              {stats[returnTextCubeType(cubeType)]?.solvesCount >= 5
                ?
                <>
                  {
                    CalculateAo5and12(solves, cubeType, 5)
                  }
                </>
                : "-"
              }
            </TextDiv>
          </InnerBox>

          {/* Ao12 */}
          <InnerBox theme={allStyles} className="innerBox ao12">
            <TextDiv theme={allStyles} className="timer-text">
              ao12
            </TextDiv>

            <TextDiv theme={allStyles} className="timer-value">
              {stats[returnTextCubeType(cubeType)]?.solvesCount >= 12
                ?
                <>
                  {
                    CalculateAo5and12(solves, cubeType, 12)
                  }
                </>
                : "-"
              }
            </TextDiv>
          </InnerBox>
        </div>

      </> 
      
      : 
      
      <Background theme={allStyles} className='session-no-solves-div'>
        <img src={NoSolves} alt="NoSolves" />
        {/* <Heading theme={allStyles}>No Stats Yet</Heading> */}
      </Background>}
    </>
  )
}

export default StatsBox