import React, { useContext, useEffect, useRef, useState } from "react";
import { SelectDiv, FocusBox, ButtonInactive } from "../styledComponents";
import { useIsMobile } from "../../../utils/functions/CustomHooks";
import { ReserveContext } from "../../../App";
import CreateSession from "./CreateSession";
import CustomSelect from "../../Global/Custom Select/CustomSelect";
import { PiCrosshairFill } from 'react-icons/pi'
import { ThemeContext } from "../../../context";
import { RxCross2 } from 'react-icons/rx'

const TopBar = ({
  allStyles,
  Focus,
  handleCubeType,
  setLoad,
  disableCubeType,
  cubeType,
  sessionData,
  setSessionData,
  sessions,
  isOpen, setIsOpen
}) => {
  const { themeName } = useContext(ThemeContext)
  let { isFocus, setisFocus, dispCNS, setDispCNS, matrixDropdown, setmatrixDropdown, guide } = useContext(ReserveContext)
  const [disable, setDisable] = useState(false);
  const dropdownRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const isMobile = useIsMobile();

  const handleOptionClick = (option) => {
    setSessionData(option);
    setSelectedOption(option);
    setIsOpen(false);
    localStorage.setItem('current_session', JSON.stringify(option))
  };

  
  const handleFocus = (e) => {
    e.target.blur();
  };


  useEffect(() => {
    setDisable(disableCubeType);
  }, [disableCubeType]);

  useEffect(() => {
    const loadSession = () => {
      if (!sessions) {
        localStorage.removeItem('current_session');
      }
      else {
        let currentSession = localStorage.getItem('current_session');
        currentSession = JSON.parse(currentSession)
        if (currentSession) {
          handleOptionClick(currentSession);
        } else if (!selectedOption) {
          handleOptionClick(sessions[0]);
        }

      }
    };
    loadSession();
  }, [sessions]);
 

  useEffect(() => {
    const handleEscapeKeyPress = (event) => {
      if (event.key === 'Escape') {
        setisFocus(false)
      }
    };

    document.addEventListener('keydown', handleEscapeKeyPress);

    return () => {
      document.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [setisFocus]);

  const returnMatrixCubeType = (input) => {
    switch (input) {
      case "222":
        return "2x2";
      case "333":
        return "3x3";
      case "333OH":
        return "3x3 OH";
      case "333BF":
        return "3x3 BLD";
      case "444":
        return "4x4";
      case "444BF":
        return "4x4 BLD";
      case "555":
        return "5x5";
      case "555BF":
        return "5x5 BLD";
      case "666":
        return "6x6";
      case "777":
        return "7x7";
      case "skewb":
        return "Skewb";
      case "pyra":
        return "Pyraminx";
      case "minx":
        return "Megaminx";
      case "clock":
        return "Clock";
      case "sq1":
        return "Square-1";
      default:
        return;
    }
  };

  const cubeTypeOptions = [
    { value: '222', label: '2x2' },
    { value: '333', label: '3x3' },
    { value: '444', label: '4x4' },
    { value: '555', label: '5x5' },
    { value: '666', label: '6x6' },
    { value: '777', label: '7x7' },
    { value: '333OH', label: '3x3 OH' },
    { value: '333BF', label: '3x3 BLD' },
    { value: 'skewb', label: 'Skewb' },
    { value: 'pyra', label: 'Pyraminx' },
    { value: 'minx', label: 'Megaminx' },
    { value: 'clock', label: 'Clock' },
    { value: 'sq1', label: 'Square-1' },
    { value: '444BF', label: '4x4 BLD' },
    { value: '555BF', label: '5x5 BLD' },
  ]


  useEffect(() => {
    const handleCloseSidebar = () => {
      setmatrixDropdown(false)
      setIsOpen(false)
    }

    // Handle clicks outside the sidebar
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target) && e.target.id !== 'select-cubetype') {
        handleCloseSidebar()
      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleOutsideClick);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [matrixDropdown, setIsOpen, setmatrixDropdown]);


  return (
    // changed for mobile
    <div className={`timer-top-bar ${isFocus ? isMobile ? 'top-corner-mobile' : 'top-corner' : ''}`} style={{ bottom: `${isFocus && isMobile ? '12rem' : isMobile ? '' : '2rem'}` }}>

      <div className={`timer-top-left ${isFocus ? 'hidden' : ''}`}>

        {/* dropdown desktop */}
        <ButtonInactive theme={allStyles} className="cube-type-select" id="select-cubetype" onClick={() => {
          if (!guide) {
            setmatrixDropdown(!matrixDropdown);
          }
          setIsOpen(false);
        }} style={{ position: 'relative' }}>

          {returnMatrixCubeType(cubeType)}

          {/* <img src={dropdown} alt="" /> */}
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6918 3.81886C14.4945 3.63604 14.2268 3.53333 13.9477 3.53333C13.6686 3.53333 13.401 3.63604 13.2036 3.81886L7.99365 8.64608L2.78371 3.81886C2.5852 3.64122 2.31933 3.54292 2.04337 3.54515C1.7674 3.54737 1.50342 3.64993 1.30827 3.83074C1.11313 4.01155 1.00244 4.25614 1.00004 4.51183C0.997642 4.76752 1.10373 5.01386 1.29545 5.19779L7.24952 10.7145C7.44689 10.8973 7.71456 11 7.99365 11C8.27273 11 8.5404 10.8973 8.73777 10.7145L14.6918 5.19778C14.8892 5.01491 15 4.76691 15 4.50832C15 4.24973 14.8892 4.00173 14.6918 3.81886Z"
              fill={`${themeName === 'light' ? "black" : "white"}`} />
          </svg>

          {matrixDropdown && <ButtonInactive theme={allStyles} className="matrix-cubetype" ref={dropdownRef}
            id={`${themeName === 'light' ? 'style-1' : ""}`} >

            {cubeTypeOptions.map((cube) => {
              return (
                <SelectDiv theme={allStyles} className={`dropdown-matrix-cubetype ${cubeType === cube.value ? 'selected' : ''}`} key={cube.value}

                  onClick={(e) => {
                    isMobile && e.stopPropagation();
                    handleCubeType(cube.value);
                    localStorage.setItem('cubeType', cube.value);
                    // setmatrixDropdown(!matrixDropdown)
                    setLoad(false);
                  }}


                >
                  {cube.label}

                  {cubeType === cube.value && <svg xmlns="http://www.w3.org/2000/svg" viewBox="-49 141 512 512" width="16" height="16" aria-hidden="true" className="dropdown-tick" fill={themeName === 'light' ? "#00000" : "#FFFFFF"}><path d="M127 552.25a24.92 24.92 0 0 1-17.678-7.322l-101-101c-9.763-9.763-9.763-25.593 0-35.355 9.764-9.764 25.592-9.764 35.355 0l83.339 83.34 243.323-242.857c9.771-9.754 25.602-9.74 35.355.033 9.754 9.772 9.739 25.602-.033 35.355l-261 260.5A24.92 24.92 0 0 1 127 552.25"></path></svg>}
                </SelectDiv>
              )
            })}

          </ButtonInactive>}

        </ButtonInactive>


        <ButtonInactive theme={allStyles} style={{ borderRadius: '8px', border: 'none' }} className="session-select" id="session-dropdown" >
          <CustomSelect
            sessionData={sessionData}
            setSessionData={setSessionData}
            timer={true}
            session={true}
            dispCNS={dispCNS}
            handleDDSession={setDispCNS}
            allStyles={allStyles}
            options={sessions}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            selectedOption={selectedOption} setSelectedOption={setSelectedOption}
            handleOptionClick={handleOptionClick}
            topText="Select Session"
          />

          <CreateSession
            allStyles={allStyles}
            dispCNS={dispCNS}
            setDispCNS={setDispCNS}
            handleOptionClick={handleOptionClick}
            setIsOpen={setIsOpen}
            setSelectedOption={setSelectedOption}
            setSessionData={setSessionData}
          />
        </ButtonInactive>

      </div>

      <div className={`timer-top-right ${isFocus ? isMobile ? 'position-focus-mobile' : 'position-focus' : ''}`}
        onClick={() => { setisFocus(!isFocus) }}
        onFocus={handleFocus}
        onKeyDown={(e) => {
          if (e.key === ' ' || e.key === 'Spacebar') {
            e.preventDefault(); // Prevent default behavior on spacebar press
          }
        }}
      >
        {isMobile ? (
          <>
            {isFocus ?
              <FocusBox theme={allStyles} className={`focus-button`} style={{ marginTop: `${isFocus ? '-200%' : '0px'}`, position: 'absolute', top: '-20vh', left: "-50px", }}>

                {themeName === 'light' ? <RxCross2 style={{ color: 'black', fontSize: '1rem' }} /> : <RxCross2 style={{ color: 'white', fontSize: '1rem' }} />}
              </FocusBox>
              :
              <FocusBox theme={allStyles} className={`focus-button`} style={{ marginTop: `${isFocus ? '10px' : '0px'}` }}>
                {themeName === 'light' ? <PiCrosshairFill style={{ color: 'black', fontSize: '1rem' }} /> : <img className="focus-image" src={Focus} alt="" />}
              </FocusBox>}
          </>
        ) : (
          <>
            {isFocus ?
              <FocusBox theme={allStyles} className={`focus-button`} style={{ marginTop: `${isFocus ? '10px' : '0px'}`, width: `${isFocus ? '4vw' : ''}` }}>

                {themeName === 'light' ? <RxCross2 style={{ color: 'black', fontSize: '1rem' }} /> : <RxCross2 style={{ color: 'white', fontSize: '1rem' }} />}
              </FocusBox>
              :
              <FocusBox theme={allStyles} className={`focus-button`} style={{ marginTop: `${isFocus ? '10px' : '0px'}`, gap: "6px" }}>
                Focus Mode
                {themeName === 'light' ? <PiCrosshairFill style={{ color: 'black', fontSize: '1rem' }} /> : <img className="focus-image" src={Focus} alt="" />}
              </FocusBox>
            }
          </>
        )}

      </div>

    </div>
  );
};

export default TopBar;
