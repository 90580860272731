import styled from "styled-components";

// export const AuthIconDiv = styled.div`
//   background-color: ${(props) => props.theme.oppBackgroundColor};
// `;

// export const AuthGoogleWrapDiv = styled.div`
//   background:linear-gradient(
//         120deg,
//         ${(props) => props.theme.borderColor_0},
//         ${(props) => props.theme.borderColor_1},
//         ${(props) => props.theme.borderColor_1}
//       );
// `;

// export const AuthGoogleDiv = styled.div`
//   background-color: ${(props) => props.theme.backgroundColor};
// `;

// export const AuthLeftContainerDiv = styled.div`
// color: ${(props) => props.theme.textColor};
// background-image: linear-gradient(
//     ${(props) => props.theme.backgroundColor},
//     ${(props) => props.theme.secondaryBackgroundColor}
//     );
// `;

export const AuthMain = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
`;

export const AuthTextDiv = styled.div`
color:${(props) => props.theme.textColor} ;
`
export const AuthTextDivDim = styled.div`
color:${(props) => props.theme.textColor} ;
`
export const AuthButton = styled.button`
background-color:${(props) => props.theme.buttonColorPrimary} ;
`
export const AuthGoogleButton = styled.button`
border:1px solid ${(props) => props.theme.oppBackgroundColor} ;
`