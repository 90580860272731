import React, { useContext } from "react";
import "./NavComponents.scss";
import { MainNavDiv, NavTextDiv } from "./styledComponents";
import { Link, NavLink, useLocation } from "react-router-dom";

// Importing assets
import Logo from "../../assets/images/logo.svg";
import LogoShort from "../../assets/images/logo-short.svg";
import LogoShortBlack from "../../assets/images/logo-short-black.png";

import Timer from "../../assets/images/nav-svg/timer.svg";
import Stats from "../../assets/images/nav-svg/stats.svg";
import Solves from "../../assets/images/nav-svg/solves.svg";
import Sessions from "../../assets/images/nav-svg/sessions.svg";
import Glossary from "../../assets/images/nav-svg/glossary.svg";
import Learning from "../../assets/images/nav-svg/learning.svg";
import Help from "../../assets/images/nav-svg/help.svg";
import Settings from "../../assets/images/nav-svg/settings.svg";
import Profile from "../../assets/images/nav-svg/profile.svg";
import Collapse from "../../assets/images/nav-svg/collapse.svg";
import TimerMob from "../../assets/images/nav-svg/timerMob.svg";
import StatsMob from "../../assets/images/nav-svg/statsMob.svg";
import SolvesMob from "../../assets/images/nav-svg/solvesMob.svg";
import SessionsMob from "../../assets/images/nav-svg/sessionMob.svg";
import GlossaryMob from "../../assets/images/nav-svg/glossaryMob.svg";
import LearningMob from "../../assets/images/nav-svg/learningMob.svg";
import HelpMob from "../../assets/images/nav-svg/helpMob.svg";
import SettingsMob from "../../assets/images/nav-svg/settingMob.svg";
import ProfileMob from "../../assets/images/nav-svg/profileMob.svg";
import CollapseMob from "../../assets/images/nav-svg/collapseMob.svg";
import CollapseMobDark from "../../assets/images/nav-svg/collapseMobDark.svg";

import Open from "../../assets/images/nav/open.png";
import lightLogo from "../../assets/images/Logo_color_logo.svg";
import { ReserveContext } from "../../App";

const Nav = ({ collapse, setCollapse, themeName, allStyles }) => {
  const location = useLocation();
  const { isFocus } = useContext(ReserveContext)
  return (
    <MainNavDiv
      theme={allStyles}
      className={`nav ${collapse ? "collapsed" : ""} ${isFocus ? "hidden" : ""}`}
      style={{display: `${window.location.href === '/profile-form' ? 'none' : ''}`}}
    >
      <div className="top">
        {!collapse ? (
          <Link to={'/timer'} className="nav-logo">
            {themeName === 'default' || themeName === 'dark' ? <img src={Logo} alt="Logo" /> : <img src={lightLogo} alt="Logo" />
            }
          </Link>
        ) : (
          <Link to={'/timer'} className="nav-logo-collapsed">
            {themeName === 'default' || themeName === 'dark' ? <img className="collapsedLogo" src={LogoShort} alt="Logo" /> : <img className="collapsedLogo" src={LogoShortBlack} alt="Logo" />
            }
          </Link>
        )}
        
        <div className={`nav-top-menu ${collapse ? "collapsed" : ""}`}>

          <div className={`nav-main-item-box ${collapse ? "collapsed" : ""}`}>

            {/* timer */}
            <NavLink style={{
              textDecoration: 'none',
              background: `${location.pathname === '/timer' ? themeName === 'default' ? 'linear-gradient(270deg, #0A003A 0%, #692BCB 100%)' : themeName === 'dark' ? '#111111' : themeName === 'light' && '#FFFFFF' : ''}`
            }}
              to="/timer" className={`nav-item ${collapse ? "centered" : ""} `}>

              <div className="nav-icon">
                {themeName === 'default' || themeName === 'dark'
                  ?
                  <img src={Timer} alt="Timer" />
                  :
                  <img src={TimerMob} alt="" />
                }

              </div>

              {!collapse && (
                <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/timer' ? "true" : "false"}>
                  Timer
                </NavTextDiv>
              )}

            </NavLink>

            {/* stats */}
            <NavLink activeclassname='active' style={{
              textDecoration: 'none',
              background: `${location.pathname === '/stats' ? themeName === 'default' ? 'linear-gradient(270deg, #0A003A 0%, #692BCB 100%)' : themeName === 'dark' ? '#111111' : themeName === 'light' && '#FFFFFF' : ''}`
            }}
              to="/stats" className={`nav-item ${collapse ? "centered" : ""} `}>

              <div className="nav-icon">
                {themeName === 'default' || themeName === 'dark'
                  ?
                  <img src={Stats} alt="Stats" />
                  :
                  <img src={StatsMob} alt="" />
                }
              </div>

              {!collapse && (
                <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/stats' ? "true" : "false"}>
                  Stats
                </NavTextDiv>
              )}

            </NavLink>

            {/* solves */}
            <NavLink activeclassname='active' style={{
              textDecoration: 'none',
              background: `${location.pathname === '/solves' ? themeName === 'default' ? 'linear-gradient(270deg, #0A003A 0%, #692BCB 100%)' : themeName === 'dark' ? '#111111' : themeName === 'light' && '#FFFFFF' : ''}`
            }}
              to="/solves" className={`nav-item ${collapse ? "centered" : ""} `}>

              <div className="nav-icon">
                {themeName === 'default' || themeName === 'dark'
                  ?
                  <img src={Solves} alt="Solves" />
                  :
                  <img src={SolvesMob} alt="" />
                }
              </div>

              {!collapse && (
                <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/solves' ? "true" : "false"}>
                  Solves
                </NavTextDiv>
              )}

            </NavLink>

            {/* sessions */}
            <NavLink activeclassname='active' style={{
              textDecoration: 'none',
              background: `${location.pathname === '/sessions' ? themeName === 'default' ? 'linear-gradient(270deg, #0A003A 0%, #692BCB 100%)' : themeName === 'dark' ? '#111111' : themeName === 'light' && '#FFFFFF' : ''}`
            }}
              to="/sessions" className={`nav-item ${collapse ? "centered" : ""} `}>

              <div className="nav-icon">
                {themeName === 'default' || themeName === 'dark'
                  ?
                  <img src={Sessions} alt="Sessions" />
                  :
                  <img src={SessionsMob} alt="" />
                }
              </div>

              {!collapse && (
                <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/sessions' ? "true" : "false"}>
                  Sessions
                </NavTextDiv>
              )}

            </NavLink>

            {/* Glossary */}
            <NavLink activeclassname='active' style={{
              textDecoration: 'none',
              background: `${location.pathname === '/glossary' ? themeName === 'default' ? 'linear-gradient(270deg, #0A003A 0%, #692BCB 100%)' : themeName === 'dark' ? '#111111' : themeName === 'light' && '#FFFFFF' : ''}`
            }}
              to="/glossary" className={`nav-item ${collapse ? "centered" : ""} `}>

              <div className="nav-icon">
                {themeName === 'default' || themeName === 'dark'
                  ?
                  <img src={Glossary} alt="Glossary" />
                  :
                  <img src={GlossaryMob} alt="" />
                }
              </div>

              {!collapse && (
                <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/glossary' ? "true" : "false"}>
                  Glossary
                </NavTextDiv>
              )}
            </NavLink>

            {/* Learnings */}
            <NavLink activeclassname='active' style={{
              textDecoration: 'none',
              background: `${location.pathname === '/learnings' ? themeName === 'default' ? 'linear-gradient(270deg, #0A003A 0%, #692BCB 100%)' : themeName === 'dark' ? '#111111' : themeName === 'light' && '#FFFFFF' : ''}`
            }}
              to="/learnings" className={`nav-item ${collapse ? "centered" : ""} `}>

              <div className="nav-icon">
                {themeName === 'default' || themeName === 'dark'
                  ?
                  <img src={Learning} alt="Learning" />
                  :
                  <img src={LearningMob} alt="" />
                }
              </div>

              {!collapse && (
                <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/learnings' ? "true" : "false"}>
                  Learning
                </NavTextDiv>
              )}

            </NavLink>

          </div>

        </div>

      </div>

      {/* Bottom Nav Links */}
      <div className="nav-bottom-menu">

        <div className={`nav-main-item-box ${collapse ? "collapsed" : ""}`}>

          {/* Help */}
          <NavLink activeclassname={`active`} style={{
            textDecoration: 'none',
            background: `${location.pathname === '/help' ? themeName === 'default' ? 'linear-gradient(270deg, #0A003A 0%, #692BCB 100%)' : themeName === 'dark' ? '#111111' : themeName === 'light' && '#FFFFFF' : ''}`
          }}
            to="/help" className={`nav-item ${collapse ? "centered" : ""} `}>

            <div className="nav-icon">

              {themeName === 'default' || themeName === 'dark'
                ?
                <img src={Help} alt="Help" />
                :
                <img src={HelpMob} alt="" />
              }

            </div>

            {!collapse && (
              <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/help' ? "true" : "false"}>
                Help
              </NavTextDiv>
            )}

          </NavLink>

          {/* Settings */}
          <NavLink activeclassname='active' style={{
            textDecoration: 'none',
            background: `${location.pathname === '/settings' ? themeName === 'default' ? 'linear-gradient(270deg, #0A003A 0%, #692BCB 100%)' : themeName === 'dark' ? '#111111' : themeName === 'light' && '#FFFFFF' : ''}`
          }}
            to="/settings" className={`nav-item ${collapse ? "centered" : ""} `}>
            <div className="nav-icon">

              {
                themeName === 'default' || themeName === 'dark'
                  ?
                  <img src={Settings} alt="Settings" />
                  :
                  <img src={SettingsMob} alt="" />
              }

            </div>

            {!collapse && (
              <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/settings' ? "true" : "false"}>
                Settings
              </NavTextDiv>
            )}

          </NavLink>

          {/* Profile */}
          <NavLink activeclassname='active' style={{
            textDecoration: 'none',
            background: `${location.pathname === '/profile' ? themeName === 'default' ? 'linear-gradient(270deg, #0A003A 0%, #692BCB 100%)' : themeName === 'dark' ? '#111111' : themeName === 'light' && '#FFFFFF' : ''}`
          }}
            to="/profile" className={`nav-item ${collapse ? "centered" : ""} `}>

            <div className="nav-icon">

              {themeName === 'default' || themeName === 'dark'
                ?
                <img src={Profile} alt="Profile" />
                :
                <img src={ProfileMob} alt="" />}

            </div>

            {!collapse && (
              <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/profile' ? "true" : "false"}>
                Profile
              </NavTextDiv>
            )}

          </NavLink>

          {/* Collapse */}
          <div className={`nav-item ${collapse ? "centered" : ""} `} activeclassname='active'
            onClick={() => {
              setCollapse(!collapse)
            }}>
            <div className="nav-icon">
            {!collapse ? 
                (themeName === 'default' || themeName === 'dark' ? 
                  <img src={Collapse} alt="Collapse" /> : 
                  <img src={CollapseMob} alt="Collapse Mobile" /> 
                ) : 
                (themeName === 'default' || themeName === 'dark' ? 
                  <img src={Open} alt="Open" /> : 
                  themeName === 'light' ? 
                    <img src={CollapseMobDark} alt="Open" style={{ transform: 'rotate(180deg)' }} /> : 
                    <img src={CollapseMobDark} alt="Collapse Mobile Dark" />
                )
              }

            </div>

            {!collapse && (
              <NavTextDiv theme={allStyles} className="nav-route" active>
                Collapse
              </NavTextDiv>
            )}

          </div>
          {/* <NavLink activeclassname='active' style={{ textDecoration: 'none' }} to="/" className={`nav-item ${collapse ? "centered" : ""} `}>
            <div className="nav-icon">
              <img src={LogOut} alt="LogOut" />
            </div>
            {!collapse && (
              <NavTextDiv theme={allStyles} className="nav-route" active={location.pathname === '/'? "true":"false"}>
                Log Out
              </NavTextDiv>
            )}
          </NavLink> */}
        </div>
      </div>

    </MainNavDiv>
  );
};

export default Nav;
