import React, { useContext, useState } from 'react'
import { ThemeContext } from "../../context";
import { ReserveContext } from '../../App';
import { PrepareDataForStats } from '../../utils/functions/operations.ts';
import StatsComponent from '../../components/Stats/StatsComponent'
const Stats = () => {

  const [cubeType, setCubeType] = useState(localStorage.getItem("cubeType") || "222");
  const [disableCubeType, setDisableCubeType] = useState(false);
  const [load, setLoad] = useState(false);

  var solves = useContext(ReserveContext).reserve.solves;
  // solves = solves ? solves.filter((item) => item.sessionId === currentSessionId) : null
  var stats = PrepareDataForStats(solves);

  const handleCubeType = (type) => {
    setCubeType(type);
  };

  const { allStyles } =
    useContext(ThemeContext);
  return (
    <>

      <StatsComponent
        allStyles={allStyles}
        cubeType={cubeType}
        handleCubeType={handleCubeType}
        setLoad={setLoad}
        disableCubeType={disableCubeType} 
        stats={stats}
        />

      {/* <ScrambleAndImage/> */}

    </>
  )
}

export default Stats