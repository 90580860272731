export const generateScramble = (cubeType) => {
  let scramble;
  switch (cubeType) {
    case "222":
      scramble = window.scramble_222.getRandomScramble();
      break;
    case "333":
      scramble = window.scramble_333.getRandomScramble();

      break;
    case "333OH":
      scramble = window.scramble_333.getRandomScramble();

      break;
    case "333BF":
      scramble = window.scramble_333.getRandomScramble();

      break;
    case "444":
      scramble = window.scramble_444.getRandomScramble();

      break;
    case "444BF":
      scramble = window.scramble_444.getRandomScramble();

      break;
    case "555":
      scramble = window.megaScrambler.get555WCAScramble(60);

      break;
    case "555BF":
      scramble = window.megaScrambler.get555WCAScramble(60);

      break;
    case "666":
      scramble = window.megaScrambler.get666WCAScramble(60);

      break;
    case "777":
      scramble = window.megaScrambler.get777WCAScramble(60);
      break;
    case "pyra":
      scramble = window.pyra_scrambler.getPyraWCAScramble();
      break;
    case "sq1":
      scramble = replaceForwardSlashWithSpace(
        window.sql_scrambler.getRandomScramble()
      );
      break;
    case "clock":
      scramble = window.util_scramble.getClockWCAScramble();
      break;
    case "skewb":
      scramble = window.skewb_scrambler.getSkewbWCAScramble();
      break;
    case "mminx":
      scramble = window.util_scramble.getMegaminxWCAScramble(70).slice(2);
      break;

    default:
      break;
  }
  return scramble;
};

function replaceForwardSlashWithSpace(str) {
  str = str.replaceAll(/\//g, " / ");
  str = str.replaceAll(", ", ",");
  return str;
}
