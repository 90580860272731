import React, {useContext} from 'react'

import { ReserveContext } from '../App'
import DumyPage from './DumyPage'
const ProtectedRoute = ({ component }) => {

    let sessions = useContext(ReserveContext).reserve.sessions
    
    return sessions ? (
        component
    ) : (
        <DumyPage />
    )
}

export default ProtectedRoute