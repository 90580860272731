import { formatter } from "../../components/Timer/Timer Section/TimerLogic";

/**
 * The CalculateAverage function calculates the average time of solves while considering various
 * conditions such as DNFs and penalties.
 * @param solves - It seems like the code snippet you provided is a function named `CalculateAverage`
 * that calculates the average time of solves based on certain conditions. However, the `solves`
 * parameter is missing in the provided context. If you can provide me with the actual data structure
 * or an example of the `s
 * @returns The CalculateAverage function returns the average time of solves after applying certain
 * filtering and calculations based on the input solves array. The average time is calculated by
 * summing the times of valid solves (not DNF or DNF with plus2) and dividing by the count of valid
 * solves. If there is only one solve with dnfManual true, it removes that solve and the solve with the
 * least time spent
 */
export const CalculateAverage = (solves) => {
  let solvesForAvg = solves

  // Step 1: Check if there is only one solve with dnfManual true
  let dnfManualSolves = solvesForAvg.filter(solve => solve.dnfManual);


  // if solves contain only 1 dnf
  if (dnfManualSolves.length === 1) {

    // Step 2: Remove the solve with dnfManual true
    solvesForAvg = solvesForAvg.filter(solve => !solve.dnfManual);

    // calulate the min solve time while checking for +2 penalties
    let minTime = Infinity;
    let minTimeSolve = null;

    solves.forEach(solve => {
      let adjustedTime = solve.time;
      if (solve.plus2Auto || solve.plus2Manual) {
        adjustedTime += 2000;
      }
      if (adjustedTime < minTime) {
        minTime = adjustedTime;
        minTimeSolve = solve;
      }
    });


    // Step 3: Remove the solve with the least time spent
    // let minTimeSolve = solvesForAvg.reduce((min, solve) => solve.time < min.time ? solve : min, solves[0]);
    solvesForAvg = solvesForAvg.filter(solve => solve !== minTimeSolve);

    let count = 0;
    let total = 0;
    solvesForAvg.forEach((solve) => {
      if (!solve.dnfAuto) {
        total = total + solve.time;
        count++;
        if (solve.plus2Manual) {
          total = total + 2000;
        }
        if (solve.plus2Auto) {
          total = total + 2000;
        }
      }
    });
    if (count) {
      return Math.round(total / count);
    } else {
      return 0;
    }
  }

  // Step 4: If there are no solves with dnf
  if (dnfManualSolves.length === 0) {

    // calulate the max solve time while checking for +2 penalties
    let minTime = Infinity;
    let minTimeSolve = null;

    solves.forEach(solve => {
      let adjustedTime = solve.time;
      if (solve.plus2Auto || solve.plus2Manual) {
        adjustedTime += 2000;
      }
      if (adjustedTime < minTime) {
        minTime = adjustedTime;
        minTimeSolve = solve;
      }
    });

    // calulate the max solve time while checking for +2 penalties
    let maxTime = -Infinity;
    let maxTimeSolve = null;

    solves.forEach(solve => {
      let adjustedTime = solve.time;
      if (solve.plus2Auto || solve.plus2Manual) {
        adjustedTime += 2000;
      }
      if (adjustedTime > maxTime) {
        maxTime = adjustedTime;
        maxTimeSolve = solve;
      }
    });

    // Remove the solves with least and most time spent
    // let minTimeSolve = solvesForAvg.reduce((min, solve) => solve.time < min.time ? solve : min, solves[0]);
    // let maxTimeSolve = solvesForAvg.reduce((max, solve) => solve.time > max.time ? solve : max, solves[0]);

    solvesForAvg = solvesForAvg.filter(solve => solve !== minTimeSolve && solve !== maxTimeSolve);

    let count = 0;
    let total = 0;
    solvesForAvg.forEach((solve) => {
      if (!solve.dnfAuto) {
        total = total + solve.time;
        count++;
        if (solve.plus2Manual) {
          total = total + 2000;
        }
        if (solve.plus2Auto) {
          total = total + 2000;
        }
      }
    });
    if (count) {
      return Math.round(total / count);
    } else {
      return 0;
    }
  }
};

/**
 * The function CalculateWorst takes an array of solves and returns the worst time among them,
 * excluding DNF (Did Not Finish) solves.
 * @param solves - The function `CalculateWorst` takes an array of solves as input. Each solve in the
 * array is an object with properties `dnfAuto`, `dnfManual`, and `time`. The function iterates through
 * the array of solves and calculates the worst time among the solves that are not marked
 * @returns The function `CalculateWorst` returns the worst time among the solves provided as input,
 * excluding any solves marked as DNF (Did Not Finish) either automatically or manually.
 */
export const CalculateWorst = (solves) => {

  let worst = 0;
  for (let i = 0; i < solves.length; i++) {
    if (!solves[i]) {
      return;
    }
    if (solves[i].dnfAuto || solves[i].dnfManual) {
      continue;
    }
    let total = 0;

    if (!solves[i].dnfAuto) {
      total = total + solves[i].time;
      if (solves[i].plus2Manual) {
        total = total + 2000;
      }
      if (solves[i].plus2Auto) {
        total = total + 2000;
      }
    }

    if (total > worst) {
      worst = total
    }
    //console.log(worst);
  }
  return worst;
};

/**
 * The function CalculateAo5and12 calculates the average of 5 and 12 solves, excluding the fastest and
 * slowest times and handling DNFs and +2 penalties.
 * @param solves - It looks like the code snippet you provided is a function named `CalculateAo5and12`
 * that calculates the average of 5 and 12 solves excluding the best and worst times, considering DNFs
 * and +2 penalties.
 * @returns The function `CalculateAo5and12` returns the average of the best 3 out of 5 or best 5 out
 * of 12 solves, after accounting for any DNFs and +2 penalties in the input array of solves. If there
 * are more than 1 DNF in the solves array, the function returns `Number.POSITIVE_INFINITY`.
 */

export const CalculateAo5and12 = (solves, cubeType, x) => {

  // preparing the array of solves while considering DNFs, removing min and max time solves
  let recentxSolves = solves?.filter((solve) => {
    return solve?.cubeType == cubeType;
  })?.slice(-x)

  let dnfManualSolvesx = recentxSolves?.filter(solve => solve.dnfManual);

  // if recent5Solves contain dnfs more than 1
  if (dnfManualSolvesx?.length > 1) {
    return 'DNF'
  }

  // if recent5Solves contain only 1 dnf
  if (dnfManualSolvesx?.length === 1) {
    // Step 2: Remove the solve with dnfManual true
    recentxSolves = recentxSolves?.filter(solve => !solve.dnfManual);

    // calulate the min solve time while checking for +2 penalties
    let minTime = Infinity;
    let minTimeSolve = null;

    recentxSolves.forEach(solve => {
      let adjustedTime = solve.time;
      if (solve.plus2Auto || solve.plus2Manual) {
        adjustedTime += 2000;
      }
      if (adjustedTime < minTime) {
        minTime = adjustedTime;
        minTimeSolve = solve;
      }
    });
    recentxSolves = recentxSolves.filter(solve => solve !== minTimeSolve);

  }

  // if recent5Solves contain no dnfs
  if (dnfManualSolvesx?.length === 0) {

    // calulate the max solve time while checking for +2 penalties
    let minTime = Infinity;
    let minTimeSolve = null;

    recentxSolves.forEach(solve => {
      let adjustedTime = solve.time;
      if (solve.plus2Auto || solve.plus2Manual) {
        adjustedTime += 2000;
      }
      if (adjustedTime < minTime) {
        minTime = adjustedTime;
        minTimeSolve = solve;
      }
    });

    // calulate the max solve time while checking for +2 penalties
    let maxTime = -Infinity;
    let maxTimeSolve = null;

    recentxSolves.forEach(solve => {
      let adjustedTime = solve.time;
      if (solve.plus2Auto || solve.plus2Manual) {
        adjustedTime += 2000;
      }
      if (adjustedTime > maxTime) {
        maxTime = adjustedTime;
        maxTimeSolve = solve;
      }
    });

    recentxSolves = recentxSolves.filter(solve => solve !== minTimeSolve && solve !== maxTimeSolve);


  }

  // Calculating the average of 5 solves
  let totalTime = 0;
  for (let i = 0; i < recentxSolves.length; i++) {
    if (!recentxSolves[i]) {
      return;
    }
    if (recentxSolves[i].dnfAuto || recentxSolves[i].dnfManual) {
      continue;
    }
    let total = 0;

    if (!recentxSolves[i].dnfAuto) {
      total = total + recentxSolves[i].time;
      if (recentxSolves[i].plus2Manual) {
        total = total + 2000;
      }
      if (recentxSolves[i].plus2Auto) {
        total = total + 2000;
      }
    }

    totalTime = totalTime + total;

  }

  return formatter(totalTime / recentxSolves.length)
}

// export const CalculateAo5and12 = (solves) => {
//   let dnfs = 0;
//   let min = Number.POSITIVE_INFINITY;
//   let total = 0;
//   for (let i = 0; i < solves.length; i++) {
//     if (!solves[i]) {
//       return;
//     }
//     if (solves[i].dnfAuto || solves[i].dnfManual) {
//       dnfs++;
//       continue;
//     }

//     // let totalValue = 0;

//     // if (!solves[i].dnfAuto) {
//     //   totalValue = totalValue + solves[i].time;
//     //   if (solves[i].plus2Manual) {
//     //     totalValue = totalValue + 2000;
//     //   }
//     //   if (solves[i].plus2Auto) {
//     //     totalValue = totalValue + 2000;
//     //   }
//     // }
//     let extras = 0;
//     if (solves[i].plus2Manual) {
//       extras = extras + 2000;
//     }
//     if (solves[i].plus2Auto) {
//       extras = extras + 2000;
//     }
//     total = total + solves[i].time + extras;
//     // total = total + totalValue;
//     min = Math.min(min, solves[i].time + extras);
//   }
//   if (dnfs > 1) {
//     return Number.POSITIVE_INFINITY;
//   }
//   let avgTotal = total - min;
//   let numberOfSolves = solves.length - 2;
//   return Math.round(avgTotal / numberOfSolves);
// };

/**
 * The function CalculateAoXpb calculates the average of the best subset of solves of length x from a
 * given array of solves.
 * @param solves - An array of numbers representing the solve times for a particular task or puzzle.
 * @param x - The parameter `x` in the `CalculateAoXpb` function represents the number of solves to
 * consider when calculating the average. It is used to determine the average of a subset of solves
 * from the input array `solves`.
 * @returns The function `CalculateAoXpb` returns an object with two properties: `value` and `solves`.
 * The `value` property contains the calculated average of the best subset of solves, and the `solves`
 * property contains the array of solves that make up the best subset.
 */
export const CalculateAoXpb = (solves, x) => {
  // Calculate average time for each consecutive set of 5 solves

  const numSets = solves.length - (x - 1);
  let aoxpb = Number.POSITIVE_INFINITY;
  let aoxpbSolves = []

  for (let i = 0; i < numSets; i++) {
    // let sum = 0;
    let solvesArray = [];

    for (let j = i; j < i + x; j++) {
      solvesArray.push(solves[j]);
    }
    let avg = CalculateAverage(solvesArray)

    if (avg < aoxpb) {
      aoxpb = avg;
      aoxpbSolves = solvesArray;
    }
  }

  return { value: aoxpb, solves: aoxpbSolves };;

  // let aoxpb = Number.POSITIVE_INFINITY;
  // let aoxpbSolves = [];
  // for (let i = 0; i < solves.length - x + 1; i++) {
  //   let solvesArray = [];
  //   for (let j = 0; j < x; j++) {
  //     solvesArray.push(solves[i + j]);
  //   }
  //   let avg = CalculateAverage(solvesArray);
  //   if (avg < aoxpb) {
  //     aoxpb = avg;
  //     aoxpbSolves = solvesArray;
  //   }
  // }
  // return { value: aoxpb, solves: aoxpbSolves };
};

export const CalculateAvg = (solves) => {
  let count = 0;
  let total = 0;
  solves.forEach((solve) => {
    if (!solve.dnfAuto) {
      total = total + solve.time;
      count++;
      if (solve.plus2Manual) {
        total = total + 2000;
      }
      if (solve.plus2Auto) {
        total = total + 2000;
      }
    }
  });
  if(count){
    return total / count;
  }
}