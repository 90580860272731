
const returnMatrixCube = (input) => {
  switch (input) {
    case "all":
      return "All";
    case "222":
      return "2x2";
    case "333":
      return "3x3";
    case "333OH":
      return "3x3 OH";
    case "333BF":
      return "3x3 BLD";
    case "444":
      return "4x4";
    case "444BF":
      return "4x4 BLD";
    case "555":
      return "5x5";
    case "555BF":
      return "5x5 BLD";
    case "666":
      return "6x6";
    case "777":
      return "7x7";
    case "skewb":
      return "Skewb";
    case "pyra":
      return "Pyraminx";
    case "minx":
      return "Megaminx";
    case "clock":
      return "Clock";
    case "sq1":
      return "Square-1";
    default:
      // Check if the input starts with digits
      if (/^\d{3}/.test(input)) {
        const n = parseInt(input.substring(0, 3)); // Extract the first 3 digits and convert to a number
        return `${n}`; 
      }
      // If input doesn't match any case and doesn't start with 3 digits, return "3x3" as default
      return "333";
  }
};

export function formatData(data) {
  const SessionProperties = data.properties
    ? JSON.parse(data.properties.sessionData)
    : null;

    
  return Object.entries(data)
    .filter(([key]) => key.startsWith("session"))
    .map(([key, solves]) => {
      const sessionNumber = parseInt(key.replace("session", ""), 10);
      const sessionInfo = SessionProperties[sessionNumber];
      const cubeType = returnMatrixCube(sessionInfo.opt.scrType) || "Unknown";
      //   console.log(solves);
      const formattedSolves = solves.map((res, i) =>
        // console.log(res[0])
        ({
          time: res[0][1],
          plus2: res[0][0] === 2000,
          dnf: res[0][0] === -1,
          scramble: res[1],
          dateTime:Date(res[3]).toString()
        })
      );

      return {
        session: sessionNumber,
        sessionName: `Session ${sessionNumber}`,
        cubeType,
        solves: formattedSolves,
      };
    });
}

