import axios from "axios";

export const setupInterceptor = (user) => {
  axios.interceptors.request.use(async (config) => {
    if (user) {
      let token = await user.getIdToken();
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
};
