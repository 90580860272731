export const PuzzleIds = {
  two: "222",
  three: "333",
  threeOH: "333OH",
  threeBF: "333BF",
  four: "444",
  fourBF: "444BF",
  five: "555",
  fiveBF: "555BF",
  six: "666",
  seven: "777",
  pyra: "pyra",
  minx: "minx",
  clock: "clock",
  skewb: "skewb",
  sq1: "sq1",
};
