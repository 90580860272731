import React from "react";
import { Navigate } from "react-router-dom";

// type Props = {
//     children: JSX.Element;
// };

const UnAuthenticated = ({ children }) => {
    const isAuthenticated = false

    return !isAuthenticated ? children : <Navigate to={"/"} replace />;
};

export default UnAuthenticated;