import React, { useContext } from 'react'
import { ThemeContext } from "../../context";
import ProfileComponent from '../../components/Profile/ProfileComponent'

const Profile = () => {
  const { allStyles } =
    useContext(ThemeContext);
  return (
    <>
      <ProfileComponent allStyles={allStyles} />
    </>
  )
}

export default Profile